import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import EditIcon from '@material-ui/icons/Edit';
import PublishIcon from '@material-ui/icons/Public';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { titleCase } from 'change-case';

import { realtimeStorage } from '../helpers/firebaseDatabase';

import Table from '../components/table';
import RegionSelector from '../components/regionSelector';

import i18n from '../helpers/i18n';

const columnData = [
  {
    id: 'media',
    numeric: false,
    disablePadding: false,
    i18nKey: 'illustration',
    valueCallback: (value) => {
      if (value && value.illustration) {
        return (
          <div style={{
            width: '60%',
            height: '90px',
            backgroundImage: `url('${value.illustration.thumbSmall || value.illustration.url}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '6px',
            boxShadow: '3px 3px 5px 0px rgba(50, 50, 50, 0.75)',
          }}
          />
        );
      }
      return null;
    },
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    i18nKey: 'title',
  },
  {
    id: 'published',
    numeric: false,
    disablePadding: false,
    published: true,
    i18nKey: 'is_published',
  },
  {
    id: 'createdAt',
    numeric: false,
    disablePadding: true,
    i18nKey: 'created_at',
    timestamp: true,
    valueCallback: value => value && moment(value.toDate()),
  },
  {
    id: 'regions',
    numeric: false,
    disablePadding: false,
    i18nKey: 'regions',
    valueCallback: (value) => {
      if (value) {
        if (value.length === 1) {
          return titleCase(value);
        }
        return value.reduce((prevValue, currentValue) => `${titleCase(prevValue)}, ${titleCase(currentValue)}`);
      }
      return null;
    },
  },
];

class News extends Component {
  constructor(props) {
    super(props); const params = (new URL(document.location)).searchParams;
    const region = params.get('region');

    let selectedRegion;
    if (region) {
      selectedRegion = region;
    } else if (props.regions && props.regions[0]) {
      [selectedRegion] = props.regions;
      props.history.push(`/news?region=${selectedRegion}`);
    }

    this.state = {
      data: [],
      selectedRegion,
      allRegions: [],
    };
  }

  componentWillMount() {
    const { role } = this.props;
    if (role === 'admin' || role === 'super_admin' || role === 'windhund_admin') {
      realtimeStorage.getOnce('countries').then((countries) => {
        this.setState({ allRegions: countries });
      });
    }
    this.fetchData();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  fetchData() {
    const { selectedRegion } = this.state;
    const filtersAndSortingProps = [
      {
        field: 'createdAt',
        type: 'orderBy',
        direction: 'asc',
      },
    ];
    if (selectedRegion) {
      filtersAndSortingProps.unshift({
        field: 'regions',
        opStr: 'array-contains',
        value: selectedRegion,
        type: 'where',
      });
    }

    this.unsubscribe = realtimeStorage.getOn(
      'news',
      (val) => {
        this.setState({ data: val });
      },
      filtersAndSortingProps,
    );
  }

  render() {
    const { data, selectedRegion, allRegions } = this.state;
    const { history, regions, role } = this.props;

    let possibleRegions = regions;
    let allowAddItem = true;
    if (role === 'admin' || role === 'super_admin' || role === 'windhund_admin') {
      possibleRegions = allRegions.map((region => region.i18nKey));
      if (regions.includes(selectedRegion)) {
        allowAddItem = true;
      } else {
        allowAddItem = false;
      }
    }

    const toolbarItems = [{
      tooltipLabel: i18n.t('edit'),
      onClick: (selected) => {
        history.push(`/news/${selected[0].id}/edit`);
      },
      icon: (<EditIcon />),
    },
    {
      tooltipLabel: i18n.t('publish'),
      onClick: (selected) => {
        realtimeStorage.publishNews(selected);
      },
      icon: (<PublishIcon />),
      isDisabled: () => !allowAddItem,
    }];

    if (!allowAddItem) {
      toolbarItems.unshift({
        tooltipLabel: i18n.t('show'),
        onClick: (selected) => {
          history.push(`/news/${selected[0].id}/show`);
        },
        icon: (<VisibilityIcon />),
      });
    }

    return (
      <div>
        {(regions && regions.length > 1)
          && (
            <RegionSelector
              value={selectedRegion}
              regions={possibleRegions}
              onChange={value => this.setState({ selectedRegion: value }, () => {
                history.push(`/news?region=${value}`);
                this.fetchData();
              })}
            />
          )
        }
        <Table
          data={data}
          columns={columnData}
          rowHeight={120}
          allowAddItem={allowAddItem}
          onDeleteItems={(items) => {
            realtimeStorage.delete('news', items);
          }}
          toolbarItems={toolbarItems}
          {...this.props}
        />
      </div>
    );
  }
}

News.propTypes = {
  history: PropTypes.object.isRequired,
  regions: PropTypes.array,
  role: PropTypes.string,
};

export default News;
