import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import TextField from '@material-ui/core/TextField';

import i18n from '../helpers/i18n';

const myTextField = (props) => {
  const {
    classes,
    flex,
    i18nLabelKey,
    onChange,
    noMarginBottom,
    isRequired = false,
    ...rest
  } = props;

  const requiredIcon = isRequired ? ' *' : '';
  return (
    <div className={classNames(
      classes.container,
      noMarginBottom && classes.noMarginBottom,
    )}
    >
      <TextField
        fullWidth
        id={i18nLabelKey}
        InputLabelProps={{
          shrink: true,
        }}
        className={classNames({
          [classes.flex]: flex,
        })}
        label={`${i18n.t(i18nLabelKey)}${requiredIcon}`}
        {...rest}
        onChange={e => onChange(e.target.value)}
      />
    </div>
  );
};

myTextField.propTypes = {
  classes: PropTypes.object.isRequired,
  flex: PropTypes.bool,
  i18nLabelKey: PropTypes.string,
  onChange: PropTypes.func,
  noMarginBottom: PropTypes.bool,
  isRequired: PropTypes.bool,
};

const styles = theme => ({
  flex: {
    display: 'flex',
    flex: 1,
  },
  container: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
});

export default withStyles(styles, { withTheme: true })(myTextField);
