import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';

import { ClipLoader } from 'react-spinners';
import Dropzone from 'react-dropzone';
import moment from 'moment';

import firebase from '../helpers/firebase';
import i18n from '../helpers/i18n';

class myDropZone extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
      loading: false,
      localValue: undefined,
      payload: {},
      remoteValue: props.value,
      sizes: props.sizes || ['400x400', '200x200', '150x150'],
    };
  }

  componentWillReceiveProps(newProps) {
    const { value } = this.props;
    if (!newProps.remoteValue && !newProps.localValue) {
      this.setState({
        loading: false,
      });
    }
    if (value !== newProps.value) {
      this.setState({
        remoteValue: newProps.value,
      });
    }
  }

  handleImageLoaded() {
    this.setState({
      loading: false,
    });
  }

  uploadImage() {
    this.setState({
      loading: true,
    });

    const {
      localValue,
      remoteValue,
      sizes,
    } = this.state;
    if (localValue) {
      const { type } = this.props;

      let fileExtension = localValue.name.match(/(\.\w+$)/g)[0];
      const filename = localValue.name.replace(fileExtension, '');
      if (fileExtension === '.mp4') {
        fileExtension = '.MP4';
      }
      const fullFilename = `${filename}.${moment().toISOString()}${fileExtension}`;
      if (localValue && localValue.type.split('/')[0] === 'image') {
        const storage = firebase
          .getInstance()
          .storage()
          .ref()
          .child(`/images/${type}`)
          .child(fullFilename);
        return storage
          .put(localValue)
          .then(snapshot => storage.getDownloadURL()
            .then(async (url) => {
              const metaData = await storage.getMetadata();
              const generateThumbnailFromImage = firebase
                .getInstance()
                .functions()
                .httpsCallable('generateThumbnailFromImage');

              const createThumbnail = size => generateThumbnailFromImage({
                url,
                size,
                metaData,
              });

              const getSafeUrl = path => firebase
                .getInstance()
                .storage()
                .ref()
                .child(`/${path.data}`);

              const small = await createThumbnail(sizes[2]);
              const medium = await createThumbnail(sizes[1]);
              const large = await createThumbnail(sizes[0]);

              const data = ({
                url,
                type: localValue.type,
                path: snapshot.ref.fullPath,
                thumbSmall: await getSafeUrl(small).getDownloadURL(),
                thumbMedium: await getSafeUrl(medium).getDownloadURL(),
                thumbLarge: await getSafeUrl(large).getDownloadURL(),
              });

              await this.setState({ payload: data });
              this.setState({ loading: false });

              // eslint-disable-next-line react/destructuring-assignment
              return this.state.payload;
            }));
      }
      const storage = firebase.getInstance()
        .storage()
        .ref()
        .child(`/ videos / ${type} `)
        .child(fullFilename);
      return storage.put(localValue)
        .then(snapshot => storage.getDownloadURL()
          .then((url) => {
            this.setState({ loading: false });
            return ({
              url,
              type: localValue.type,
              path: snapshot.ref.fullPath,
            });
          }));
    }
    if (remoteValue) {
      return new Promise((resolve) => {
        this.setState({ loading: false });
        resolve(remoteValue);
      });
    }
    return new Promise((resolve) => {
      this.setState({ loading: false });
      resolve(null);
    });
  }

  render() {
    const {
      accept,
      i18nLabelKey,
      disabled,
    } = this.props;
    const {
      localValue,
      remoteValue,
      loading,
      error,
    } = this.state;

    const dropzoneStyle = {
      height: '217px',
      background: 'rgb(235, 235, 235)',
      border: '2px dashed rgb(102, 102, 102)',
      cursor: 'pointer',
      boxSizing: 'border-box',
      marginTop: '9px',
      marginRight: '1px',
    };

    const containerStyle = {
      width: '100%',
      marginTop: '60px',
      position: 'relative',
      textAlign: 'center',
      marginBottom: '10px',
    };

    const labelStyle = {
      marginTop: '-25px',
      left: 0,
    };

    if (localValue || remoteValue) {
      if (remoteValue && !remoteValue.type) {
        remoteValue.type = 'video';
      }
      return (
        <div style={containerStyle}>
          <Typography variant="caption" style={labelStyle}>
            {i18n.t(i18nLabelKey)}
          </Typography>
          <div style={dropzoneStyle}>
            {(!loading)
              ? (
                <div>
                  <span
                    style={{
                      position: 'absolute',
                      top: '34px',
                      right: '10px',
                      width: '23px',
                      height: '23px',
                      zIndex: 99,
                      background: 'rgb(235, 235, 235)',
                      border: '1px solid rgb(102, 102, 102)',
                      cursor: 'pointer',
                    }}
                    tabIndex="0"
                    role="button"
                    onKeyDown={
                      () => this.setState({
                        localValue: undefined,
                        remoteValue: undefined,
                      })
                    }
                    onClick={
                      () => this.setState({
                        localValue: undefined,
                        remoteValue: undefined,
                      })
                    }
                  >
                    <i className="material-icons" style={{ color: 'rgb(102, 102, 102)' }}>
                      clear
                    </i>
                  </span>
                </div>
              )
              : (
                <div style={{
                  width: '100%',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: '99',
                }}
                >
                  <ClipLoader
                    loading={loading}
                    sizeUnit="px"
                    size="35"
                    color="red"
                  />
                </div>
              )
            }
          </div>
          {
            ((localValue && localValue.type.split('/')[0] === 'image') || (remoteValue && remoteValue.type.split('/')[0] === 'image'))
            && (
              // (
              <img
                src={(localValue ? localValue.preview : remoteValue.url)}
                alt="test"
                // eslint-disable-next-line
                onLoad={() => this.handleImageLoaded()}
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '25px',
                  height: '217px',
                  zIndex: '90',
                  transform: 'translate(-50%)',
                }}
              />
            )
          }
          {((localValue && localValue.type.split('/')[0] === 'video') || (remoteValue && remoteValue.type.split('/')[0] === 'video'))
          && (
            <video
              width="320"
              height="240"
              controls
              style={{
                position: 'absolute',
                left: '50%',
                top: '25px',
                height: '217px',
                zIndex: '90',
                transform: 'translate(-50%)',
              }}
              muted
            >
              <source
                src={(localValue ? localValue.preview : remoteValue.url)}
                type={(localValue ? localValue.type : remoteValue.type)}
              />
            </video>
            //   src={(localValue ? localValue.preview : remoteValue.url)}
            // // alt="test"
            // // eslint-disable-next-line
            // // onLoad={() => this.handleImageLoaded()}
            // />
          )
          }
        </div>
      );
    }

    return (
      <div style={containerStyle}>
        <Typography variant="caption" style={labelStyle}>
          {i18n.t(i18nLabelKey)}
        </Typography>
        <div style={{
          width: '100%',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%)',
          zIndex: '99',
        }}
        >
          <ClipLoader
            loading={loading}
            sizeUnit="px"
            size={50}
            color="red"
          />
        </div>
        <Dropzone
          disabled={disabled}
          onDrop={(acceptedFiles, rejectedFiles) => {
            const newFile = acceptedFiles.map(file => Object.assign(file, {
              preview: URL.createObjectURL(file),
            }))[0];
            this.setState({
              localValue: newFile,
              error: (rejectedFiles.length !== 0) ? i18n.t('invalid_file') : '',
            });
            this.props.passUrl(newFile);
          }}
          accept={accept}
          style={dropzoneStyle}
          {...this.props}
        />
        <Typography
          variant="caption"
          style={{
            color: 'red',
            fontWeight: 'bold',
          }}
        >
          {error}
        </Typography>
      </div>
    );
  }
}


myDropZone.propTypes = {
  disabled: PropTypes.bool,
  sizes: PropTypes.array,
  accept: PropTypes.string,
  value: PropTypes.object,
  i18nLabelKey: PropTypes.string,
  type: PropTypes.string,
};


export default myDropZone;
