import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  position: 'relative',
  // change background colour if dragging
  background: isDragging ? 'rgba(98, 138, 25, 0.8)' : 'white',
  borderBottom: '1px solid rgba(102, 102, 102, 0.5)',

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = trashzone => ({
  // background: 'lightgrey',
  flex: 1,
  padding: grid,
  borderBottom: trashzone ? '1px solid black' : null,
});


class DraggableList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      isDragging: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { items } = this.state;
    if (items !== nextProps.items) {
      this.setState({ items: nextProps.items });
    }
  }

  onDragEnd(result) {
    const { items } = this.state;
    const { reorderCallback } = this.props;
    const { source, destination, draggableId } = result;
    let newItems = items;
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      newItems = reorder(
        items,
        result.source.index,
        result.destination.index,
      );
    } else if (destination.droppableId === 'trashzone') {
      newItems = items.filter(item => (item.id !== draggableId));
    }

    this.setState({
      items: newItems,
      isDragging: false,
    });
    reorderCallback(newItems);
  }

  onDragStart() {
    this.setState({
      isDragging: true,
    });
  }

  getTrashzoneStyle(isDraggingOver) {
    const { isDragging } = this.state;
    return (
      {
        background: isDraggingOver ? 'darkgrey' : 'yellow',
        padding: grid,
        height: 150,
        opacity: isDragging ? 1 : 0.4,
      }
    );
  }

  // Normally you would want to split things out into separate components.
  // But in this example everything is just done in one place for simplicity
  render() {
    const { items } = this.state;
    const {
      renderItem,
      trashzone,
      onDelete,
      onEdit,
    } = this.props;
    return (
      <DragDropContext
        onDragEnd={result => this.onDragEnd(result)}
        onDragStart={() => this.onDragStart()}
      >
        <Droppable droppableId="droppable">
          {(providedDroppable, snapshotDroppable) => (
            <div
              ref={providedDroppable.innerRef}
              style={getListStyle(snapshotDroppable.isDraggingOver)}
            >
              {items.map((item, index) => {
                if (item) {
                  return (
                    <Draggable key={item.uid} draggableId={item.uid} index={index}>
                      {(providedDraggable, snapshotDraggable) => (
                        <div
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          style={getItemStyle(
                            snapshotDraggable.isDragging,
                            providedDraggable.draggableProps.style,
                          )}
                        >
                          {/* {renderItem(item)} */}
                          {/* <Icon style={{
                            top: '50%',
                            right: '10px',
                            position: 'absolute',
                            fontSize: '220%',
                            transform: 'translateY(-50%)',
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                          > */}
                          {/* reorder
                          </Icon>
                        </div>
                      )}
                      > */}
                          {renderItem(item)}
                          {onEdit && (
                            <IconButton
                              onClick={() => onEdit(item)}
                              style={{
                                top: '50%',
                                right: '90px',
                                position: 'absolute',
                                fontSize: '180%',
                                transform: 'translateY(-50%)',
                                color: 'rgba(0, 0, 0, 0.5)',
                              }}
                            >
                              <Icon>
                                edit
                              </Icon>
                            </IconButton>
                          )}
                          {onDelete && (
                            <IconButton
                              onClick={() => onDelete(item)}
                              style={{
                                top: '50%',
                                right: '50px',
                                position: 'absolute',
                                fontSize: '180%',
                                transform: 'translateY(-50%)',
                                color: 'rgba(0, 0, 0, 0.5)',
                              }}
                            >
                              <Icon>
                                delete
                              </Icon>
                            </IconButton>
                          )}
                          <Icon style={{
                            top: '50%',
                            right: '10px',
                            position: 'absolute',
                            fontSize: '220%',
                            transform: 'translateY(-50%)',
                            color: 'rgba(0, 0, 0, 0.5)',
                          }}
                          >
                            reorder
                          </Icon>
                        </div>
                      )
                      }
                    </Draggable>
                  );
                }
                return null;
              })}
              {providedDroppable.placeholder}
            </div>
          )
          }
        </Droppable>
        {(trashzone) ? (
          <Droppable droppableId="trashzone">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={this.getTrashzoneStyle(snapshot.isDraggingOver)}
              >
                <Icon style={{
                  fontSize: '420%',
                  color: 'rgba(0, 0, 0, 0.8)',
                  margin: 'auto',
                  top: '50%',
                  display: 'block',
                  transform: 'translateY(-50%)',
                  position: 'relative',
                }}
                >
                  delete
                </Icon>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ) : ('')
        }
      </DragDropContext>
    );
  }
}

DraggableList.propTypes = {
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired,
  reorderCallback: PropTypes.func.isRequired,
  trashzone: PropTypes.bool,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export default DraggableList;
