import { realtimeStorage } from '../helpers/firebaseDatabase';

export const FETCH_NOTIFICATIONS_REQUEST = Symbol('FETCH_NOTIFICATIONS_REQUEST');
export const FETCH_NOTIFICATIONS_SUCCESS = Symbol('FETCH_NOTIFICATIONS_SUCCESS');
export const FETCH_NOTIFICATIONS_FAILURE = Symbol('FETCH_NOTIFICATIONS_FAILURE');

export const DELETE_NOTIFICATION_REQUEST = Symbol('DELETE_NOTIFICATION_REQUEST');
export const DELETE_NOTIFICATION_SUCCESS = Symbol('DELETE_NOTIFICATION_SUCCESS');
export const DELETE_NOTIFICATION_FAILURE = Symbol('DELETE_NOTIFICATION_FAILURE');

export const fetchNotificationsRequest = () => ({
  type: FETCH_NOTIFICATIONS_REQUEST,
});
export const fetchNotificationsFailure = payload => ({
  type: FETCH_NOTIFICATIONS_FAILURE,
  payload,
});
export const fetchNotificationsSuccess = payload => ({
  type: FETCH_NOTIFICATIONS_SUCCESS,
  payload,
});

export const deleteNotificationsRequest = () => ({
  type: DELETE_NOTIFICATION_REQUEST,
});
export const deleteNotificationsFailure = payload => ({
  type: DELETE_NOTIFICATION_FAILURE,
  payload,
});
export const deleteNotificationsSuccess = payload => ({
  type: DELETE_NOTIFICATION_SUCCESS,
  payload,
});

export const getNotifications = () => async (dispatch) => {
  dispatch(fetchNotificationsRequest());
  try {
    realtimeStorage
      .getOn('pushNotifications', (collection) => {
        dispatch(fetchNotificationsSuccess(collection));
      });
  } catch (e) {
    dispatch(fetchNotificationsFailure(e));
  }
};

export const deleteNotifications = payload => async (dispatch) => {
  dispatch(deleteNotificationsRequest());
  realtimeStorage
    .delete('pushNotifications', [payload])
    .then(() => {
      dispatch(deleteNotificationsSuccess());
    })
    .catch((error) => {
      dispatch(deleteNotificationsFailure(error));
    });
};

const initState = {
  entries: [],
  isLoading: false,
  error: false,
};

const notificationsReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATIONS_REQUEST:
    case DELETE_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        error: false,
        isLoading: false,
        entries: action.payload,
      };
    case FETCH_NOTIFICATIONS_FAILURE:
    case DELETE_NOTIFICATION_FAILURE:
      return {
        ...state,
        error: action.payload,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default (state, action) => [notificationsReducer].reduce(
  (newState, currentReducer) => currentReducer(newState, action),
  state,
);
