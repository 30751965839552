import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// reducers
import { getUserDetails } from '../ducks/user';
import { getLanguages } from '../ducks/languages';
import { getCountries } from '../ducks/countries';
import {
  getVideos,
  getVideoCategories,
  addNewVideo,
  deleteVideo,
  updateVideos,
  updateVideoById,
} from '../ducks/videos';

// selectors
import {
  hasError,
  selectIsLoading,
  selectAdminCountries,
} from '../selectors/countries';

import { selectLanguages, selectSuccess } from '../selectors/languages';
import { selectUserDetails, isLoading } from '../selectors/user';
import { selectVideos } from '../selectors/videos';

import VideosPage from '../pages/videos';

const mapStateToProps = state => ({
  countries: selectAdminCountries(state),
  languages: selectLanguages(state),
  videos: selectVideos(state),
  isLoading: selectIsLoading(state),
  isFetchingUser: isLoading(state),
  user: selectUserDetails(state),
  error: hasError(state),
  success: selectSuccess(state),
});

const mapDispatchToProps = dispatch => ({
  getCountries: async () => {
    await dispatch(getCountries());
  },
  getLanguages: async () => {
    await dispatch(getLanguages());
  },
  getUserDetails: async () => {
    await dispatch(getUserDetails());
  },
  getVideos: async () => {
    await dispatch(getVideos());
  },
  getVideoCategories: async () => {
    await dispatch(getVideoCategories());
  },
  addNewVideo: async (payload) => {
    await dispatch(addNewVideo(payload));
  },
  deleteVideo: async (payload) => {
    await dispatch(deleteVideo(payload));
  },
  updateVideos: async (payload) => {
    await dispatch(updateVideos(payload));
  },
  updateVideoById: async (payload) => {
    await dispatch(updateVideoById(payload));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VideosPage));
