import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// reducers
import {
  fetchAllUsersWithEmailAccount,
  deleteUserWithEmailAccount,
  sendUserPasswordResetForEmailAccount,
  sendVerificationEmailLinkForEmailAccount,
} from '../ducks/admins';
import { getLanguages } from '../ducks/languages';
import { getCountries } from '../ducks/countries';

// selectors
import {
  selectAllCountries,
  selectCountries,
} from '../selectors/countries';

import {
  selectAllUsersCount,
  selectAllUsersWithAccount,
  selectError,
  selectHasData,
  selectIsLoading,
  selectSuccess,
  selectUnverifiedUsers,
  selectUsersAccountType,
  selectUsersIncognitoType,
  selectIncognitoTypeCount,
  selectVerifiedUsers,
  selectAllUsers,
} from '../selectors/admins';

import usersWithEmail from '../pages/usersWithEmail';

const mapStateToProps = state => ({
  allCountries: selectAllCountries(state),
  allUsersCount: selectAllUsersCount(state),
  allUsers: selectAllUsers(state),
  allUsersWithEmailAccount: selectAllUsersWithAccount(state),
  countries: selectCountries(state),
  error: selectError(state),
  hasData: selectHasData(state),
  isLoading: selectIsLoading(state),
  success: selectSuccess(state),
  unverifiedUsers: selectUnverifiedUsers(state),
  usersAccountType: selectUsersAccountType(state),
  usersIncognitoType: selectUsersIncognitoType(state),
  usersIncognitoTypeCount: selectIncognitoTypeCount(state),
  verifiedUsers: selectVerifiedUsers(state),
});

const mapDispatchToProps = dispatch => ({
  getCountries: async () => {
    await dispatch(getCountries());
  },
  getLanguages: async () => {
    await dispatch(getLanguages());
  },
  fetchAllUsersWithEmailAccount: async () => {
    await dispatch(fetchAllUsersWithEmailAccount());
  },
  deleteUserWithEmailAccount: async (payload) => {
    await dispatch(deleteUserWithEmailAccount(payload));
  },
  sendUserPasswordReset: async (payload) => {
    await dispatch(sendUserPasswordResetForEmailAccount(payload));
  },
  sendVerificationEmailLink: async (payload) => {
    await dispatch(sendVerificationEmailLinkForEmailAccount(payload));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(usersWithEmail));
