import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { realtimeStorage } from '../helpers/firebaseDatabase';
import Table from '../components/table';

import i18n from '../helpers/i18n';

const columnData = [
  {
    id: 'media',
    numeric: false,
    disablePadding: false,
    i18nKey: 'illustration',
    valueCallback: (value) => {
      if (value && value.path) {
        return (
          <div style={{
            width: '60%',
            height: '90px',
            backgroundImage: `url('${value.thumbSmall || value.url}')`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            borderRadius: '6px',
            boxShadow: '3px 3px 5px 0px rgba(50, 50, 50, 0.75)',
          }}
          />
        );
      }
      return null;
    },
  },
  {
    id: 'i18nKey',
    numeric: false,
    disablePadding: false,
    i18nKey: 'title',
  },
  {
    id: 'index',
    numeric: false,
    disablePadding: false,
    i18nKey: 'index',
  },
  {
    id: 'key',
    numeric: false,
    disablePadding: false,
    i18nKey: 'key',
  },
  {
    id: 'updatedAt',
    numeric: false,
    disablePadding: true,
    i18nKey: 'created_at',
    timestamp: true,
    valueCallback: value => value && moment(value.toDate()),
  },
];

class VideoCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentWillMount() {
    this.fetchData();
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  fetchData() {
    const filtersAndSortingProps = [
      {
        field: 'updatedAt',
        type: 'orderBy',
        direction: 'asc',
      },
    ];
    this.unsubscribe = realtimeStorage.getOn(
      'videoCategories',
      (val) => {
        this.setState({ data: val });
      },
      filtersAndSortingProps,
    );
  }

  render() {
    const { data } = this.state;
    const { history } = this.props;

    const allowAddItem = true;

    const toolbarItems = [{
      tooltipLabel: i18n.t('edit'),
      onClick: (selected) => {
        history.push(`/videoCategories/${selected[0].id}/edit`);
      },
      icon: (<EditIcon />),
    }];

    if (!allowAddItem) {
      toolbarItems.unshift({
        tooltipLabel: i18n.t('show'),
        onClick: (selected) => {
          history.push(`/videoCategories/${selected[0].id}/show`);
        },
        icon: (<VisibilityIcon />),
      });
    }

    return (
      <div>
        <Table
          data={data}
          columns={columnData}
          rowHeight={120}
          allowAddItem={allowAddItem}
          onDeleteItems={(items) => {
            realtimeStorage.delete('videoCategories', items);
          }}
          toolbarItems={toolbarItems}
          {...this.props}
        />
      </div>
    );
  }
}

VideoCategories.propTypes = {
  history: PropTypes.object.isRequired,
  regions: PropTypes.array,
  role: PropTypes.string,
};

export default VideoCategories;
