import {
  equals,
  includes,
  isEmpty,
  length,
  map,
  join,
  omit,
  pluck,
  head,
  pipe,
  values,
  pick,
  mergeRight,
} from 'ramda';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import RegionSelector from '../../../components/regionSelector';
import i18n from '../../../helpers/i18n';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  editBox: {
    padding: 30,
  },
  formBox: {
    padding: 30,
    margin: 15,
  },
  saveButton: {
    marginLeft: 10,
    marginRight: 10,
  },
  deleteButton: {
    backgroundColor: '#df0024',
    color: 'white',
    marginLeft: 10,
    marginRight: 10,
    '&:hover': {
      color: 'grey',
    },
  },
});

const CountriesTable = ({
  languages, deleteCountry, row, updateCountries,
}) => {
  const classes = useRowStyles();
  const [open, setOpen] = useState(false);
  const [modalManager, setModalManager] = useState(false);

  const [initValues, setInitValues] = useState({
    countryName: row.name,
    hasOwnContent: row.hasOwnContent,
    countryCode: row.countryCode,
    isThirdParty: row.thirdParty,
    translationKey: row.i18nKey,
    shopUrl: row.webshopURI,
    id: row.uid,
    languages: row.languages,
  });

  const [formValues, setNewFormValues] = useState(initValues);

  const transformLanguages = pipe(
    pick(['languages']),
    map(head),
    mergeRight(omit(['languages'], initValues)),
  )(initValues);

  const disabled = useMemo(() => (
    equals(values(omit(['languages'], initValues)), values(omit(['languages'], formValues)))
    || equals(transformLanguages, formValues)
    || length(formValues.countryCode) !== 2
    || pipe(values, map(isEmpty), includes(true))(formValues)
  ), [initValues, formValues]);

  const handleChange = useCallback(prop => event => setNewFormValues({
    ...formValues,
    [prop]: event.target.value,
  }), [setNewFormValues]);

  const handleChangeMultiValues = useCallback(prop => event => setNewFormValues({
    ...formValues,
    [prop]: event,
  }), [setNewFormValues]);

  const updatePayload = useMemo(() => ({
    countryCode: formValues.countryCode,
    hasOwnContent: formValues.hasOwnContent,
    i18nKey: formValues.translationKey,
    languages: formValues.languages,
    name: formValues.countryName,
    thirdParty: formValues.isThirdParty,
    uid: formValues.id,
    webshopURI: formValues.shopUrl,
  }), [formValues]);

  const handleSubmit = useCallback(() => {
    updateCountries({
      uid: updatePayload.uid,
      country: updatePayload,
    });
    setOpen(!open);
  }, [updatePayload, updateCountries, open, setOpen]);

  const handleDelete = useCallback(() => {
    deleteCountry({
      id: updatePayload.uid,
      country: updatePayload,
    });
    setOpen(!open);
  }, [updatePayload, deleteCountry, open, setOpen]);

  useEffect(() => setInitValues({
    countryCode: row.countryCode,
    countryName: row.name,
    hasOwnContent: row.hasOwnContent,
    id: row.uid,
    isThirdParty: row.thirdParty,
    languages: [row.languages],
    shopUrl: row.webshopURI,
    translationKey: row.i18nKey,
  }), [row, setInitValues]);

  const pickLanguage = useMemo(() => pipe(pluck('value'))(languages), [languages]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.uid}</TableCell>
        <TableCell align="right">{row.name}</TableCell>
        <TableCell align="right">{row.hasOwnContent ? i18n.t('yes') : i18n.t('no')}</TableCell>
        <TableCell align="right">{row.countryCode}</TableCell>
        <TableCell align="right">{join(', ', row.languages)}</TableCell>
        <TableCell align="right">{row.i18nKey}</TableCell>
        <TableCell align="right">{row.thirdParty ? i18n.t('yes') : i18n.t('no')}</TableCell>
        <TableCell align="right">{row.webshopURI}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={9}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={5}>
              <Paper elevation={3}>
                <form className={classes.root} noValidate autoComplete="off">
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="baseline"
                    className={classes.formBox}
                  >
                    <div>
                      <TextField
                        defaultValue={row.name}
                        error={isEmpty(formValues.countryName)}
                        helperText={i18n.t('country_name')}
                        id={row.name}
                        label={i18n.t('required')}
                        onChange={handleChange('countryName')}
                        required
                      />
                    </div>
                    <div>
                      <InputLabel shrink id="hasOwnContent-label">
                        {i18n.t('required')}
                      </InputLabel>
                      <Select
                        className={classes.selectEmpty}
                        displayEmpty
                        id={row.hasOwnContent}
                        labelId="hasOwnContent-label-id"
                        onChange={handleChange('hasOwnContent')}
                        value={row.hasOwnContent}
                      >
                        <MenuItem value>{i18n.t('yes')}</MenuItem>
                        <MenuItem value={false}>{i18n.t('no')}</MenuItem>
                      </Select>
                      <FormHelperText>{i18n.t('own_content')}</FormHelperText>
                    </div>
                    <div>
                      <TextField
                        defaultValue={row.countryCode}
                        error={isEmpty(formValues.countryCode)}
                        helperText={i18n.t('country_code')}
                        id="countryCode"
                        label={i18n.t('required')}
                        onChange={handleChange('countryCode')}
                        required
                      />
                    </div>
                    <div>
                      <RegionSelector
                        multiple
                        value={formValues.languages}
                        regions={pickLanguage}
                        onChange={handleChangeMultiValues('languages')}
                      />
                      <FormHelperText>{i18n.t('language_code')}</FormHelperText>
                    </div>
                    <div>
                      <TextField
                        defaultValue={row.i18nKey}
                        error={isEmpty(formValues.translationKey)}
                        helperText={i18n.t('translation_key')}
                        id="translationKey"
                        label={i18n.t('required')}
                        onChange={handleChange('translationKey')}
                        required
                      />
                    </div>
                    <div>
                      <InputLabel shrink id="isThirdParty-input-label">
                        Required
                      </InputLabel>
                      <Select
                        className={classes.selectEmpty}
                        displayEmpty
                        id="isThirdParty"
                        labelId="isThirdParty-label"
                        onChange={handleChange('isThirdParty')}
                        value={row.thirdParty}
                      >
                        <MenuItem value>{i18n.t('yes')}</MenuItem>
                        <MenuItem value={false}>{i18n.t('no')}</MenuItem>
                      </Select>
                      <FormHelperText>{i18n.t('is_party')}</FormHelperText>
                    </div>
                    <div>
                      <TextField
                        defaultValue={row.webshopURI}
                        error={isEmpty(formValues.shopUrl)}
                        helperText={i18n.t('url_shop')}
                        id={formValues.shopUrl}
                        label={i18n.t('required')}
                        onChange={handleChange('shopUrl')}
                        required
                      />
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.editBox}
                  >
                    <Box>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={disabled}
                        onClick={() => handleSubmit()}
                        className={classes.saveButton}
                        startIcon={<SaveIcon />}
                      >
                        {i18n.t('update_in_db')}
                      </Button>
                    </Box>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => setModalManager(!modalManager)}
                        className={classes.deleteButton}
                        startIcon={<DeleteIcon />}
                      >
                        {i18n.t('delete_country_from_db', { country: row.name })}
                      </Button>
                    </Box>
                  </Grid>
                </form>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={modalManager}
        onClose={() => setModalManager(!modalManager)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {i18n.t('delete_question')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {i18n.t('delete_prompt')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModalManager(!modalManager)}
            variant="contained"
            color="primary"
          >
            {i18n.t('no')}
          </Button>
          <Button
            onClick={() => handleDelete()}
            color="primary"
            autoFocus
            className={classes.deleteButton}
          >
            {i18n.t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

CountriesTable.propTypes = {
  row: PropTypes.shape({
    countryCode: PropTypes.string.isRequired,
    hasOwnContent: PropTypes.bool.isRequired,
    i18nKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    thirdParty: PropTypes.bool.isRequired,
    webshopURI: PropTypes.string.isRequired,
    languages: PropTypes.string.isRequired,
  }).isRequired,
  updateCountries: PropTypes.func.isRequired,
  deleteCountry: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
};

export default CountriesTable;
