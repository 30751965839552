import Logger from './Logger';
import { StorageService } from './StorageService';

const storage = new StorageService();
const logger = new Logger();

export default {
  storage,
  logger,
};
