import {
  filter,
  length,
  map,
  mergeLeft,
  splitEvery,
  pathOr,
  pipe,
  propEq,
  reject,
  when,
  whereEq,
} from 'ramda';

import firebase from '../helpers/firebase';
import { getUserDetails } from './user';
import AuthService from '../services/AuthService';

const authService = new AuthService();

export const FETCH_ALL_USERS_AS_ADMIN_REQUEST = Symbol('FETCH_ALL_USERS_AS_ADMIN_REQUEST');
export const FETCH_ALL_USERS_AS_ADMIN_SUCCESS = Symbol('FETCH_ALL_USERS_AS_ADMIN_SUCCESS');
export const FETCH_ALL_USERS_AS_ADMIN_FAILURE = Symbol('FETCH_ALL_USERS_AS_ADMIN_FAILURE');
export const ADD_USER_ROLE_AS_ADMIN_REQUEST = Symbol('ADD_USER_ROLE_AS_ADMIN_REQUEST');
export const ADD_USER_ROLE_AS_ADMIN_SUCCESS = Symbol('ADD_USER_ROLE_AS_ADMIN_SUCCESS');
export const ADD_USER_ROLE_AS_ADMIN_FAILURE = Symbol('ADD_USER_ROLE_AS_ADMIN_FAILURE');
export const FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_REQUEST = Symbol('FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_REQUEST');
export const FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_SUCCESS = Symbol('FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_SUCCESS');
export const FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_FAILURE = Symbol('FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_FAILURE');
export const DELETE_USER_WITH_EMAIL_ACCOUNT_REQUEST = Symbol('DELETE_USER_WITH_EMAIL_ACCOUNT_REQUEST');
export const DELETE_USER_WITH_EMAIL_ACCOUNT_SUCCESS = Symbol('DELETE_USER_WITH_EMAIL_ACCOUNT_SUCCESS');
export const DELETE_USER_WITH_EMAIL_ACCOUNT_FAILURE = Symbol('DELETE_USER_WITH_EMAIL_ACCOUNT_FAILURE');
export const SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_REQUEST = Symbol('SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_REQUEST');
export const SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_SUCCESS = Symbol('SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_SUCCESS');
export const SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_FAILURE = Symbol('SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_FAILURE');
export const SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_REQUEST = Symbol('SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_REQUEST');
export const SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_SUCCESS = Symbol('SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_SUCCESS');
export const SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_FAILURE = Symbol('SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_FAILURE');

export const fetchAllUsersAsAdminRequest = payload => ({
  type: FETCH_ALL_USERS_AS_ADMIN_REQUEST,
  payload,
});
export const fetchAllUsersAsAdminFailure = payload => ({
  type: FETCH_ALL_USERS_AS_ADMIN_FAILURE,
  payload,
});
export const fetchAllUsersAsAdminSuccess = payload => ({
  type: FETCH_ALL_USERS_AS_ADMIN_SUCCESS,
  payload,
});

export const addUserRoleAsAdminRequest = payload => ({
  type: ADD_USER_ROLE_AS_ADMIN_REQUEST,
  payload,
});
export const addUserRoleAsAdminFailure = payload => ({
  type: ADD_USER_ROLE_AS_ADMIN_FAILURE,
  payload,
});
export const addUserRoleAsAdminSuccess = payload => ({
  type: ADD_USER_ROLE_AS_ADMIN_SUCCESS,
  payload,
});

export const fetchAllUsersWithEmailAccountRequest = payload => ({
  type: FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_REQUEST,
  payload,
});
export const fetchAllUsersWithEmailAccountFailure = payload => ({
  type: FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_FAILURE,
  payload,
});
export const fetchAllUsersWithEmailAccountSuccess = payload => ({
  type: FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_SUCCESS,
  payload,
});

export const deleteUserWithEmailAccountRequest = payload => ({
  type: DELETE_USER_WITH_EMAIL_ACCOUNT_REQUEST,
  payload,
});
export const deleteUserWithEmailAccountFailure = payload => ({
  type: DELETE_USER_WITH_EMAIL_ACCOUNT_FAILURE,
  payload,
});
export const deleteUserWithEmailAccountSuccess = payload => ({
  type: DELETE_USER_WITH_EMAIL_ACCOUNT_SUCCESS,
  payload,
});

export const sendPasswordResetLinkEmailAccountRequest = payload => ({
  type: SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_REQUEST,
  payload,
});
export const sendPasswordResetLinkEmailAccountFailure = payload => ({
  type: SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_FAILURE,
  payload,
});
export const sendPasswordResetLinkEmailAccountSuccess = payload => ({
  type: SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_SUCCESS,
  payload,
});

export const sendVerificationEmailTemplateForEmailAccountRequest = payload => ({
  type: SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_REQUEST,
  payload,
});
export const sendVerificationEmailTemplateForEmailAccountFailure = payload => ({
  type: SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_FAILURE,
  payload,
});
export const sendVerificationEmailTemplateForEmailAccountSuccess = payload => ({
  type: SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_SUCCESS,
  payload,
});

export const fetchAllUsers = () => async (dispatch) => {
  dispatch(fetchAllUsersAsAdminRequest());
  const userData = firebase.getInstance()
    .functions()
    .httpsCallable('getAdmins');

  userData()
    .then((users) => {
      dispatch(fetchAllUsersAsAdminSuccess(users.data));
    })
    .catch((error) => {
      dispatch(fetchAllUsersAsAdminFailure(error));
    });
};

// eslint-disable-next-line consistent-return
export const fetchAllUsersWithEmailAccount = () => async (dispatch) => {
  dispatch(fetchAllUsersWithEmailAccountRequest());
  try {
    const listAllUsersWithEmails = firebase
      .getInstance()
      .functions()
      .httpsCallable('listAllUsersWithEmails', { timeout: 1000 * 540 });

    const listAllIncognitoUsers = firebase
      .getInstance()
      .functions()
      .httpsCallable('listAllIncognitoUsers', { timeout: 1000 * 540 });

    const userDetailsData = firebase
      .getInstance()
      .functions()
      .httpsCallable('listAllUsersDetails', { timeout: 1000 * 540 });

    const allUsersWithEmails = await listAllUsersWithEmails();
    const allIncognitoUsers = await listAllIncognitoUsers();

    const callForUserDetails = async (endpoint) => {
      dispatch(fetchAllUsersWithEmailAccountRequest());
      const { data } = await userDetailsData({ users: endpoint });
      dispatch(fetchAllUsersWithEmailAccountSuccess({ data, fetching: true }));
      return new Promise(resolve => setTimeout(resolve(data), 1000));
    };

    const splitAllIncognitoUsers = pipe(splitEvery(50))(pathOr([], ['data'], allIncognitoUsers));
    splitAllIncognitoUsers.reduce(async (previousPromise, nextUserCollection) => {
      await previousPromise;
      return callForUserDetails(nextUserCollection);
    }, Promise.resolve());

    const splitAllUsersWithEmails = pipe(splitEvery(50))(pathOr([], ['data'], allUsersWithEmails));
    splitAllUsersWithEmails.reduce(async (previousPromise, nextUserCollection) => {
      await previousPromise;
      return callForUserDetails(nextUserCollection);
    }, Promise.resolve());
  } catch (e) {
    dispatch(fetchAllUsersWithEmailAccountFailure());
    return e;
  }
};

export const deleteUserWithEmailAccount = payload => async (dispatch) => {
  dispatch(deleteUserWithEmailAccountRequest());
  try {
    await authService.deleteUserById(payload.uid);
    return dispatch(deleteUserWithEmailAccountSuccess(payload));
  } catch (e) {
    return dispatch(deleteUserWithEmailAccountFailure());
  }
};

export const sendUserPasswordResetForEmailAccount = payload => async (dispatch) => {
  dispatch(sendPasswordResetLinkEmailAccountRequest());
  try {
    await authService.sendUserPasswordResetEmail(payload.email);
    return dispatch(sendPasswordResetLinkEmailAccountSuccess(payload));
  } catch (e) {
    return dispatch(sendPasswordResetLinkEmailAccountFailure(e.message));
  }
};

export const sendVerificationEmailLinkForEmailAccount = payload => async (dispatch) => {
  dispatch(sendVerificationEmailTemplateForEmailAccountRequest());
  try {
    await authService.sendVerificationEmailLink(payload.uid);
    return dispatch(sendVerificationEmailTemplateForEmailAccountSuccess(payload));
  } catch (e) {
    return dispatch(sendVerificationEmailTemplateForEmailAccountFailure(e.message));
  }
};

export const addUserRole = payload => async (dispatch) => {
  dispatch(addUserRoleAsAdminRequest());
  const addRoleToUser = firebase.getInstance()
    .functions()
    .httpsCallable('addRoleToUser');

  addRoleToUser({
    uid: payload.uid,
    role: payload.role,
    regions: payload.regions,
  })
    .then((user) => {
      dispatch(addUserRoleAsAdminSuccess(user.data));
      dispatch(getUserDetails());
    })
    .catch((error) => {
      dispatch(addUserRoleAsAdminFailure(error));
    });
};


const initState = {
  accountType: [],
  allUsers: [],
  allUsersCount: 0,
  allUsersWithAccount: [],
  error: false,
  hasData: false,
  incognitoType: [],
  incognitoTypeCount: 0,
  isLoading: false,
  success: false,
  unverifiedUsers: 0,
  verifiedUsers: 0,
};

const adminsReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_ALL_USERS_AS_ADMIN_REQUEST:
    case ADD_USER_ROLE_AS_ADMIN_REQUEST:
    case FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_REQUEST:
    case DELETE_USER_WITH_EMAIL_ACCOUNT_REQUEST:
    case SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_REQUEST:
    case SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasData: action.payload || false,
        error: false,
      };
    case FETCH_ALL_USERS_AS_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUsers: action.payload,
        error: false,
      };
    case FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        allUsers: [...state.allUsers, ...action.payload.data],
        accountType: [...state.accountType, ...filter(record => typeof record.email === 'string', action.payload.data)],
        incognitoType: [...state.incognitoType, ...filter(propEq('email', false), action.payload.data)],
        allUsersWithAccount: [...state.allUsersWithAccount, ...action.payload.data],
        allUsersCount: state.allUsersCount + length(action.payload.data),
        verifiedUsers: state.verifiedUsers + pipe(
          filter(record => typeof record.email === 'string'),
          filter(propEq('emailVerified', true)),
          length,
        )(action.payload.data),
        unverifiedUsers: state.unverifiedUsers + pipe(
          filter(record => typeof record.email === 'string'),
          filter(propEq('emailVerified', false)),
          length,
        )(action.payload.data),
        incognitoTypeCount: state.incognitoTypeCount + length(filter(propEq('email', false), action.payload.data)),
        error: false,
      };
    case DELETE_USER_WITH_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // eslint-disable-next-line max-len
        allUsersWithAccount: reject(whereEq({ uid: action.payload.uid }), state.allUsersWithAccount),
        verifiedUsers: action.payload.emailVerified ? state.verifiedUsers - 1 : state.verifiedUsers,
        // eslint-disable-next-line max-len
        unverifiedUsers: action.payload.emailVerified ? state.unverifiedUsers : state.unverifiedUsers - 1,
        error: false,
      };
    case DELETE_USER_WITH_EMAIL_ACCOUNT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case ADD_USER_ROLE_AS_ADMIN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        allUsers: pipe(
          map(
            when(
              propEq('uid', action.payload.uid), mergeLeft(action.payload),
            ),
          ),
        )(state.allUsers),
      };
    case SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        success: `Successfully send password reset link to email: ${action.payload.email}`,
      };
    case SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        success: 'Successfully send email to selected user!',
      };
    case SEND_PASSWORD_RESET_LINK_FOR_EMAIL_ACCOUNT_FAILURE:
    case SEND_VERIFICATION_EMAIL_TEMPLATE_FOR_EMAIL_ACCOUNT_FAILURE:
    case FETCH_ALL_USERS_AS_ADMIN_FAILURE:
    case FETCH_ALL_USERS_WITH_EMAIL_ACCOUNTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload || true,
        success: false,
      };
    default:
      return state;
  }
};

export default (state, action) => [adminsReducer].reduce(
  (newState, currentReducer) => currentReducer(newState, action),
  state,
);
