import React, {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  applySpec,
  countBy,
  descend,
  filter,
  groupBy,
  head,
  includes,
  isEmpty,
  last,
  map,
  omit,
  pathOr,
  pipe,
  prop,
  sortBy,
  toLower,
  toPairs,
} from 'ramda';
import { renameKeys } from 'ramda-adjunct';
import PropTypes from 'prop-types';
import { ToastContainer, toast } from 'react-toastify';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EmailIcon from '@material-ui/icons/Email';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { Paper, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import Table from '../components/table';
import i18n from '../helpers/i18n';

const toPercent = decimal => decimal;
const defaultFileType = 'json';

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0;

  return toPercent(ratio, 2);
};

const renderTooltipContent = (o) => {
  const {
    payload,
    label,
  } = o;
  const total = payload && payload.reduce((result, entry) => result + entry.value, 0);

  return (
    <div className="customized-tooltip-content">
      <p className="total">{`${label} (Total: ${total})`}</p>
      <ul className="list">
        {payload && payload.map((entry, index) => (
          // eslint-disable-next-line
          <li key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
          </li>
        ))}
      </ul>
    </div>
  );
};


const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

const columnData = [
  {
    id: 'uid',
    numeric: false,
    disablePadding: false,
    i18nKey: 'id',
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    i18nKey: 'name',
    valueCallback: value => (value || 'INCOGNITO'),
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    i18nKey: 'email',
    valueCallback: value => (value || 'INCOGNITO'),
  },
  {
    id: 'emailVerified',
    numeric: false,
    disablePadding: false,
    valueCallback: value => (value ? <CheckCircleIcon color="primary" /> : (
      <CancelIcon
        color="disabled"
      />
    )),
    i18nKey: 'email_verified',
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    valueCallback: value => value.creationTime,
    i18nKey: 'created_at',
    timestamp: true,
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    valueCallback: value => value.lastRefreshTime,
    i18nKey: 'last_seen',
    timestamp: true,
  },
  {
    id: 'country',
    numeric: false,
    disablePadding: false,
    i18nKey: 'country',
  },
  {
    id: 'language',
    numeric: false,
    disablePadding: false,
    i18nKey: 'language',
  },
  {
    id: 'currentDevice',
    numeric: false,
    disablePadding: false,
    i18nKey: 'current_device',
  },
  {
    id: 'numberOfExercises',
    numeric: false,
    disablePadding: false,
    i18nKey: 'number_of_exercises',
  },
  {
    id: 'notificationsAllowed',
    numeric: false,
    disablePadding: false,
    i18nKey: 'notifications_allowed',
    valueCallback: value => (value ? <CheckCircleIcon color="primary" /> : (
      <CancelIcon
        color="disabled"
      />
    )),
  },
  {
    id: 'trackingAllowed',
    numeric: false,
    disablePadding: false,
    i18nKey: 'tracking_allowed',
    valueCallback: value => (value ? <CheckCircleIcon color="primary" /> : (
      <CancelIcon
        color="disabled"
      />
    )),
  },
];

const Users = ({
  allUsersWithEmailAccount,
  deleteUserWithEmailAccount,
  fetchAllUsersWithEmailAccount,
  sendVerificationEmailLink,
  sendUserPasswordReset,
  getCountries,
  isLoading,
  unverifiedUsers,
  verifiedUsers,
  allUsersCount,
  error,
  success,
  usersIncognitoTypeCount,
}) => {
  const classes = useStyles();
  const [errorMessage, setErrorMessage] = useState();
  const [successMessage, setSuccessMessage] = useState();
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchPhraseID, setSearchPhraseID] = useState('');
  const [searchData, setSearchData] = useState([]);

  // DOWNLOAD STATE

  const anchorRef = useRef();

  const fileNames = {
    csv: `generated_data_${Date.now()}.csv`,
    json: `generated_data_${Date.now()}.json`,
  };

  const [fileType, setFileType] = useState(defaultFileType);
  const [fileDownloadUrl, setFileDownloadUrl] = useState(null);

  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };

  const handleUserDelete = useCallback((users) => {
    setErrorMessage();
    setSuccessMessage();
    return users.forEach(user => deleteUserWithEmailAccount({
      emailVerified: pathOr(false, ['emailVerified'], user),
      uid: user.uid,
    }));
  }, [deleteUserWithEmailAccount]);

  // eslint-disable-next-line max-len
  const handleSendUserPasswordResetLink = useCallback((users) => {
    setErrorMessage();
    setSuccessMessage();
    return users.forEach(user => sendUserPasswordReset({
      email: user.email,
    }));
  }, [deleteUserWithEmailAccount]);

  // eslint-disable-next-line max-len
  const handleSendVerificationEmailLink = useCallback(users => users.forEach(user => sendVerificationEmailLink({
    uid: user.uid,
  })), [deleteUserWithEmailAccount]);

  const notifyWithError = useCallback(() => toast.error(errorMessage, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }), [errorMessage]);

  const notifyWithSuccess = useCallback(() => toast.success(successMessage, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }), [successMessage]);

  useEffect(() => {
    setErrorMessage(error);
    // eslint-disable-next-line no-unused-expressions
    errorMessage && notifyWithError();
  }, [error, errorMessage]);

  useEffect(() => {
    setSuccessMessage(success);
    // eslint-disable-next-line no-unused-expressions
    successMessage && notifyWithSuccess();
  }, [success, successMessage]);

  useEffect(() => {
    fetchAllUsersWithEmailAccount();
  }, [getCountries]);

  useEffect(() => {
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const allUsersTotalNumber = useMemo(() => [
    {
      name: i18n.t('total_users'),
      accounts: allUsersCount,
    },
    {
      name: i18n.t('account_anonymous'),
      accounts: usersIncognitoTypeCount,
    },
    {
      name: i18n.t('email'),
      accounts: allUsersCount - usersIncognitoTypeCount,
    },
  ], [allUsersCount, usersIncognitoTypeCount, verifiedUsers, unverifiedUsers]);

  const allUsersWithEmailsSeparation = useMemo(() => [
    {
      name: i18n.t('verified_users'),
      verification: verifiedUsers,
    },
    {
      name: i18n.t('unverified_users'),
      verification: unverifiedUsers,
    },
  ], [verifiedUsers, unverifiedUsers]);


  const data = allUsersWithEmailAccount && allUsersWithEmailAccount.map(user => ({
    name: user.emailVerified ? 'A1' : 'B1',
    value: user.country,
  }));

  const dataByLanguage = allUsersWithEmailAccount && allUsersWithEmailAccount.map(user => ({
    name: user.emailVerified ? 'A1' : 'B1',
    value: user.language === 'NOT SELECTED' ? 'de' : user.language,
  }));

  // eslint-disable-next-line max-len
  const dataByNotificationsAllowed = allUsersWithEmailAccount && allUsersWithEmailAccount.map(user => ({
    value: user.notificationsAllowed ? i18n.t('yes') : i18n.t('no'),
  }));

  const dataByTrackingAllowed = allUsersWithEmailAccount && allUsersWithEmailAccount.map(user => ({
    value: user.trackingAllowed ? i18n.t('yes') : i18n.t('no'),
  }));

  const groupByAccountType = groupBy((user) => {
    const accountType = user.email;
    return accountType === false ? 'incognito' : 'email';
  });

  const sortByDate = arrayOfData => arrayOfData && arrayOfData.sort((a, b) => {
    const aa = a.date.split('/')
      .reverse()
      .join();
    const bb = b.date.split('/')
      .reverse()
      .join();
    // eslint-disable-next-line no-nested-ternary
    return aa < bb ? -1 : (aa > bb ? 1 : 0);
  });

  const convertDate = (inputFormat) => {
    // eslint-disable-next-line
    const pad = s => (s < 10) ? '0' + s : s;
    const createDate = new Date(inputFormat);
    return [pad(createDate.getDate()), pad(createDate.getMonth() + 1), createDate.getFullYear()].join('/');
  };

  const getAllUserInTimeframe = pipe(
    map(
      countBy(user => convertDate(user.metadata.creationTime)),
    ),
    map(toPairs),
    map(
      map(applySpec({
        date: pipe(head),
        value: last,
      })),
    ),
  )(groupByAccountType(allUsersWithEmailAccount));

  const getAllUserBackInTimeframe = pipe(
    map(
      countBy(user => convertDate(user.metadata.lastRefreshTime)),
    ),
    map(toPairs),
    map(
      map(applySpec({
        date: pipe(head),
        value: last,
      })),
    ),
  )(groupByAccountType(allUsersWithEmailAccount));

  const renameKeysBasedOnType = type => getAllUserInTimeframe && pipe(
    pathOr([], [type]),
    map(
      renameKeys({ value: type }),
    ),
  )(getAllUserInTimeframe);

  const renameKeysBasedOnTypeWithRefresh = type => getAllUserBackInTimeframe && pipe(
    pathOr([], [type]),
    map(
      renameKeys({ value: type }),
    ),
  )(getAllUserBackInTimeframe);

  const incognitoTimeFrameAccounts = sortByDate(renameKeysBasedOnType('incognito'));
  const emailTimeFrameAccounts = sortByDate(renameKeysBasedOnType('email'));
  const incognitoRefreshTimeFrameAccounts = sortByDate(renameKeysBasedOnTypeWithRefresh('incognito'));
  const emailRefreshTimeFrameAccounts = sortByDate(renameKeysBasedOnTypeWithRefresh('email'));

  const usersByCountry = data && pipe(
    countBy(prop('value')),
    toPairs,
    map(applySpec({
      name: pipe(head),
      country: last,
    })),
    sortBy(descend(prop('name'))), // or ascend
  )(data);

  const usersByLanguage = data && pipe(
    countBy(prop('value')),
    toPairs,
    map(applySpec({
      name: pipe(head),
      language: last,
    })),
    sortBy(descend(prop('name'))), // or ascend
  )(dataByLanguage);

  const usersByNotificationsAllowed = data && pipe(
    countBy(prop('value')),
    toPairs,
    map(applySpec({
      name: pipe(head),
      notifications: last,
    })),
    sortBy(descend(prop('count'))), // or ascend
  )(dataByNotificationsAllowed);

  const usersByTrackingAllowed = data && pipe(
    countBy(prop('value')),
    toPairs,
    map(applySpec({
      name: pipe(head),
      tracking: last,
    })),
    sortBy(descend(prop('count'))), // or ascend
  )(dataByTrackingAllowed);

  const handleSearchPhrase = () => (event) => {
    setSearchPhrase(event.target.value);
    // eslint-disable-next-line max-len
    setSearchData(pipe(filter(val => includes(toLower(event.target.value), toLower(val.email))))(allUsersWithEmailAccount));
  };

  const handleSearchById = () => (event) => {
    setSearchPhraseID(event.target.value);
    // eslint-disable-next-line max-len
    setSearchData(pipe(filter(val => includes(toLower(event.target.value), toLower(val.uid))))(allUsersWithEmailAccount));
  };

  const handleFileTypeChange = useCallback(event => setFileType(event.target.value), [setFileType]);

  const generateCsvFromOutput = useCallback((content) => {
    let csv = '';
    content.forEach((value) => {
      value.forEach((item, i) => {
        const innerValue = item === null ? '' : item.toString();
        let result = innerValue.replace(/"/g, '""');
        if (result.search(/([",\n])/g) >= 0) {
          result = `"${result}"`;
        }
        if (i > 0) { csv += ','; }
        csv += result;
      });
      csv += '\n';
    });
    return csv;
  }, []);

  const download = useCallback(async (event) => {
    const fallbackData = pipe(
      map(userObject => ({
        creationTime: convertDate(userObject.metadata.creationTime),
        lastSignInTime: convertDate(userObject.metadata.lastSignInTime),
        lastRefreshTime: convertDate(userObject.metadata.lastRefreshTime),
        ...omit(['metadata'], userObject),
      })),
    )(allUsersWithEmailAccount);

    event.preventDefault();
    let output;
    if (fileType === 'json') {
      output = await JSON.stringify(fallbackData, null, 4);
    } else if (fileType === 'csv') {
      const contents = [];
      contents.push([
        'country',
        'creationTime',
        'currentDevice',
        'disabled',
        'email',
        'emailVerified',
        'language',
        'lastRefreshTime',
        'lastSignInTime',
        'name',
        'notificationsAllowed',
        'numberOfExercises',
        'trackingAllowed',
        'uid',
      ]);
      await fallbackData.forEach(row => contents.push([
        row.country,
        row.creationTime,
        row.currentDevice,
        row.email,
        row.emailVerified,
        row.language,
        row.lastRefreshTime,
        row.lastSignInTime,
        row.name,
        row.notificationsAllowed,
        row.numberOfExercises,
        row.trackingAllowed,
        row.uid,
      ]));
      output = generateCsvFromOutput(contents);
    }
    const blob = await new Blob([output]);
    const fileUrl = await URL.createObjectURL(blob);
    await setFileDownloadUrl(fileUrl);
    await setTimeout(() => anchorRef.current.click(), 2500);
  }, [allUsersWithEmailAccount, fileDownloadUrl, setFileDownloadUrl]);

  return (
    <Fragment>
      <div>
        <ToastContainer />
        <div className={classes.root}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                {isLoading && (
                  <p style={{ color: '#ff7300' }}>
                    {i18n.t('loading_wait')}
                  </p>
                )}
                <Grid
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item spacing={5} xs={3}>
                    <FormControl fullWidth>
                      <Select
                        disabled={isLoading}
                        onChange={handleFileTypeChange}
                        value={fileType}
                      >
                        <MenuItem value="csv" name="csv">CSV</MenuItem>
                        <MenuItem value="json" name="json">JSON</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid xs={1} />
                  <Grid item spacing={5} xs={3}>
                    <FormControl fullWidth>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={isLoading}
                        onClick={download}
                      >
                        {i18n.t('download')}
                      </Button>
                      <a
                        style={{
                          visibility: 'hidden',
                        }}
                        download={fileNames[fileType]}
                        href={fileDownloadUrl}
                        ref={anchorRef}
                      >
                        {i18n.t('download')}
                      </a>
                    </FormControl>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid>
                  <Typography variant="h6" component="h6">
                    {`${i18n.t('chart_dynamic_accounts')}`}
                  </Typography>
                </Grid>
                <AreaChart
                  width={width - 100}
                  height={200}
                  data={incognitoTimeFrameAccounts}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis tickFormatter={toPercent} />
                  <Tooltip content={renderTooltipContent} />
                  <Area
                    dataKey="incognito"
                    fill="#93ab39"
                    stackId="1"
                    stroke="#ff7300"
                    type="monotone"
                  />
                  <Brush />
                </AreaChart>
                <Grid>
                  <Typography variant="h6" component="h6">
                    {`${i18n.t('chart_dynamic_accounts_email')}`}
                  </Typography>
                </Grid>
                <AreaChart
                  width={width - 100}
                  height={200}
                  data={emailTimeFrameAccounts}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis tickFormatter={toPercent} />
                  <Tooltip content={renderTooltipContent} />
                  <Area
                    dataKey="email"
                    fill="#93ab39"
                    stackId="1"
                    stroke="#ff7300"
                    type="monotone"
                  />
                  <Brush />
                </AreaChart>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper className={classes.paper}>
                <Grid>
                  <Typography variant="h6" component="h6">
                    {`${i18n.t('chart_dynamic_accounts_returns')}`}
                  </Typography>
                </Grid>
                <AreaChart
                  width={width - 100}
                  height={200}
                  data={incognitoRefreshTimeFrameAccounts}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis tickFormatter={toPercent} />
                  <Tooltip content={renderTooltipContent} />
                  <Area
                    dataKey="incognito"
                    fill="#555"
                    stackId="1"
                    stroke="#ff7300"
                    type="monotone"
                  />
                  <Brush />
                </AreaChart>
                <Grid>
                  <Typography variant="h6" component="h6">
                    {`${i18n.t('chart_dynamic_accounts_email_returns')}`}
                  </Typography>
                </Grid>
                <AreaChart
                  width={width - 100}
                  height={200}
                  data={emailRefreshTimeFrameAccounts}
                  margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 0,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="date" />
                  <YAxis tickFormatter={toPercent} />
                  <Tooltip content={renderTooltipContent} />
                  <Area
                    dataKey="email"
                    fill="#555"
                    stackId="1"
                    stroke="#ff7300"
                    type="monotone"
                  />
                  <Brush />
                </AreaChart>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  direction="column"
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Typography variant="h6" component="h6">
                      {`${i18n.t('all_users')}`}
                    </Typography>
                  </Grid>
                  <Grid>
                    {allUsersTotalNumber && (
                      <BarChart
                        width={(width / 2) - 100}
                        height={200}
                        data={allUsersTotalNumber}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey="name"
                          scale="point"
                          padding={{
                            left: 10,
                            right: 10,
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="accounts" fill="#93ab39" background={{ fill: '#eee' }} />
                      </BarChart>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  direction="column"
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Typography variant="h6" component="h6">
                      {`${i18n.t('all_users')}`}
                    </Typography>
                  </Grid>
                  <Grid>
                    {allUsersWithEmailsSeparation && (
                      <BarChart
                        width={(width / 2) - 100}
                        height={200}
                        data={allUsersWithEmailsSeparation}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey="name"
                          scale="point"
                          padding={{
                            left: 10,
                            right: 10,
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="verification" fill="#ff7300" background={{ fill: '#eee' }} />
                      </BarChart>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  direction="column"
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Typography variant="h6" component="h6">
                      {`${i18n.t('country')}`}
                    </Typography>
                  </Grid>
                  <Grid>
                    {usersByCountry && (
                      <BarChart
                        width={(width / 2) - 100}
                        height={200}
                        data={usersByCountry}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey="name"
                          scale="point"
                          padding={{
                            left: 10,
                            right: 10,
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="country" fill="#93ab39" background={{ fill: '#eee' }} />
                      </BarChart>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  direction="column"
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Typography variant="h6" component="h6">
                      {`${i18n.t('language')}`}
                    </Typography>
                  </Grid>
                  <Grid>
                    {usersByLanguage && (
                      <BarChart
                        width={(width / 2) - 100}
                        height={200}
                        data={usersByLanguage}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey="name"
                          scale="point"
                          padding={{
                            left: 10,
                            right: 10,
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="language" fill="#93ab39" background={{ fill: '#eee' }} />
                      </BarChart>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  direction="column"
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Typography variant="h6" component="h6">
                      {`${i18n.t('notifications_allowed')}`}
                    </Typography>
                  </Grid>
                  <Grid>
                    {usersByNotificationsAllowed && (
                      <BarChart
                        width={(width / 2) - 100}
                        height={200}
                        data={usersByNotificationsAllowed}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey="name"
                          scale="point"
                          padding={{
                            left: 10,
                            right: 10,
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar
                          background={{ fill: '#eee' }}
                          dataKey="notifications"
                          fill="#93ab39"
                        />
                      </BarChart>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
            <Grid item md={6} xs={12}>
              <Paper className={classes.paper}>
                <Grid
                  direction="column"
                  container
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid>
                    <Typography variant="h6" component="h6">
                      {`${i18n.t('tracking_allowed')}`}
                    </Typography>
                  </Grid>
                  <Grid>
                    {usersByTrackingAllowed && (
                      <BarChart
                        width={(width / 2) - 100}
                        height={200}
                        data={usersByTrackingAllowed}
                        margin={{
                          top: 5,
                          right: 30,
                          left: 20,
                          bottom: 5,
                        }}
                        barSize={20}
                      >
                        <XAxis
                          dataKey="name"
                          scale="point"
                          padding={{
                            left: 10,
                            right: 10,
                          }}
                        />
                        <YAxis />
                        <Tooltip />
                        <Legend />
                        <CartesianGrid strokeDasharray="3 3" />
                        <Bar dataKey="tracking" fill="#93ab39" background={{ fill: '#eee' }} />
                      </BarChart>
                    )}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
        <Grid
          alignContent="center"
          container
          item
          justify="center"
          spacing={4}
          xs={12}
        >
          <Grid
            item
            container
            xs={2}
            alignItems="center"
            justify="center"
          >
            <Typography variant="h6" component="h6">
              {i18n.t('selectedFilters')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={4}
            alignItems="center"
            justify="center"
          >
            <FormControl fullWidth variant="filled">
              <TextField
                label={i18n.t('search_email')}
                value={searchPhrase}
                defaultValue={searchPhrase}
                id="search"
                onChange={handleSearchPhrase()}
              />
            </FormControl>
          </Grid>
          <Grid
            item
            container
            xs={4}
            alignItems="center"
            justify="center"
          >
            <FormControl fullWidth variant="filled">
              <FormControl fullWidth variant="filled">
                <TextField
                  label={i18n.t('search_id')}
                  value={searchPhraseID}
                  defaultValue={searchPhraseID}
                  id="search"
                  onChange={handleSearchById()}
                />
              </FormControl>
            </FormControl>
          </Grid>
          <Grid xs={1} container justify="center" alignContent="center">
            <IconButton
              aria-label="delete"
              onClick={() => {
                setSearchPhrase('');
                setSearchPhraseID('');
              }}
            >
              <RotateLeftIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Table
          isLoading={isLoading}
          keyColumn="uid"
          data={
            (isEmpty(searchPhrase) && isEmpty(searchPhraseID))
              ? allUsersWithEmailAccount : searchData
          }
          columns={columnData}
          onDeleteItems={handleUserDelete}
          allowAddItem={false}
          toolbarItems={
            [
              {
                tooltipLabel: i18n.t('send_password_reset_link'),
                onClick: handleSendUserPasswordResetLink,
                icon: (<RotateLeftIcon />),
              },
              {
                tooltipLabel: i18n.t('send_verification_email_link'),
                onClick: handleSendVerificationEmailLink,
                icon: (<EmailIcon />),
              },
            ]
          }
        />
      </div>
    </Fragment>
  );
};

Users.propTypes = {
  allUsersCount: PropTypes.number,
  allUsersWithEmailAccount: PropTypes.array.isRequired,
  deleteUserWithEmailAccount: PropTypes.func.isRequired,
  error: PropTypes.any,
  fetchAllUsersWithEmailAccount: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  sendUserPasswordReset: PropTypes.func.isRequired,
  sendVerificationEmailLink: PropTypes.func.isRequired,
  success: PropTypes.any,
  unverifiedUsers: PropTypes.number,
  usersIncognitoTypeCount: PropTypes.number,
  verifiedUsers: PropTypes.number,
};

export default Users;
