import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import XHR from 'i18next-http-backend';

import de from '../config/translations/de.json';
import en from '../config/translations/en.json';
import pl from '../config/translations/pl.json';
import it from '../config/translations/it.json';
import fr from '../config/translations/fr.json';
import es from '../config/translations/es.json';
import ru from '../config/translations/ru.json';
import nl from '../config/translations/nl.json';
import ua from '../config/translations/ua.json';

const options = {
  order: ['querystring', 'navigator'],
  lookupQuerystring: 'lng',
};

i18next
  .use(XHR)
  .use(LanguageDetector)
  .init({
    detection: options,
    fallbackLng: 'en',
    debug: true,
    resources: {
      de: {
        translation: de,
      },
      en: {
        translation: en,
      },
      pl: {
        translation: pl,
      },
      ru: {
        translation: ru,
      },
      nl: {
        translation: nl,
      },
      it: {
        translation: it,
      },
      fr: {
        translation: fr,
      },
      es: {
        translation: es,
      },
      ua: {
        translation: ua,
      },
    },
  });

const translate = (key) => {
  const newKey = key.replace(/[\w]([A-Z])/g, m => (`${m[0]}_${m[1]}`.toLowerCase()));
  return i18next.t(newKey);
};

export default {
  ...i18next,
  t: translate,
};
