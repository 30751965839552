import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  isEmpty,
  length,
  pipe,
  pluck,
  take,
  head,
} from 'ramda';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import RegionSelector from '../../../components/regionSelector';
import i18n from '../../../helpers/i18n';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  editBox: {
    padding: 30,
  },
  formBox: {
    padding: 30,
    margin: 15,
  },
});

const AddNewCountryForm = ({
  addNewCountry,
  languages,
}) => {
  const classes = useRowStyles();

  const [initValues] = useState({
    countryName: '',
    hasOwnContent: true,
    countryCode: '',
    isThirdParty: true,
    i18nKey: '',
    webshopURI: 'https://',
    languages: ['en'],
  });

  const [countryManager, setCountryManager] = useState(false);

  const [countryCode, setCountryCode] = useState(initValues.countryCode);
  const [withContent, setWithContent] = useState(initValues.hasOwnContent);
  const [i18nKey, setI18nKey] = useState(initValues.i18nKey);
  const [countryName, setCountryName] = useState(initValues.countryCode);
  const [thirdParty, setThirdParty] = useState(initValues.isThirdParty);
  const [webshopURI, setWebshopURI] = useState(initValues.webshopURI);
  const [language, setLanguage] = useState(initValues.language);

  const [formValues] = useState(initValues);

  const disabled = useMemo(() => formValues && (
    length(countryCode) !== 2
    || isEmpty(countryCode)
    || isEmpty(i18nKey)
    || isEmpty(countryName)
    || isEmpty(webshopURI)
    || isEmpty(language)
  ), [
    countryCode,
    i18nKey,
    countryName,
    webshopURI,
    formValues,
    language,
  ]);

  const pickLanguage = useMemo(() => pipe(pluck('value'))(languages), [languages]);

  const handleSubmit = useCallback(() => {
    addNewCountry({
      country: {
        countryCode,
        hasOwnContent: withContent,
        i18nKey,
        language: pipe(take(1), head)(language), // to keep working old version of mobile app
        languages: language,
        name: countryName,
        thirdParty,
        webshopURI,
      },
    });
    setCountryManager(false);
    setCountryCode('');
    setWithContent(true);
    setI18nKey('');
    setCountryName('');
    setThirdParty('');
    setWebshopURI('');
    setLanguage(['en']);
  }, [
    addNewCountry,
    countryCode,
    countryName,
    i18nKey,
    language,
    setCountryCode,
    setCountryManager,
    setCountryName,
    setI18nKey,
    setLanguage,
    setThirdParty,
    setWebshopURI,
    setWithContent,
    thirdParty,
    webshopURI,
    withContent,
  ]);


  return (
    <Box margin={5}>
      <Grid
        container
        direction="row"
        justify="center"
      >
        <Fab
          color={!countryManager ? 'primary' : 'secondary'}
          aria-label={!countryManager ? 'add' : 'remove'}
          onClick={() => setCountryManager(!countryManager)}
        >
          {!countryManager ? <AddIcon /> : <RemoveIcon />}
        </Fab>
      </Grid>
      {countryManager && (
        <Paper elevation={3}>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="baseline"
              className={classes.formBox}
            >
              <div>
                <TextField
                  defaultValue={initValues.countryName}
                  error={isEmpty(countryName)}
                  helperText={i18n.t('country_name')}
                  id="countryName"
                  label={i18n.t('required')}
                  onChange={e => setCountryName(e.target.value)}
                  required
                />
              </div>
              <div>
                <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                  Required
                </InputLabel>
                <Select
                  className={classes.selectEmpty}
                  displayEmpty
                  id="hasOwnContent"
                  labelId="hasOwnContent-label"
                  onChange={e => setWithContent(e.target.value)}
                  value={initValues.hasOwnContent}
                >
                  <MenuItem value>{i18n.t('yes')}</MenuItem>
                  <MenuItem value={false}>{i18n.t('no')}</MenuItem>
                </Select>
                <FormHelperText>{i18n.t('own_content')}</FormHelperText>
              </div>
              <div>
                <TextField
                  defaultValue={initValues.countryCode}
                  error={isEmpty(countryCode) || length(countryCode) !== 2}
                  helperText={i18n.t('country_code')}
                  id="countryCode"
                  label={i18n.t('required')}
                  onChange={e => setCountryCode(e.target.value)}
                  required
                />
              </div>
              <div>
                <RegionSelector
                  multiple
                  value={language}
                  regions={pickLanguage}
                  onChange={e => setLanguage(e)}
                />
                <FormHelperText>{i18n.t('language_code')}</FormHelperText>
              </div>
              <div>
                <TextField
                  defaultValue={initValues.i18nKey}
                  error={isEmpty(i18nKey)}
                  helperText={i18n.t('translation_key')}
                  id="i18nKey"
                  label={i18n.t('required')}
                  onChange={e => setI18nKey(e.target.value)}
                  required
                />
              </div>
              <div>
                <InputLabel shrink id="isThirdParty-input-label">
                  {i18n.t('required')}
                </InputLabel>
                <Select
                  className={classes.selectEmpty}
                  displayEmpty
                  id="isThirdParty"
                  labelId="isThirdParty-label"
                  onChange={e => setThirdParty(e.target.value)}
                  value={initValues.isThirdParty}
                >
                  <MenuItem value>{i18n.t('yes')}</MenuItem>
                  <MenuItem value={false}>{i18n.t('no')}</MenuItem>
                </Select>
                <FormHelperText>{i18n.t('is_party')}</FormHelperText>
              </div>
              <div>
                <TextField
                  defaultValue={initValues.webshopURI}
                  error={isEmpty(webshopURI)}
                  helperText={i18n.t('url_shop')}
                  id="webShopURI"
                  label={i18n.t('required')}
                  onChange={e => setWebshopURI(e.target.value)}
                  required
                />
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.editBox}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                onClick={() => handleSubmit()}
              >
                {i18n.t('add_new_country')}
              </Button>
            </Grid>
          </form>
        </Paper>
      )}
    </Box>
  );
};

AddNewCountryForm.propTypes = {
  addNewCountry: PropTypes.func.isRequired,
  languages: PropTypes.array.isRequired,
};

export default AddNewCountryForm;
