import {
  prop,
  reject,
  sortBy,
  whereEq,
} from 'ramda';
import firebase from '../helpers/firebase';
import { realtimeStorage } from '../helpers/firebaseDatabase';

export const FETCH_VIDEOS_REQUEST = Symbol('FETCH_VIDEOS_REQUEST');
export const FETCH_VIDEOS_SUCCESS = Symbol('FETCH_VIDEOS_SUCCESS');
export const FETCH_VIDEOS_FAILURE = Symbol('FETCH_VIDEOS_FAILURE');

export const FETCH_VIDEO_CATEGORIES_REQUEST = Symbol('FETCH_VIDEO_CATEGORIES_REQUEST');
export const FETCH_VIDEO_CATEGORIES_SUCCESS = Symbol('FETCH_VIDEO_CATEGORIES_SUCCESS');
export const FETCH_VIDEO_CATEGORIES_FAILURE = Symbol('FETCH_VIDEO_CATEGORIES_FAILURE');

export const ADD_NEW_VIDEO_REQUEST = Symbol('ADD_NEW_VIDEO_REQUEST');
export const ADD_NEW_VIDEO_SUCCESS = Symbol('ADD_NEW_VIDEO_SUCCESS');
export const ADD_NEW_VIDEO_FAILURE = Symbol('ADD_NEW_VIDEO_FAILURE');

export const DELETE_VIDEO_REQUEST = Symbol('DELETE_VIDEO_REQUEST');
export const DELETE_VIDEO_SUCCESS = Symbol('DELETE_VIDEO_SUCCESS');
export const DELETE_VIDEO_FAILURE = Symbol('DELETE_VIDEO_FAILURE');

export const UPDATE_VIDEO_REQUEST = Symbol('UPDATE_VIDEO_REQUEST');
export const UPDATE_VIDEO_SUCCESS = Symbol('UPDATE_VIDEO_SUCCESS');
export const UPDATE_VIDEO_FAILURE = Symbol('UPDATE_VIDEO_FAILURE');

export const UPDATE_VIDEO_BY_ID_REQUEST = Symbol('UPDATE_VIDEO_BY_ID_REQUEST');
export const UPDATE_VIDEO_BY_ID_SUCCESS = Symbol('UPDATE_VIDEO_BY_ID_SUCCESS');
export const UPDATE_VIDEO_BY_ID_FAILURE = Symbol('UPDATE_VIDEO_BY_ID_FAILURE');

export const fetchVideosRequest = payload => ({
  type: FETCH_VIDEOS_REQUEST,
  payload,
});
export const fetchVideosFailure = payload => ({
  type: FETCH_VIDEOS_FAILURE,
  payload,
});
export const fetchVideosSuccess = payload => ({
  type: FETCH_VIDEOS_SUCCESS,
  payload,
});

export const fetchVideoCategoriesRequest = payload => ({
  type: FETCH_VIDEO_CATEGORIES_REQUEST,
  payload,
});
export const fetchVideoCategoriesFailure = payload => ({
  type: FETCH_VIDEO_CATEGORIES_FAILURE,
  payload,
});
export const fetchVideoCategoriesSuccess = payload => ({
  type: FETCH_VIDEO_CATEGORIES_SUCCESS,
  payload,
});

export const addNewVideoRequest = payload => ({
  type: ADD_NEW_VIDEO_REQUEST,
  payload,
});
export const addNewVideoFailure = payload => ({
  type: ADD_NEW_VIDEO_FAILURE,
  payload,
});
export const addNewVideoSuccess = payload => ({
  type: ADD_NEW_VIDEO_SUCCESS,
  payload,
});

export const deleteVideoRequest = payload => ({
  type: DELETE_VIDEO_REQUEST,
  payload,
});
export const deleteVideoFailure = payload => ({
  type: DELETE_VIDEO_FAILURE,
  payload,
});
export const deleteVideoSuccess = payload => ({
  type: DELETE_VIDEO_SUCCESS,
  payload,
});

export const updateVideoRequest = payload => ({
  type: UPDATE_VIDEO_REQUEST,
  payload,
});
export const updateVideoFailure = payload => ({
  type: UPDATE_VIDEO_FAILURE,
  payload,
});
export const updateVideoSuccess = payload => ({
  type: UPDATE_VIDEO_SUCCESS,
  payload,
});

export const updateVideoByIdRequest = payload => ({
  type: UPDATE_VIDEO_BY_ID_REQUEST,
  payload,
});
export const updateVideoByIdFailure = payload => ({
  type: UPDATE_VIDEO_BY_ID_FAILURE,
  payload,
});
export const updateVideoByIdSuccess = payload => ({
  type: UPDATE_VIDEO_BY_ID_SUCCESS,
  payload,
});

export const getVideos = () => async (dispatch) => {
  dispatch(fetchVideosRequest());
  firebase
    .getInstance()
    .firestore()
    .collection('v1')
    .doc('data')
    .collection('videos')
    .get()
    .then((snapshot) => {
      const result = [];
      snapshot.forEach((childSnapshot) => {
        const uid = childSnapshot.id;
        if (!childSnapshot.data() && childSnapshot.data().id) {
          realtimeStorage.update('videos', uid, {
            uid,
          });
        }
        result.push({
          ...childSnapshot.data(),
          uid,
        });
      });
      dispatch(fetchVideosSuccess({ result }));
    })
    .catch((error) => {
      dispatch(fetchVideosFailure(error));
    });
};

export const getVideoCategories = () => async (dispatch) => {
  dispatch(fetchVideoCategoriesRequest());
  firebase
    .getInstance()
    .firestore()
    .collection('v1')
    .doc('data')
    .collection('videoCategories')
    .get()
    .then((snapshot) => {
      const result = [];
      snapshot.forEach((childSnapshot) => {
        result.push({
          ...childSnapshot.data(),
          uid: childSnapshot.id,
        });
      });
      dispatch(fetchVideoCategoriesSuccess({ result }));
    })
    .catch((error) => {
      dispatch(fetchVideoCategoriesFailure(error));
    });
};

export const updateVideos = payload => async (dispatch) => {
  dispatch(updateVideoRequest());
  try {
    payload.map(videoPayload => realtimeStorage
      .update('videos', videoPayload.uid, videoPayload));
    dispatch(updateVideoSuccess(payload));
  } catch (error) {
    dispatch(updateVideoFailure(error));
  }
};

export const updateVideoById = payload => async (dispatch) => {
  dispatch(updateVideoByIdRequest());
  try {
    realtimeStorage.update('videos', payload.uid, payload);
    dispatch(updateVideoByIdSuccess(payload));
  } catch (error) {
    dispatch(updateVideoByIdFailure(error));
  }
};

export const addNewVideo = payload => async (dispatch) => {
  dispatch(addNewVideoRequest());
  realtimeStorage
    .push('videos', payload)
    .then((response) => {
      dispatch(updateVideoById({
        uid: response.id,
      }));
      dispatch(addNewVideoSuccess());
      setTimeout(() => dispatch(getVideos()), 750);
    })
    .catch((error) => {
      dispatch(addNewVideoFailure(error));
    });
};

export const deleteVideo = payload => async (dispatch) => {
  dispatch(deleteVideoRequest());
  if (payload) {
    realtimeStorage
      .delete('videos', [payload])
      .then(() => {
        dispatch(deleteVideoSuccess());
        dispatch(getVideos());
      })
      .catch((error) => {
        dispatch(deleteVideoFailure(error));
      });
  }
};

const initState = {
  isLoading: false,
  error: false,
  success: false,
  videos: [],
  videoCategories: [],
};

const sortByOrder = data => sortBy(prop('order'), data);

const videosReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_VIDEOS_REQUEST:
    case FETCH_VIDEO_CATEGORIES_REQUEST:
    case UPDATE_VIDEO_BY_ID_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case UPDATE_VIDEO_BY_ID_SUCCESS:
      return {
        ...state,
        isLoading: false,
        videos: [
          ...reject(whereEq({ uid: action.payload.uid }), state.videos),
          action.payload,
        ],
      };
    case UPDATE_VIDEO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        videos: action.payload,
      };
    case FETCH_VIDEOS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        videos: sortByOrder(action.payload.result),
      };
    case FETCH_VIDEO_CATEGORIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        videoCategories: action.payload.result,
      };
    case FETCH_VIDEOS_FAILURE:
    case UPDATE_VIDEO_BY_ID_FAILURE:
    case FETCH_VIDEO_CATEGORIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default (state, action) => [videosReducer].reduce(
  (newState, currentReducer) => currentReducer(newState, action),
  state,
);
