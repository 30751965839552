import React from 'react';
import { confirmAlert } from 'react-confirm-alert';

import i18n from './i18n';

const promptStyle = {
  textAlign: 'center',
  width: '500px',
  padding: '40px',
  backgroundColor: 'rgb(118,166,75)',
  boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
  color: '#fff',
};

const promptButtonStyle = {
  width: '160px',
  padding: '10px',
  border: '1px solid #fff',
  margin: '10px',
  cursor: 'pointer',
  background: 'none',
  color: '#fff',
  fontSize: '14px',
};

const confirmPrompt = ({ confirmAction, confirmI18nKey = 'delete_item_question' }) => {
  confirmAlert({
    // eslint-disable-next-line react/prop-types
    customUI: ({ onClose }) => (
      <div
        className="custom-ui"
        style={promptStyle}
      >
        <h1>
          {i18n.t('sure_question')}
        </h1>
        <p>
          {i18n.t(confirmI18nKey)}
        </p>
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
          }}
        >
          <button
            style={promptButtonStyle}
            onClick={() => {
              confirmAction();
              onClose();
            }}
            type="submit"
          >
            {i18n.t('yes')}
          </button>
          <button
            style={promptButtonStyle}
            onClick={onClose}
            type="submit"
          >
            {i18n.t('no')}
          </button>
        </div>
      </div>
    ),
  });
};

// eslint-disable-next-line import/prefer-default-export
export { confirmPrompt };
