import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import * as moment from 'moment';
import 'moment/locale/de';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Box from '@material-ui/core/Box';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';

import { ClipLoader } from 'react-spinners';
import TextField from '@material-ui/core/TextField';
import { isEmpty } from 'ramda';
import { realtimeStorage } from '../../helpers/firebaseDatabase';

import i18n from '../../helpers/i18n';
import I18nButton from '../../components/i18nButton';

import imageHelpers from '../../helpers/image';

moment.locale('de');

const Product = ({
  classes,
  history,
  match,
}) => {
  const productSku = match.params.sku || '';

  const [loading, setLoading] = useState(false);
  const [firebaseProductId, setFirebaseProductId] = useState('');
  const [iconUrl, setIconUrl] = useState('');
  const [product, setProduct] = useState({});

  const [notificationErrors, setNotificationsErrors] = useState(null);

  useEffect(() => {
    if (productSku) {
      readProduct();
    }
  }, []);

  const readProduct = async () => {
    try {
      setLoading(true);

      const newPimProducts = await realtimeStorage.getOnce('pimProducts');

      const newProduct = newPimProducts.find(p => p.sku === productSku);

      setFirebaseProductId(newProduct.firebaseProductId || '');
      setIconUrl(newProduct.icon || '');
      setProduct(newProduct);
    } finally {
      setLoading(false);
    }
  };

  const saveProduct = async () => {
    try {
      if (isEmpty(firebaseProductId) && isEmpty(iconUrl)) {
        setNotificationsErrors(['You need to add Firebase Product Id or icon url']);
      }

      setNotificationsErrors([]);
      setLoading(true);

      if (iconUrl) {
        const exists = await imageHelpers.checkIfImageExists(iconUrl);

        if (!exists) {
          setNotificationsErrors(['Please pass correct image']);
          return;
        }
      }

      if (firebaseProductId) {
        const newProducts = await realtimeStorage.getOnce('products');

        const firebaseProduct = newProducts.find(p => p.id === firebaseProductId);

        if (firebaseProduct) {
          const icon = iconUrl || firebaseProduct.image.uri;
          await realtimeStorage.update('pimProducts', productSku, {
            firebaseProductId,
            icon,
          });
          setProduct({ ...product, icon });
        } else {
          setNotificationsErrors(['There is no product with given id in firebase']);
        }
        return;
      }

      if (iconUrl) {
        const icon = iconUrl;
        await realtimeStorage.update('pimProducts', productSku, {
          firebaseProductId: '',
          icon,
        });
        setProduct({ ...product, icon });
      }
    } finally {
      setLoading(false);
    }
  };

  const renderHeader = () => (
    <Grid item xs={12}>
      <Box alignItems="center" display="flex" flexDirection="row">
        <IconButton onClick={history.goBack} style={{ paddingRight: 16 }}>
          <ArrowBackIosRounded />
        </IconButton>
        <Typography variant="h4" component="h4">
          {i18n.t('product')}
          {product && product.name ? ` - ${product.name.en} - ${product.sku}` : ''}
        </Typography>
      </Box>
    </Grid>
  );

  const renderNotificationErrors = () => {
    if (notificationErrors) {
      return notificationErrors.map(errorToRender => (
        <Grid>
          <Typography color="error">
            {errorToRender}
          </Typography>
        </Grid>
      ));
    }
    return null;
  };

  const renderContentForm = () => (
    <Grid
      container
      direction="column"
      item
      xs={6}
    >
      <Box mt="10px">
        <Typography>{i18n.t('image')}</Typography>
        <img
          alt={product.name}
          src={product.image}
          style={{
            height: '217px',
          }}
        />
      </Box>
      <Box mt="10px">
        <Typography>{i18n.t('image')}</Typography>
        <img
          alt={product.name}
          src={product.icon}
          style={{
            height: '217px',
          }}
        />
      </Box>

      <Grid item>
        <FormControl>
          <TextField
            label="Firebase product id"
            id="firebase_product_id"
            value={firebaseProductId}
            onChange={e => setFirebaseProductId(e.target.value)}
            className={classes.textField}
            noMarginBottom
          />
        </FormControl>
      </Grid>
      <Grid item>
        <FormControl>
          <TextField
            label="Icon url"
            id="firebase_product_id"
            value={iconUrl}
            onChange={e => setIconUrl(e.target.value)}
            className={classes.textField}
            noMarginBottom
          />
          <FormHelperText>
            If you will leave this field empty this will be
            automaticaly generated fetched from firebase product
          </FormHelperText>
        </FormControl>
      </Grid>

      <Grid item className={classes.saveButton}>
        <I18nButton
          i18nKey="save"
          onClick={saveProduct}
          color="primary"
          disabled={loading || (isEmpty(firebaseProductId) && isEmpty(iconUrl))}
          loading={loading}
        />
      </Grid>

    </Grid>
  );

  const renderLoader = () => loading && (
    <div className={classes.loadingContainer}>
      <ClipLoader
        sizeUnit="px"
        size={35}
      />
    </div>
  );

  return (
    <Paper className={classes.paper} elevation={4}>
      <Grid container item spacing={2} direction="row">
        {renderHeader()}
        {renderContentForm()}
      </Grid>
      {renderNotificationErrors()}
      {renderLoader()}
    </Paper>
  );
};

Product.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 1000,
    minWidth: 400,
  }),
  loadingContainer: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ffffff85',
    top: 0,
    right: 0,
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    width: 400,
  },
  summary: {
    marginTop: 10,
    '& b': {
      fontWeight: 600,
      fontFamily: 'sans-serif',
    },
  },
  audienceContainer: {
    marginTop: 28,
  },
  heading: {
    fontFamily: 'Rajdhani',
    fontSize: 20,
  },
  contentHeading: {
    marginTop: 10,
  },
  deleteProductsButton: {
    minWidth: 40,
    padding: 0,
  },
  saveButton: {
    marginRight: 10,
  },
  regionSection: {
    marginBottom: 15,
  },
});

export default withStyles(styles)(Product);
