import { RestApiInterceptor } from './RestApiInterceptor';

class NotificationService {
  constructor({
    api = null,
  } = {}) {
    this.api = api || new RestApiInterceptor();
  }

  async getNotifications({ limit, offset }) {
    const response = await this.api.get('/api/notifications', { limit, offset });
    return response.data.data;
  }

  async getNotification(id) {
    const response = await this.api.get(`/api/notifications/${id}`);
    return response.data.data;
  }

  createNotification(params) {
    return this.api.post('/api/notifications', params);
  }

  cancelNotification(id) {
    return this.api.delete(`/api/notifications/${id}`);
  }

  getPlayers({ limit, offset }) {
    return this.api.get('/api/players', { limit, offset });
  }

  getPlayer(id) {
    return this.api.get(`/api/notifications/${id}`);
  }
}

export const notificationService = new NotificationService();

export default NotificationService;
