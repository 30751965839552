import React from 'react';
import propTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import I18nButton from './i18nButton';
import i18n from '../helpers/i18n';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    marginLeft: 0,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
});

class myDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  componentDidMount() {
    const { onRef } = this.props;
    onRef(this);
  }

  componentWillUnmount() {
    const { onRef } = this.props;
    onRef(null);
  }

  open() {
    this.setState({ open: true });
  }

  render() {
    const { open } = this.state;
    const { i18nTitleKey, children, onSave } = this.props;
    return (
      <Dialog open={open}>
        <DialogTitle>{i18n.t(i18nTitleKey)}</DialogTitle>
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <I18nButton i18nKey="cancel" onClick={() => this.setState({ open: false })} color="primary" />
          <I18nButton
            i18nKey="save"
            onClick={() => {
              this.setState({ open: false });
              onSave();
            }}
            color="primary"
          />
        </DialogActions>
      </Dialog>
    );
  }
}

myDialog.propTypes = {
  onRef: propTypes.func,
  i18nTitleKey: propTypes.string,
  children: propTypes.node,
  onSave: propTypes.func,
};

export default withStyles(styles)(myDialog);
