import React from 'react';
import PropTypes from 'prop-types';
import { titleCase } from 'change-case';

import I18nSelect from './i18nSelect';

const RegionSelector = (props) => {
  const {
    value,
    regions,
    onChange,
    multiple,
    disabled,
  } = props;

  return (
    <I18nSelect
      i18nLabelKey="region"
      value={value}
      options={regions.map(region => ({ value: region, label: titleCase(region) }))}
      onChange={onChange}
      multiple={multiple}
      disabled={disabled}
    />
  );
};

RegionSelector.propTypes = {
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  regions: PropTypes.array,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
};

RegionSelector.defaultProps = {
  multiple: false,
};

export default RegionSelector;
