import React, { Fragment } from 'react';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import Favicon from 'react-favicon';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import ReduxStoreConfigurator from './services/ReduxStoreConfigurator';

import App from './App';

const history = createBrowserHistory();
const reduxStoreConfigurator = new ReduxStoreConfigurator(history);

const store = reduxStoreConfigurator.configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <Fragment>
        <Favicon url="https://cdn.shopify.com/s/files/1/0185/6232/t/1/assets/br_favicon.png?7884022327281601547" />
        <App />
      </Fragment>
    </Router>
  </Provider>, document.getElementById('root'),
);
