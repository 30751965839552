import React, { Component, Fragment } from 'react';
import i18next from 'i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import startCase from 'lodash/startCase';

import { Grid } from '@material-ui/core';
import i18n from '../helpers/i18n';
import I18nButton from './i18nButton';

import MenuListItem from './menuListItem';
import { realtimeStorage } from '../helpers/firebaseDatabase';
import I18nSelect from './i18nSelect';

const drawerWidth = 240;

const styles = theme => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  appFrame: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    width: '100%',
  },
  appBar: {
    position: 'absolute',
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'appBarShift-left': {
    marginLeft: drawerWidth,
  },
  'appBarShift-right': {
    marginRight: drawerWidth,
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    position: 'relative',
    width: drawerWidth,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  content: {
    flex: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  'content-left': {
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  'contentShift-left': {
    marginLeft: 0,
  },
  flex: {
    flex: 1,
    display: 'flex',
  },
});

class PersistentDrawer extends Component {
  state = {
    open: false,
    selectedLanguage: i18next.language ? i18next.language.split('-')[0] : 'de',
    languages: [
      {
        value: 'de',
        label: i18n.t('notification_de'),
      },
      {
        value: 'en',
        label: i18n.t('notification_en'),
      },
      {
        value: 'fr',
        label: i18n.t('notification_fr'),
      },
      {
        value: 'it',
        label: i18n.t('notification_it'),
      },
      {
        value: 'es',
        label: i18n.t('notification_es'),
      },
      {
        value: 'pl',
        label: i18n.t('notification_pl'),
      },
      {
        value: 'ua',
        label: i18n.t('notification_ua'),
      },
      {
        value: 'ru',
        label: i18n.t('notification_ru'),
      },
      {
        value: 'nl',
        label: i18n.t('notification_nl'),
      },
    ],
  };

  componentWillMount() {
    const { getUserDetails } = this.props;
    getUserDetails();
    realtimeStorage.getOnce('languages')
      .then((data) => {
        const mapperLanguage = data.map(lang => ({
          ...lang,
          value: lang.i18nKey,
          label: i18n.t(`notification_${lang.i18nKey}`),
        }));
        this.setState({ languages: mapperLanguage });
      });
  }

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const {
      classes,
      theme,
      children,
      role,
      currentUser,
      logoutCurrentUser,
      userDetails,
    } = this.props;

    const { open, selectedLanguage, languages } = this.state;
    const title = window.location.pathname.split(/(\/)/)[2];

    const drawer = (
      <Drawer
        variant="persistent"
        anchor="left"
        open={open}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <div className={classes.drawerHeader}>
          <IconButton onClick={this.handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          {(role === 'windhund_admin' || role === 'super_admin' || role === 'admin' || role === 'distributor')
          && (
            <Fragment>
              <MenuListItem path="/news" i18nTitleKey="news" iconName="news" />
              <MenuListItem path="/videos" i18nTitleKey="videos" iconName="videos" />
              <MenuListItem
                path="/push_notifications"
                i18nTitleKey="push_notifications"
                iconName="push_notification"
              />
            </Fragment>
          )
          }
          {(role === 'windhund_admin' || role === 'super_admin')
          && (
            <Fragment>
              <MenuListItem path="/videoCategories" i18nTitleKey="video_categories" iconName="video_categories" />
            </Fragment>
          )
          }
          {(role === 'windhund_admin' || role === 'super_admin')
            && (
              <Fragment>
                <MenuListItem path="/assets" i18nTitleKey="app_assets" iconName="image" />
              </Fragment>
            )
          }
          {(role === 'windhund_admin' || role === 'super_admin' || role === 'admin')
          && (
            <Fragment>
              <MenuListItem path="/exercises" i18nTitleKey="exercises" iconName="exercise" />
              <MenuListItem path="/sports" i18nTitleKey="sports" iconName="sports" />
            </Fragment>
          )}
          {(role === 'windhund_admin' || role === 'super_admin')
          && (
            <Fragment>
              <MenuListItem path="/management" i18nTitleKey="management" iconName="admin" />
            </Fragment>
          )
          }
          {role === 'super_admin' && (
            <Fragment>
              <MenuListItem path="/products" i18nTitleKey="products" iconName="products" />
            </Fragment>
          )}
          {(role === 'windhund_admin' || role === 'super_admin')
          && (
            <Fragment>
              <MenuListItem path="/userAccountsManagement" i18nTitleKey="account_management" iconName="users" />
            </Fragment>
          )
          }
        </List>
      </Drawer>
    );

    return (
      <div className={classes.root}>
        <div className={classes.appFrame}>
          <AppBar
            className={classNames(classes.appBar, {
              [classes.appBarShift]: open,
              [classes['appBarShift-left']]: open,
            })}
          >
            <Toolbar disableGutters={!open}>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classNames(classes.menuButton, open && classes.hide)}
              >
                <MenuIcon />
              </IconButton>
              <Grid container direction="row" justify="space-between" alignItems="center">
                <Grid item>
                  <Typography color="inherit">
                    {i18n.t(title)}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item>
                <I18nSelect
                  i18nLabelKey="country"
                  value={selectedLanguage}
                  options={languages}
                  onChange={(val) => {
                    i18next.changeLanguage(val);
                    return this.setState({ selectedLanguage: val });
                  }}
                />
              </Grid>
              <Box mx={5}>
                <Grid item>
                  <Grid container direction="column">
                    <Grid item>
                      <Typography color="inherit" noWrap>
                        {currentUser && currentUser.email}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="caption" color="inherit" noWrap>
                        {userDetails && startCase(userDetails.role)}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
              <I18nButton
                i18nKey="logout"
                color="secondary"
                variant="text"
                onClick={() => logoutCurrentUser()}
              />
            </Toolbar>
          </AppBar>
          {drawer}
          <main
            className={classNames(classes.content, classes['content-left'], {
              [classes.contentShift]: open,
              [classes['contentShift-left']]: open,
            })}
          >
            <div className={classes.drawerHeader} />
            {children}
          </main>
        </div>
      </div>
    );
  }
}

PersistentDrawer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  currentUser: PropTypes.object,
  getUserDetails: PropTypes.func,
  logoutCurrentUser: PropTypes.func,
  role: PropTypes.string.isRequired,
  theme: PropTypes.object.isRequired,
  userDetails: PropTypes.object,
};

export default withStyles(styles, { withTheme: true })(PersistentDrawer);
