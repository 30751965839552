import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import {
  isEmpty, length, toLower,
} from 'ramda';

import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { makeStyles } from '@material-ui/core/styles';
import i18n from '../../../helpers/i18n';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  editBox: {
    padding: 30,
  },
  formBox: {
    padding: 30,
    marginTop: 40,
  },
});

const AddNewLanguageForm = ({
  addNewLanguage,
}) => {
  const classes = useRowStyles();

  const [initValues] = useState({
    name: '',
    i18nKey: '',
  });
  const [newLanguageValues] = useState(initValues);
  const [languageName, setLanguageName] = useState(initValues.name);
  const [languageI18nKey, setLanguageI18nKey] = useState(initValues.i18nKey);
  const [languageManager, setLanguageManager] = useState(false);

  const disabled = useMemo(() => newLanguageValues && (length(languageI18nKey) !== 2
    || isEmpty(languageName)
    || isEmpty(languageI18nKey)
  ), [languageI18nKey, languageName, newLanguageValues]);

  const handleSubmit = useCallback(() => {
    addNewLanguage({
      language: {
        name: toLower(languageName),
        i18nKey: toLower(languageI18nKey),
      },
    });
    setLanguageName(initValues.name);
    setLanguageI18nKey(initValues.i18nKey);
    setLanguageManager(false);
  }, [addNewLanguage, initValues, languageName, languageI18nKey, setLanguageManager]);

  return (
    <Box marginTop={5}>
      <Grid
        container
        direction="row"
        justify="center"
      >
        <Fab
          color={!languageManager ? 'primary' : 'secondary'}
          aria-label={!languageManager ? 'add' : 'remove'}
          onClick={() => setLanguageManager(!languageManager)}
        >
          {!languageManager ? <AddIcon /> : <RemoveIcon />}
        </Fab>
      </Grid>
      {languageManager && (
        <Paper elevation={3}>
          <form className={classes.root} noValidate autoComplete="off">
            <Grid
              container
              direction="row"
              justify="space-around"
              alignItems="baseline"
              className={classes.formBox}
            >
              <div>
                <TextField
                  defaultValue={initValues.name}
                  error={isEmpty(languageName)}
                  helperText={i18n.t('language_name')}
                  id="name"
                  label={i18n.t('required')}
                  onChange={e => setLanguageName(e.target.value)}
                  placeholder={i18n.t('required')}
                  required
                />
              </div>
              <div>
                <TextField
                  defaultValue={initValues.i18nKey}
                  error={isEmpty(languageI18nKey) || length(languageI18nKey) !== 2}
                  helperText={i18n.t('country_shortcut')}
                  id="i18nKey"
                  label={i18n.t('required')}
                  onChange={e => setLanguageI18nKey(e.target.value)}
                  placeholder={i18n.t('required')}
                  required
                />
              </div>
            </Grid>
            <Grid
              container
              direction="row"
              justify="center"
              alignItems="center"
              className={classes.editBox}
            >
              <Button
                disabled={disabled}
                variant="contained"
                color="primary"
                onClick={() => handleSubmit()}
              >
                {i18n.t('add_new_language')}
              </Button>
            </Grid>
          </form>
        </Paper>
      )}
    </Box>
  );
};

AddNewLanguageForm.propTypes = {
  addNewLanguage: PropTypes.func.isRequired,
};

export default AddNewLanguageForm;
