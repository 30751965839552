import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import I18nTypography from './i18nTypography';
import i18n from '../helpers/i18n';

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    marginLeft: 0,
    minWidth: 120,
    maxWidth: 400,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: theme.spacing.unit / 4,
  },
});

const I18nSelect = (props) => {
  const {
    chips,
    classes,
    i18nLabelKey,
    options,
    multiple,
    value,
    disabled,
    sortByLabel,
  } = props;

  const optionsToRender = [...options];
  if (sortByLabel) {
    optionsToRender.sort((a, b) => {
      const labelA = a.label.toLowerCase();
      const labelB = b.label.toLowerCase();

      if (labelA < labelB) {
        return -1;
      }
      if (labelA > labelB) {
        return 1;
      }
      return 0;
    });
  }

  return (
    <form className={classes.root} autoComplete="off">
      <FormControl className={classes.formControl}>
        <I18nTypography variant="caption" color="primary" align="left" i18nKey={i18nLabelKey} />
        <Select
          disabled={disabled}
          value={value || []}
          onChange={e => props.onChange(e.target.value)}
          inputProps={{
            name: i18nLabelKey,
            id: `select-${i18nLabelKey}`,
          }}
          multiple={multiple}
          renderValue={chips ? (selected) => {
            if (selected && Array.isArray(selected)) {
              return (
                <div className={classes.chips}>
                  {
                    selected.map(val => (
                      <Chip key={val} label={i18n.t(val)} className={classes.chip} />
                    ))
                  }
                </div>
              );
            }
            return null;
          } : undefined
          }
        >
          {optionsToRender && optionsToRender.map(item => (
            <MenuItem key={item.label} value={item.value}>
              {item.label}
            </MenuItem>
          ))
          }
        </Select>
      </FormControl>
    </form>
  );
};

I18nSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  i18nLabelKey: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sortByLabel: PropTypes.bool,
  chips: PropTypes.bool,
};

I18nSelect.defaultProps = {
  sortByLabel: true,
};

export default withStyles(styles)(I18nSelect);
