import React from 'react';
import PropTypes from 'prop-types';

import Typography from '@material-ui/core/Typography';
import ReactHtmlParser from '@artsy/react-html-parser';

import i18n from '../helpers/i18n';

const myTypography = (props) => {
  const {
    i18nKey,
    html,
    children,
    ...rest
  } = props;

  return (
    <Typography {...rest}>
      {(i18nKey) && i18n.t(i18nKey)}
      {(html) && (
        <div>
          {
            ReactHtmlParser(html)
          }
        </div>
      )}
      {children}
    </Typography>
  );
};

myTypography.propTypes = {
  children: PropTypes.element,
  i18nKey: PropTypes.string,
  html: PropTypes.string,
};

export default myTypography;
