export default class Logger {
  // eslint-disable-next-line no-unused-vars
  constructor(loggerInterface = console) {
    this.loggerInterface = console;
  }

  log(message) {
    this.loggerInterface.log(message);
  }

  warning(message) {
    this.loggerInterface.warn(message);
  }

  error(message) {
    this.loggerInterface.error(message);
  }
}
