import {
  includes,
  isEmpty,
  not,
  pathOr,
} from 'ramda';
import React, {
  useEffect, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player/file';

import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';

import AccountBalance from '@material-ui/icons/AccountBalance';
import WebFont from 'webfontloader';

import * as moment from 'moment';
import 'moment/locale/de';

import { ClipLoader } from 'react-spinners';

import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';

import {
  PushNotificationPreview,
} from './components';
import I18nTypography from '../../components/i18nTypography';
import I18nTextField from '../../components/i18nTextField';
import I18nButton from '../../components/i18nButton';
import Dropzone from '../../components/dropZone';

import I18nSelect from '../../components/i18nSelect';
import i18n from '../../helpers/i18n';
import { validURL } from '../../helpers/strings';

import { realtimeStorage } from '../../helpers/firebaseDatabase';
import { notificationService } from '../../services/NotificationService';

moment.locale('de');

const titleMaxCharacters = 50;
const bodyMaxCharacters = 150;

const appUrl = 'com.windhund.clients.blackroll://';

const exampleDeepLinks = [
  'news/gfWne5ocFa34xpbyfeio',
  'https://blackroll.com',
];

export const globalValue = 'global';

const NotificationForm = ({
  classes,
  match,
  role,
  regions,
  history,
}) => {
  const cloneType = isEmpty(pathOr([], ['location', 'state'], history)) ? [] : history.location.state[0];
  const userTypes = [
    {
      value: 'all',
      label: i18n.t('all_users'),
    },
    {
      value: 'incognito',
      label: i18n.t('user_incognito_only'),
    },
    {
      value: 'email',
      label: i18n.t('user_with_emails_only'),
    },
    {
      value: 'mailingSubscription',
      label: i18n.t('user_mailing_subscription_only'),
    },
  ];

  const illustrationDropzone = useRef();
  const readOnly = match.params.action === 'show';
  const notificationIdToPreview = match.params.id || '';
  const isDistributor = role === 'distributor';

  // FORM STATE
  const [title, setTitle] = useState(cloneType.title || '');
  const [subTitle, setSubTitle] = useState(cloneType.subtitle || '');
  const [body, setBody] = useState(cloneType.body || '');
  const [launchUrl, setLaunchUrl] = useState(cloneType.url || '');
  const [illustration, setIllustration] = useState(null);
  const [image, setImage] = useState(cloneType.image || null);
  const [scheduleNotification, setScheduleNotification] = useState(true);
  // eslint-disable-next-line max-len
  const [openLinkInApp, setOpenLinkInApp] = useState(cloneType.open === (null || undefined) ? true : cloneType.open);
  const [sendAt, setSendAt] = useState(
    moment()
      .add(1, 'hour')
      .startOf('hour'),
  );
  // eslint-disable-next-line max-len
  const [sendToSpecificLanguage, setSendToSpecificLanguage] = useState(cloneType.language !== 'all_languages' ? true : isDistributor);
  const [selectedLanguage, setSelectedLanguage] = useState(cloneType.language || null);
  const [selectedCountry, setSelectedCountry] = useState(cloneType.country || null);
  // eslint-disable-next-line max-len
  const [selectedUserType, setSelectedUserType] = useState(cloneType.userType || userTypes[0].value);

  // OTHER STATE
  const [loading, setLoading] = useState(false);
  const [changeImage, setChangeImage] = useState(false);
  const [platform, setPlatform] = useState('apple');
  const [linksContent, setLinksContent] = useState({});
  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);
  const [modalManager, setModalManager] = useState(false);
  const [summaryModalManager, setSummaryModalManager] = useState(false);
  const [notificationErrors, setNotificationsErrors] = useState(null);
  const [listCategories] = useState([
    {
      value: 'news',
      label: i18n.t('news'),
      disabled: false,
    },
    {
      value: 'videos',
      label: i18n.t('videos_screen'),
      disabled: false,
    },
    {
      value: 'exerciseFiltersScreen',
      label: i18n.t('exercise_category'),
      disabled: true,
    },
    {
      value: 'exerciseRoutines',
      label: i18n.t('exercise_routines'),
      disabled: true,
    },
    {
      value: 'shop',
      label: i18n.t('open_in_app_browser'),
      disabled: false,
    },
  ]);
  const getCloneLink = cloneType.url && cloneType.url.split('/');
  const [linkCategory, setLinkCategory] = useState((
    getCloneLink && getCloneLink.length > 0 && getCloneLink[0]) || null);
  const [error, setError] = useState('');

  const appName = 'BLACKROLL®';
  const time = '10:12 PM';
  const actionButtons = ['Add reminder', 'Dismiss', 'Buy now!'];
  const color = '#1c9492';

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Roboto', 'sans-serif'],
      },
    });
  }, []);

  useEffect(() => {
    realtimeStorage.getOn(
      'news',
      newsResponse => setLinksContent(prevState => ({
        ...prevState,
        news: newsResponse
          .filter(news => news.regions.map(n => includes(n, regions)))
          .map(news => ({
            value: `news/${news.id}`,
            label: news.title,
            locale: news.regions,
          })),
      })),
    );
    realtimeStorage.getOn(
      'videos',
      videosResponse => setLinksContent(prevState => ({
        ...prevState,
        videos: videosResponse
          .filter(video => includes(video.country, regions))
          .map(video => ({
            value: `videos/${video.videosCollection[video.languages[0]].videoId}`,
            label: Object.values(video.videosCollection)[0].title,
            locale: {
              country: video.country,
              languages: video.languages,
            },
          })),
      })),
    );
    realtimeStorage.getOn('exercisesCategories',
      categoriesResponse => setLinksContent(prevState => ({
        ...prevState,
        exerciseFiltersScreen: categoriesResponse
          .filter(category => category.active)
          .map(category => ({
            value: category.id,
            label: category.title[i18n.language ? i18n.language.split('-')[0] : 'en'],
          })),
      })));
    realtimeStorage.getOn('routines',
      routinesResponse => setLinksContent(prevState => ({
        ...prevState,
        exerciseRoutines: routinesResponse
          .map(routine => ({
            value: routine.id,
            label: routine.title[i18n.language ? i18n.language.split('-')[0] : 'en'],
          })),
      })));

    realtimeStorage.getOnce('countries')
      .then((newCountries) => {
        const countriesSelect = newCountries
          .filter(country => country.hasOwnContent && regions.includes(country.i18nKey))
          .map(country => ({
            key: country.name && country.name.toLowerCase(),
            label: i18n.t(`notification_${country.i18nKey}`),
            value: country.countryCode,
            locale: country.i18nKey,
          }));

        if (isDistributor) {
          setCountries(countriesSelect);
          const userCountry = countriesSelect[0].value;
          setSelectedCountry(cloneType.country || userCountry);
        } else {
          setCountries([{
            value: globalValue,
            label: 'Global',
          }, ...countriesSelect]);
          setSelectedCountry(cloneType.country || globalValue);
        }
      });

    realtimeStorage.getOnce('languages')
      .then((newLanguages) => {
        const languagesSelect = newLanguages.map(lang => ({
          key: lang.i18nKey,
          label: i18n.t(`notification_${lang.i18nKey}`),
          value: lang.i18nKey,
          locale: lang.name,
        }));
        setLanguages(languagesSelect);

        const userLanguage = newLanguages.find(lang => (
          i18n.language.includes(lang.i18nKey)
        )) || newLanguages[0];

        if (userLanguage && userLanguage.i18nKey) {
          setSelectedLanguage(cloneType.language || userLanguage.i18nKey);
        }
      });


    if (readOnly) {
      readNotification();
    }
  }, []);

  const readNotification = async () => {
    try {
      setLoading(true);
      const notification = await notificationService.getNotification(notificationIdToPreview);
      const {
        app_url,
        contents,
        countryFilter,
        languageFilter,
        userTypeFilter,
        headings,
        big_picture = null,
        sub_title = '',
        send_after = null,
        data = {},
      } = notification;

      setTitle(headings.en);
      setSubTitle(sub_title);
      setBody(contents.en);
      // eslint-disable-next-line no-nested-ternary
      setLaunchUrl(data.web_url ? data.web_url : app_url ? app_url.replace(appUrl, '') : '');
      setOpenLinkInApp(data.openInWeb ? !data.openInWeb : true);
      setIllustration(big_picture);
      setImage(big_picture);
      setSelectedCountry(countryFilter);
      setSelectedUserType(userTypeFilter);

      if (send_after) {
        setScheduleNotification(true);
        setSendAt(moment(send_after));
      } else {
        setScheduleNotification(false);
      }

      if (languageFilter !== 'all_languages') {
        setSendToSpecificLanguage(true);
        setSelectedLanguage(languageFilter);
      }
    } finally {
      setLoading(false);
    }
  };

  const generateNotification = async () => {
    let newIllustration = changeImage ? null : cloneType.image || null;

    if (illustrationDropzone.current) {
      const dropzoneResponse = await illustrationDropzone.current.uploadImage();

      if (dropzoneResponse) {
        newIllustration = dropzoneResponse.url;
        setImage(newIllustration);
        setIllustration(dropzoneResponse);
      }
    }
    const shouldSendGlobally = selectedCountry === globalValue;
    const shouldSendToWithEmailsOnly = selectedUserType === 'email';
    const shouldSendToIncognitoOnly = selectedUserType === 'incognito';
    const shouldSendToMailingSubscriptionOnly = selectedUserType === 'mailingSubscription';

    const sendIllustration = newIllustration ? {
      ios_attachments: { id1: newIllustration },
      big_picture: newIllustration,
    } : {};
    const sendScheduled = scheduleNotification ? { send_after: sendAt.format() } : {};

    const isWebUrl = launchUrl.includes('https') || launchUrl.includes('http');
    const app_url = isWebUrl ? `${appUrl}` : `${appUrl}${launchUrl}`;
    const sendLaunchUrl = openLinkInApp && launchUrl ? { app_url } : {};
    const sendSubTitle = subTitle ? { subtitle: { en: subTitle } } : {};
    // When implementing more data in additional data for app, refactor the bottom line
    const sendWebUrl = isWebUrl ? {
      data: {
        web_url: launchUrl,
        openInWeb: !openLinkInApp,
      },
    } : {};

    // TODO: Replace it with correct implementation
    // const sendButtons = false ? { buttons: [
    //   { id: 'id2', text: 'Go to Exercise', icon: 'ic_menu_share' },
    //   { id: 'id1', text: 'Buy Product', icon: 'ic_menu_send' }
    // ] } : {};

    // Filters
    const sendLanguage = sendToSpecificLanguage ? [{
      field: 'tag',
      key: 'language',
      relation: '=',
      value: selectedLanguage,
    }] : [];
    const sendCountry = !shouldSendGlobally ? [{
      field: 'tag',
      key: 'country',
      relation: '=',
      value: selectedCountry,
    }] : [];
    const sendWithEmailOnly = shouldSendToWithEmailsOnly ? [{
      field: 'tag',
      key: 'email',
      relation: '=',
      value: 'true',
    }] : [];
    const sendIncognitoOnly = shouldSendToIncognitoOnly ? [{
      field: 'tag',
      key: 'incognito',
      relation: '=',
      value: 'true',
    }] : [];
    const sendMailingSubscriptionOnly = shouldSendToMailingSubscriptionOnly ? [{
      field: 'tag',
      key: 'mailingSubscription',
      relation: '=',
      value: 'true',
    }] : [];

    const filtersArray = [
      ...sendLanguage,
      ...sendCountry,
      ...sendWithEmailOnly,
      ...sendIncognitoOnly,
      ...sendMailingSubscriptionOnly,
    ];
    const sendFilters = filtersArray.length > 0 ? {
      filters: filtersArray,
    } : {
      included_segments: ['Subscribed Users'],
    };

    return ({
      ...sendIllustration,
      ...sendScheduled,
      ...sendFilters,
      ...sendLaunchUrl,
      ...sendSubTitle,
      ...sendWebUrl,
      ios_badgeType: 'Increase',
      ios_badgeCount: 1,
      headings: { en: title },
      contents: { en: body },
    });
  };

  const validateNotification = () => {
    const errors = [];
    if (!readOnly) {
      if (!title || title === '') {
        errors.push('title_empty');
      }
      if (!body || body === '') {
        errors.push('body_empty');
      }
      if (scheduleNotification && sendAt.isBefore()) {
        errors.push('send_date_not_in_future');
      }
      const isValidWebLink = appUrl && (appUrl.includes('https') || appUrl.includes('http'));
      if (!openLinkInApp && isValidWebLink) {
        errors.push('wrong_link');
      }
      setNotificationsErrors(errors);
    }
    return errors.length > 0;
  };

  const sendNotification = async () => {
    try {
      setLoading(true);
      setSummaryModalManager(!summaryModalManager);
      setNotificationsErrors(null);
      setError('');
      const isError = validateNotification();
      if (!isError) {
        const generatedNotification = await generateNotification();
        await notificationService.createNotification(generatedNotification);
        history.goBack();
      }
    } catch (err) {
      if (err && err.response && err.response.data && err.response.data.message) {
        setError(err.response.data.message);
      } else {
        setError(err.message);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleToggleScheduleNotification = () => {
    setScheduleNotification(state => !state);
  };

  const renderHeader = () => (
    <Grid item xs={12}>
      <Box alignItems="center" display="flex" flexDirection="row">
        <IconButton onClick={history.goBack} style={{ paddingRight: 16 }}>
          <ArrowBackIosRounded />
        </IconButton>
        <Typography variant="h4" component="h4">
          {i18n.t(readOnly ? 'show_push_notification' : 'new_push_notification')}
        </Typography>
      </Box>
    </Grid>
  );

  const renderNotificationErrors = () => {
    if (notificationErrors) {
      return notificationErrors.map(errorToRender => (
        <Grid key={errorToRender}>
          <I18nTypography i18nKey={errorToRender} color="error" />
        </Grid>
      ));
    }
    return null;
  };

  const renderSampleLinks = links => links.map(exampleLink => (
    <FormHelperText key={exampleLink}>
      {'- '}
      {exampleLink}
    </FormHelperText>
  ));

  const userType = React.useMemo(() => (selectedUserType ? userTypes
    .find(type => type.value === selectedUserType) : ''),
  [selectedUserType]);

  const renderSummary = (noTitle = false, noButton = false, hideContent = false) => (
    <React.Fragment>
      {!noTitle ? (
        <Typography variant="h4" component="h4">
          {i18n.t('notification_summary')}
        </Typography>
      ) : null}
      <Box mt={4}>
        <Grid container rowSpacing={1}>
          {!hideContent ? (
            <React.Fragment>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={8}
              >
                <Grid item xs={2}>
                  <Typography style={{ fontWeight: 'bold' }}>{i18n.t('title')}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{title}</Typography>
                </Grid>
              </Grid>
              <div style={{
                height: 1,
                width: '90%',
                marginTop: 2,
                opacity: 0.5,
                marginBottom: 3,
                backgroundColor: 'grey',
              }}
              />
            </React.Fragment>
          ) : null}
          {!hideContent && subTitle ? (
            <React.Fragment>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={8}
              >
                <Grid item xs={2}>
                  <Typography style={{ fontWeight: 'bold' }}>{i18n.t('sub_title')}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{subTitle}</Typography>
                </Grid>
              </Grid>
              <div style={{
                height: 1,
                width: '90%',
                marginTop: 2,
                opacity: 0.5,
                marginBottom: 3,
                backgroundColor: 'grey',
              }}
              />
            </React.Fragment>
          ) : null}
          {!hideContent ? (
            <React.Fragment>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={8}
              >
                <Grid item xs={2}>
                  <Typography style={{ fontWeight: 'bold' }}>{i18n.t('message_text')}</Typography>
                </Grid>
                <Grid item xs={10}>
                  <Typography>{body}</Typography>
                </Grid>
              </Grid>
              <div style={{
                height: 1,
                width: '90%',
                marginTop: 2,
                opacity: 0.5,
                marginBottom: 3,
                backgroundColor: 'grey',
              }}
              />
            </React.Fragment>
          ) : null}
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 'bold' }}>{i18n.t('image')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {image ? i18n.t('yes') : i18n.t('no')}
              </Typography>
            </Grid>
          </Grid>
          <div style={{
            height: 1,
            width: '90%',
            marginTop: 2,
            opacity: 0.5,
            marginBottom: 3,
            backgroundColor: 'grey',
          }}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 'bold' }}>{i18n.t('user_type')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {userType.label || ''}
              </Typography>
            </Grid>
          </Grid>
          <div style={{
            height: 1,
            width: '90%',
            marginTop: 2,
            opacity: 0.5,
            marginBottom: 3,
            backgroundColor: 'grey',
          }}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 'bold' }}>{i18n.t('language')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {sendToSpecificLanguage ? `${i18n.t('yes')} - ${languageLabel ? i18n.t(`notification_${languageLabel.value}`) : null}` : i18n.t('all_languages')}
              </Typography>
            </Grid>
          </Grid>
          <div style={{
            height: 1,
            width: '90%',
            marginTop: 2,
            opacity: 0.5,
            marginBottom: 3,
            backgroundColor: 'grey',
          }}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 'bold' }}>{i18n.t('country')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {selectedCountry === 'global' ? i18n.t('all_countries') : selectedCountry}
              </Typography>
            </Grid>
          </Grid>
          <div style={{
            height: 1,
            width: '90%',
            marginTop: 2,
            opacity: 0.5,
            marginBottom: 3,
            backgroundColor: 'grey',
          }}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 'bold' }}>{i18n.t('open_link_in_app')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {openLinkInApp ? `${i18n.t('yes')} - ${isEmpty(launchUrl) ? i18n.t('link_is_empty') : launchUrl}` : i18n.t('no')}
              </Typography>
            </Grid>
          </Grid>
          <div style={{
            height: 1,
            width: '90%',
            marginTop: 2,
            opacity: 0.5,
            marginBottom: 3,
            backgroundColor: 'grey',
          }}
          />
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={8}
          >
            <Grid item xs={2}>
              <Typography style={{ fontWeight: 'bold' }}>{i18n.t('schedule_notification')}</Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography>
                {scheduleNotification ? `${i18n.t('yes')} - ${sendAt.format('YYYY-MM-DD HH:mm')}` : `${i18n.t('no')} -  ${i18n.t('send_notification_now')}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {!noButton ? (
        <Grid item container>
          <Box mt={4}>
            {!readOnly && (
              <Grid item className={classes.saveButton}>
                <I18nButton
                  i18nKey="review_and_send"
                  fullWidth
                  onClick={() => setSummaryModalManager(!summaryModalManager)}
                  color="primary"
                  disabled={loading || isEmpty(title) || isEmpty(body)}
                  loading={loading}
                />
              </Grid>
            )}
          </Box>
        </Grid>
      ) : null}
    </React.Fragment>
  );

  const renderPreview = () => (
    <Box>
      <Typography variant="h4" component="h4">
        {i18n.t('message_preview')}
      </Typography>
      <Box mt={4}>
        <Grid container spacing={3} mb={5}>
          <Grid item xs={6} spacing={2}>
            <I18nButton
              fullWidth
              disabled={platform === 'android'}
              onClick={() => setPlatform('android')}
              i18nKey="Android"
            />
          </Grid>
          <Grid item xs={6}>
            <I18nButton
              fullWidth
              disabled={platform === 'apple'}
              onClick={() => setPlatform('apple')}
              i18nKey="Apple"
            />
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        {platform === 'apple' ? (
          <div className="marvel-device iphone-x">
            <div className="notch">
              <div className="camera" />
              <div className="speaker" />
            </div>
            <div className="top-bar" />
            <div className="sleep" />
            <div className="bottom-bar" />
            <div className="volume" />
            <div className="overflow">
              <div className="shadow shadow--tr" />
              <div className="shadow shadow--tl" />
              <div className="shadow shadow--br" />
              <div className="shadow shadow--bl" />
            </div>
            <div className="inner-shadow" />
            <div className="screen">
              {platform !== null && not(isEmpty(body || title)) && (
                <Box mt={5} paddingLeft={2} paddingRight={2}>
                  <Box mt={2}>
                    <Grid item xs={12}>
                      <PushNotificationPreview
                        AppIcon={AccountBalance}
                        actionButtons={actionButtons}
                        appName={appName}
                        color={color}
                        image={image}
                        platform={platform}
                        message={body}
                        time={time}
                        title={title}
                      />
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid item xs={12}>
                      <PushNotificationPreview
                        AppIcon={AccountBalance}
                        appName={appName}
                        color={color}
                        message={body}
                        platform={platform}
                        title={title}
                      />
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid item xs={12}>
                      <PushNotificationPreview
                        actionButtons={actionButtons}
                        appName={appName}
                        color={color}
                        message={body}
                        platform={platform}
                      />
                    </Grid>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        ) : null}
        {platform === 'android' ? (
          <div className="marvel-device note8">
            <div className="inner" />
            <div className="overflow">
              <div className="shadow" />
            </div>
            <div className="speaker" />
            <div className="sensors" />
            <div className="more-sensors" />
            <div className="sleep" />
            <div className="volume" />
            <div className="camera" />
            <div className="screen">
              {platform !== null && not(isEmpty(body || title)) && (
                <Box mt={5} paddingLeft={2} paddingRight={2}>
                  <Box mt={2}>
                    <Grid item xs={12}>
                      <PushNotificationPreview
                        AppIcon={AccountBalance}
                        actionButtons={actionButtons}
                        appName={appName}
                        color={color}
                        image={image}
                        platform={platform}
                        message={body}
                        time={time}
                        title={title}
                      />
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid item xs={12}>
                      <PushNotificationPreview
                        AppIcon={AccountBalance}
                        appName={appName}
                        color={color}
                        message={body}
                        platform={platform}
                        title={title}
                      />
                    </Grid>
                  </Box>
                  <Box mt={2}>
                    <Grid item xs={12}>
                      <PushNotificationPreview
                        actionButtons={actionButtons}
                        appName={appName}
                        color={color}
                        message={body}
                        platform={platform}
                      />
                    </Grid>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        ) : null}
      </Box>
    </Box>
  );

  const renderContentForm = () => (
    <Grid
      container
      direction="column"
      item
      xs={7}
    >
      <Box>
        <Grid item>
          <Typography variant="h4" component="h4">
            {i18n.t('content')}
          </Typography>
        </Grid>
        <Grid item>
          <FormControl>
            <I18nTextField
              disabled={readOnly}
              i18nLabelKey="title"
              isRequired
              id="title-input"
              value={title}
              onChange={value => setTitle(value.substring(0, titleMaxCharacters))}
              className={classes.textField}
              noMarginBottom
            />
            <FormHelperText>{`${title.length} / ${titleMaxCharacters}`}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <I18nTextField
              disabled={readOnly}
              i18nLabelKey="sub_title"
              id="sub_title-input"
              value={subTitle}
              onChange={value => setSubTitle(value.substring(0, titleMaxCharacters))}
              className={classes.textField}
              noMarginBottom
            />
            <FormHelperText>{`${subTitle.length} / ${titleMaxCharacters}`}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl>
            <I18nTextField
              disabled={readOnly}
              isRequired
              i18nLabelKey="message_text"
              id="message-input"
              value={body}
              onChange={value => setBody(value.substring(0, bodyMaxCharacters))}
              multiline
              rowsMax="4"
              className={classes.textField}
              noMarginBottom
            />
            <FormHelperText>{`${body.length} / ${bodyMaxCharacters}`}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={7}>
          {!readOnly && image && !changeImage && cloneType.status !== undefined && (
            <Grid>
              <Box mt={10}>
                <Typography>{i18n.t('image')}</Typography>
                <img
                  alt={title}
                  src={image}
                  style={{
                    width: '100%',
                    borderRadius: 10,
                  }}
                />
              </Box>
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.deleteButton}
                  onClick={() => {
                    setChangeImage(true);
                    setImage(null);
                    setIllustration(null);
                  }}
                >
                  {i18n.t('replace_notification_image')}
                </Button>
              </Box>
            </Grid>
          )}
          {readOnly && illustration && (
            <Box mt="10px">
              <Typography>{i18n.t('image')}</Typography>
              <img
                alt={title}
                src={illustration}
                style={{
                  width: '100%',
                  borderRadius: 10,
                }}
              />
            </Box>
          )}
          {!readOnly && cloneType.status === undefined && (
            <div>
              <Dropzone
                disabled={readOnly}
                i18nLabelKey="illustration"
                type="illustrations"
                value={illustration}
                passUrl={file => file && setImage(file.preview)}
                ref={(ref) => { illustrationDropzone.current = ref; }}
                maxSize={2500000}
              />
              <Typography>{i18n.t('file_max_size')}</Typography>
            </div>
          )}
          {changeImage && (
            <div>
              <Dropzone
                disabled={readOnly}
                i18nLabelKey="illustration"
                type="illustrations"
                value={illustration}
                passUrl={file => file && setImage(file.preview)}
                ref={(ref) => { illustrationDropzone.current = ref; }}
                maxSize={2500000}
              />
              <Typography>{i18n.t('file_max_size')}</Typography>
            </div>
          )}
        </Grid>
        <Grid item>
          <div className={classes.formControl}>
            <Typography>{error}</Typography>
          </div>
        </Grid>
      </Box>
    </Grid>
  );

  const renderOptions = React.useMemo(() => {
    if (linkCategory === 'news') {
      const findLocale = countries && countries.find(country => country.value === selectedCountry);
      return linksContent[linkCategory] && findLocale && linksContent[linkCategory]
        .filter(news => news.locale
          .some(l => findLocale.locale === l));
    }
    if (linkCategory === 'videos') {
      const findCountry = countries && countries
        .find(country => country.value === selectedCountry);
      const findLanguage = languages && languages
        .find(language => language.value === selectedLanguage);
      return linksContent[linkCategory] && linksContent[linkCategory].filter((video) => {
        const containCountry = findCountry && video.locale.country === findCountry.locale;
        const containLanguage = findLanguage && video.locale.languages
          .some(l => findLanguage.locale === l);
        return containCountry && containLanguage;
      });
    }
    return linksContent[linkCategory];
  }, [linksContent, linkCategory, countries, languages, selectedCountry, selectedLanguage]);

  const renderLinksForm = React.useCallback(() => (
    <Grid
      container
      item
      direction="column"
      xs={12}
    >
      <Box>
        <Typography variant="h4" component="h4">{i18n.t('video_title_links')}</Typography>
        <Grid container item direction="row" alignItems="center">
          <Typography>{i18n.t('open_link_in_app')}</Typography>
          <Switch
            disabled={readOnly}
            onChange={() => setOpenLinkInApp(state => !state)}
            checked={openLinkInApp}
            color="primary"
          />
        </Grid>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            className={classes.deleteButton}
            onClick={() => setModalManager(!modalManager)}
          >
            {i18n.t('how_deep_links_work')}
          </Button>
        </Box>
        <Grid item>
          <FormControl>
            {!openLinkInApp && (
              <I18nTextField
                disabled={readOnly}
                i18nLabelKey="launch_url"
                id="launchUrl-input"
                value={launchUrl}
                onChange={value => setLaunchUrl(value)}
                className={classes.textField}
                noMarginBottom
              />
            )}
            {openLinkInApp ? (
              <div>
                <FormHelperText>
                  {i18n.t('launch_url_info')}
                </FormHelperText>
                {selectedCountry === 'global' && (
                  <FormHelperText error>
                    {i18n.t('notification_more_global_disclaimer')}
                  </FormHelperText>
                )}
                {selectedCountry !== 'global' && (
                  <I18nSelect
                    i18nLabelKey="link_category"
                    value={linkCategory}
                    options={selectedCountry === 'global' ? listCategories.filter(({ value }) => value === 'shop') : listCategories.filter(({ disabled }) => !disabled)}
                    onChange={(value) => {
                      setLaunchUrl(null);
                      setLinkCategory(value);
                    }}
                    disabled={readOnly || regions.length === 1}
                  />
                )}
                {/* eslint-disable-next-line no-nested-ternary */}
                {selectedCountry !== 'global' ? (linkCategory && renderOptions && linkCategory !== 'shop' ? (
                  <I18nSelect
                    i18nLabelKey="link_category_path"
                    value={launchUrl}
                    options={renderOptions}
                    onChange={value => setLaunchUrl(value)}
                    disabled={readOnly || regions.length === 1 || renderOptions.length === 0}
                  />
                ) : linkCategory && (
                  <I18nTextField
                    disabled={readOnly}
                    i18nLabelKey="action_uri"
                    id="launchUrl-input"
                    value={launchUrl}
                    onChange={value => setLaunchUrl(value.trim())}
                    className={classes.textField}
                    noMarginBottom
                  />
                )) : (
                  <I18nTextField
                    disabled={readOnly}
                    i18nLabelKey="launch_url"
                    id="launchUrl-input"
                    value={launchUrl}
                    onChange={value => setLaunchUrl(value.trim())}
                    className={classes.textField}
                    noMarginBottom
                  />
                )}
                {launchUrl && !validURL(launchUrl) && !['videos', 'news'].includes(linkCategory) ? <I18nTypography i18nKey="valid_url" color="error" /> : null}
                {selectedCountry !== 'global' && renderSampleLinks((launchUrl ? [launchUrl] : null) || exampleDeepLinks)}
              </div>
            ) : (
              <div>
                <FormHelperText>
                  {i18n.t('web_url_info')}
                </FormHelperText>
                {renderSampleLinks(exampleDeepLinks.filter(link => link.includes('http')))}
              </div>
            )}
          </FormControl>
        </Grid>
      </Box>
    </Grid>
  ), [
    launchUrl,
    linkCategory,
    linksContent,
    listCategories,
    openLinkInApp,
    readOnly,
    selectedCountry,
    selectedLanguage,
    setLaunchUrl,
  ]);

  const renderFiltersForm = () => (
    <Grid
      container
      item
      direction="column"
      xs={12}
    >
      <Box>
        <Typography variant="h4" component="h4">{i18n.t('audience')}</Typography>
        <Grid item>
          {regions && regions.length > 0 && (
            <Box my={2}>
              <I18nSelect
                i18nLabelKey="country"
                value={selectedCountry}
                options={countries}
                onChange={setSelectedCountry}
                disabled={readOnly || regions.length === 1}
              />
            </Box>
          )}
          <Box my={2}>
            <Grid container item direction="row" alignItems="center">
              <Typography>{i18n.t('language_notification')}</Typography>
              <Switch
                disabled={readOnly}
                onChange={() => setSendToSpecificLanguage(state => !state)}
                checked={sendToSpecificLanguage}
                color="primary"
              />
            </Grid>
            {sendToSpecificLanguage && (
              <I18nSelect
                i18nLabelKey="language"
                value={selectedLanguage}
                options={languages}
                onChange={setSelectedLanguage}
                disabled={readOnly}
              />
            )}
          </Box>
          <Box my={2}>
            <I18nSelect
              i18nLabelKey="user_type"
              value={selectedUserType}
              options={[
                {
                  value: 'all',
                  label: i18n.t('all_users'),
                },
                {
                  value: 'incognito',
                  label: i18n.t('user_incognito_only'),
                },
                {
                  value: 'email',
                  label: i18n.t('user_with_emails_only'),
                },
                {
                  value: 'mailingSubscription',
                  label: i18n.t('user_mailing_subscription_only'),
                },
              ]}
              onChange={setSelectedUserType}
              disabled={readOnly}
            />
          </Box>
          <Box my={2}>
            <Grid container item direction="row" alignItems="center">
              <Typography>{i18n.t('schedule_notification')}</Typography>
              <Switch
                disabled={readOnly}
                onChange={handleToggleScheduleNotification}
                checked={scheduleNotification}
                color="primary"
              />
            </Grid>
            {scheduleNotification && (
              <div className={classes.formControl}>
                <Grid xs={12} container item direction="row" alignItems="center">
                  <Box mr={2}>
                    <Typography>{`${i18n.t('send_at')}: `}</Typography>
                  </Box>
                  <Box>
                    <TextField
                      id="datetime-local"
                      type="datetime-local"
                      disabled={readOnly}
                      defaultValue={sendAt.format('YYYY-MM-DDTHH:mm')}
                      value={sendAt.format('YYYY-MM-DDTHH:mm')}
                      // defaultValue="2017-05-24T10:30"
                      className={classes.textField}
                      onChange={(date) => {
                        const newDate = moment(date.target.value);
                        // round to next 5 min.
                        const minutes = Math.round(newDate.minutes() / 5) * 5;

                        setSendAt(newDate.minutes(minutes));
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...readOnly && ({
                        value: sendAt.format('YYYY-MM-DDTHH:mm'),
                      })}
                    />
                  </Box>
                </Grid>
                {sendAt.isBefore() && !readOnly
                && <I18nTypography i18nKey="send_date_not_in_future" color="error" />}
              </div>
            )}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );

  const renderLoader = () => loading && (
    <div className={classes.loadingContainer}>
      <ClipLoader
        sizeUnit="px"
        size={35}
      />
    </div>
  );

  const languageLabel = React.useMemo(() => (selectedLanguage ? languages
    .find(lang => lang.value === selectedLanguage) : ''),
  [languages, selectedLanguage]);

  return (
    <React.Fragment>
      <Grid container item spacing={2} direction="row">
        {renderHeader()}
        <Grid container>
          <Grid item xs={12}>
            <Paper className={classes.paper} elevation={4}>
              <Grid container>
                <Grid item xs={7}>
                  {renderContentForm()}
                </Grid>
                <Grid item xs={5}>
                  {renderPreview()}
                </Grid>
              </Grid>
            </Paper>
            <Paper className={classes.paper} elevation={4}>
              {renderFiltersForm()}
            </Paper>
            <Paper className={classes.paper} elevation={4}>
              {renderLinksForm()}
            </Paper>
            <Paper className={classes.paper} elevation={4}>
              {renderSummary()}
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      {renderNotificationErrors()}
      {renderLoader()}
      <Dialog
        open={modalManager}
        onClose={() => setModalManager(!modalManager)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{i18n.t('video_title_links')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {i18n.t('video_notification_more')}
          </DialogContentText>
          <Box>
            <Typography>{i18n.t('notification_open_in_app')}</Typography>
          </Box>
          <ReactPlayer
            url="https://firebasestorage.googleapis.com/v0/b/blackroll-dev.appspot.com/o/notificationsVideo%2FOPEN_IN_APP.MP4?alt=media&token=bc15af1a-fec6-4b08-b60a-4ce976123d54"
            controls
            muted
            width="100%"
          />
          <Box mt={4}>
            <Typography>{i18n.t('notification_open_in_web')}</Typography>
          </Box>
          <ReactPlayer
            url="https://firebasestorage.googleapis.com/v0/b/blackroll-dev.appspot.com/o/notificationsVideo%2FOPEN_IN_WEB.MP4?alt=media&token=9b44b008-a801-4e59-96f3-92505982f4c8"
            controls
            muted
            width="100%"
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setModalManager(!modalManager)}
            color="primary"
            variant="contained"
          >
            {i18n.t('close_action')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={summaryModalManager}
        onClose={() => setSummaryModalManager(!summaryModalManager)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{i18n.t('review_your_message')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Box>
              <Typography variant="h5" component="h5">
                {i18n.t('message_preview')}
              </Typography>
            </Box>
            <Box mt={1}>
              <Grid container spacing={3} mb={5}>
                <Grid item xs={6} spacing={2}>
                  <I18nButton
                    fullWidth
                    disabled={platform === 'android'}
                    onClick={() => setPlatform('android')}
                    i18nKey="Android"
                  />
                </Grid>
                <Grid item xs={6}>
                  <I18nButton
                    fullWidth
                    disabled={platform === 'apple'}
                    onClick={() => setPlatform('apple')}
                    i18nKey="Apple"
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mt={2}>
              <div style={{
                boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
                borderRadius: 12,
              }}
              >
                <PushNotificationPreview
                  AppIcon={AccountBalance}
                  actionButtons={actionButtons}
                  appName={appName}
                  color={color}
                  image={image}
                  platform={platform}
                  message={body}
                  time={time}
                  title={title}
                />
              </div>
            </Box>
          </DialogContentText>
          {renderSummary(true, true, true)}
        </DialogContent>
        <DialogActions>
          {!readOnly && (
            <I18nButton
              i18nKey="send_notification_now"
              fullWidth
              onClick={sendNotification}
              color="primary"
              disabled={loading || isEmpty(title) || isEmpty(body)}
              loading={loading}
            />
          )
          }
          <Button
            onClick={() => setSummaryModalManager(!summaryModalManager)}
            variant="contained"
            color="secondary"
          >
            {i18n.t('close_action')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

NotificationForm.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  regions: PropTypes.array,
  role: PropTypes.string.isRequired,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 1000,
    minWidth: 400,
  }),
  loadingContainer: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ffffff85',
    top: 0,
    right: 0,
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    width: 400,
  },
  summary: {
    marginTop: 10,
    '& b': {
      fontWeight: 600,
      fontFamily: 'sans-serif',
    },
  },
  audienceContainer: {
    marginTop: 28,
  },
  heading: {
    fontFamily: 'Rajdhani',
    fontSize: 20,
  },
  contentHeading: {
    marginTop: 10,
  },
  deleteProductsButton: {
    minWidth: 40,
    padding: 0,
  },
  saveButton: {
    marginRight: 10,
  },
  regionSection: {
    marginBottom: 15,
  },
});

export default withStyles(styles)(NotificationForm);
