/* eslint-disable react/prop-types */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

import I18nTypography from './i18nTypography';

function noOptionsMessage(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.noOptionsMessage}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

function control(props) {
  const {
    innerProps,
    children,
    innerRef,
    selectProps,
  } = props;
  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: selectProps.classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
    />
  );
}

function option(props) {
  const {
    innerRef,
    isFocused,
    isSelected,
    innerProps,
    children,
  } = props;
  return (
    <MenuItem
      buttonRef={innerRef}
      selected={isFocused}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
      {...innerProps}
    >
      {children}
    </MenuItem>
  );
}

function placeholderComponent(props) {
  const { selectProps, innerProps, children } = props;
  return (
    <Typography
      color="textSecondary"
      className={selectProps.classes.placeholder}
      {...innerProps}
    >
      {children}
    </Typography>
  );
}

function valueContainer(props) {
  const { selectProps, children } = props;
  return (
    <div className={selectProps.classes.valueContainer}>
      {children}
    </div>
  );
}

function multiValue(props) {
  const {
    children,
    selectProps,
    removeProps,
    isFocused,
  } = props;
  return (
    <Chip
      tabIndex={-1}
      label={children}
      className={classNames(selectProps.classes.chip, {
        [selectProps.classes.chipFocused]: isFocused,
      })}
      onDelete={(event) => {
        removeProps.onClick();
        removeProps.onMouseDown(event);
      }}
    />
  );
}

const components = {
  option,
  control,
  noOptionsMessage,
  placeholderComponent,
  multiValue,
  valueContainer,
};

class IntegrationReactSelect extends Component {
  handleChange = name => (value) => {
    this.setState({
      [name]: value,
    });
  };

  render() {
    const {
      classes,
      placeholder,
      i18nLabelKey,
      options,
      value,
      onChange,
    } = this.props;

    return (
      <div className={classes.root}>
        <I18nTypography i18nKey={i18nLabelKey} />
        <NoSsr>
          <Select
            classes={classes}
            options={options}
            components={components}
            value={value}
            onChange={newValue => onChange(newValue)}
            placeholder={placeholder}
            isMulti
          />
        </NoSsr>
      </div>
    );
  }
}

IntegrationReactSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  placeholder: PropTypes.string,
  i18nLabelKey: PropTypes.string,
  options: PropTypes.array,
};

const styles = theme => ({
  root: {
    flexGrow: 1,
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flex: 1,
    alignItems: 'center',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    fontSize: 16,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
});


export default withStyles(styles)(IntegrationReactSelect);
