import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import I18nTypography from '../components/i18nTypography';
import firebase from '../helpers/firebase';
import I18nButton from '../components/i18nButton';

const NoAccess = (props) => {
  const { classes } = props;
  return (
    <div className={classes.container}>
      <I18nTypography variant="display3"><span role="img" aria-label="lock">🔒</span></I18nTypography>
      <I18nTypography variant="subheading" i18nKey="you_have_no_access" />
      <I18nButton i18nKey="logout" color="primary" variant="text" onClick={() => firebase.getInstance().auth().signOut()} />
    </div>
  );
};

NoAccess.propTypes = {
  classes: propTypes.shape(),
};

const styles = theme => ({
  container: {
    textAlign: 'center',
    marginTop: theme.spacing.unit * 10,
  },
});

export default withStyles(styles)(NoAccess);
