import * as firebase from 'firebase';

export const timestamp = firebase.firestore.FieldValue.serverTimestamp;

class Firebase {
  constructor() {
    this.isDevEnv = false;
  }

  init() {
    return fetch('/__/firebase/init.json')
      .then(response => response.json())
      .then((config) => {
        if (config.projectId.split('-')[1] === 'dev') {
          this.isDevEnv = true;
        }
        if (!this.instance) {
          this.instance = firebase.initializeApp(config);
          // eslint-disable-next-line max-len
          /* if you want to use local functions for debugging please uncomment line below [public void useFunctionsEmulator (String origin)] */
          // this.instance.functions().useFunctionsEmulator('http://localhost:5001');
        }
      });
  }

  getInstance() {
    return this.instance;
  }
}

export default new Firebase();
