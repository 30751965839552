import firebase, { timestamp } from '../helpers/firebase';

let root;

firebase.init().then(() => {
  const db = firebase.getInstance().firestore();
  root = db.collection('v1').doc('data');
});

class FirebaseService {
  constructor() {
    this.ref = root;
  }

  setRef(ref) {
    this.ref = ref;
    return this;
  }

  collection(collectionName) {
    this.ref = this.ref.collection(collectionName);
    return this;
  }

  doc(docName) {
    this.ref = this.ref.doc(docName);
    return this;
  }

  where(first, comparitor, second) {
    this.ref = this.ref.where(first, comparitor, second);
    return this;
  }

  orderBy(field) {
    this.ref = this.ref.orderBy(field);
    return this;
  }

  onSnapshot(callback) {
    return this.ref.onSnapshot(callback);
  }

  set(value, options) {
    return this.ref.set(value, options);
  }

  async add(doc) {
    return this.ref.add({
      ...doc,
      createdAt: timestamp(),
    });
  }

  update(obj) {
    this.ref.update({
      ...obj,
      updatedAt: timestamp(),
    });
  }

  delete() {
    this.ref.delete();
  }

  async data() {
    let returnValue;
    await this.ref.get()
      .then((snapshot) => {
        if (snapshot.data) {
          returnValue = snapshot.data();
        } else {
          returnValue = snapshot.docs.map((doc) => {
            const data = doc.data();
            data.id = doc.id;
            data.ref = doc.ref;
            return data;
          });
        }
      });
    return returnValue;
  }
}

export default () => new FirebaseService();
