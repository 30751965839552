import React from 'react';
import propTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import { ClipLoader } from 'react-spinners';

const Spinner = ({ classes }) => (
  <div className={classes.container}>
    <ClipLoader
      sizeUnit="px"
      size={35}
    />
  </div>
);

Spinner.propTypes = {
  classes: propTypes.shape(),
};

const styles = () => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    alignItems: 'center',
  },
});

export default withStyles(styles)(Spinner);
