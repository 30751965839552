import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import YouTube from 'react-youtube';
import Switch from 'react-switch';

import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import I18nTypography from '../components/i18nTypography';
import I18nTextField from '../components/i18nTextField';
import I18nButton from '../components/i18nButton';

import Dropzone from '../components/dropZone';

import { realtimeStorage } from '../helpers/firebaseDatabase';
import RegionSelector from '../components/regionSelector';
import { isValidURL } from '../helpers/validation.service';
import i18n from '../helpers/i18n';

class AddOrEditNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      illustration: null,
      videoUrl: null,
      videoId: null,
      usesVideo: false,
      post: '',
      published: false,
      selectedRegions: props.regions && [props.regions[0]],
      errorMessages: false,
      callToActionIsEnabled: false,
      callToActionText: '',
      callToActionURI: '',
      callToActionTab: 'training',
      callToActionIsExternal: false,
      readOnly: (props.match.params.action === 'show'),
    };
  }

  componentWillMount() {
    const { match } = this.props;
    const promises = [];

    Promise.all(promises).then(() => {
      if (match.params.action === 'edit' || match.params.action === 'show') {
        realtimeStorage.getDoc('news', match.params.id).then((data) => {
          let callToActionIsEnabled = false;
          let callToActionText = '';
          let callToActionURI = '';
          let callToActionTab = '';
          let callToActionIsExternal = false;
          if (data.callToAction) {
            callToActionText = data.callToAction.text;
            callToActionURI = data.callToAction.URI || '';
            callToActionTab = data.callToAction.tab || 'training';
            if (data.callToAction.isExternal !== undefined) {
              callToActionIsExternal = data.callToAction.isExternal;
            }
            if (data.callToAction.isEnabled !== undefined) {
              callToActionIsEnabled = data.callToAction.isEnabled;
            }
          }
          this.setState({
            id: data.id,
            title: data.title,
            illustration: data.media.illustration,
            videoUrl: data.media.videoUrl || null,
            videoId: data.media.videoId || null,
            usesVideo: data.media.usesVideo,
            post: data.post,
            published: data.published || false,
            selectedRegions: data.regions,
            callToActionIsEnabled,
            callToActionText,
            callToActionURI,
            callToActionTab,
            callToActionIsExternal,
          });
        });
      }
    });
  }

  getNews() {
    const {
      title,
      illustration,
      videoUrl,
      videoId,
      usesVideo,
      post,
      published,
      selectedRegions,
      callToActionIsEnabled,
      callToActionText,
      callToActionURI,
      callToActionTab,
      callToActionIsExternal,
    } = this.state;

    let newIllustration = illustration;
    if (usesVideo && videoId) {
      newIllustration = `https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`;
      newIllustration = {
        url: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
        thumbLarge: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
        thumbMedium: `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`,
        thumbSmall: `https://img.youtube.com/vi/${videoId}/default.jpg`,
      };
    }

    const errorMessages = [];

    if (title === null || title.length === 0 || !title.trim()) {
      errorMessages.push('title_missing');
    }

    if (post === '' || post === '<p>&nbsp;</p>') {
      errorMessages.push('post_empty');
    }

    if (newIllustration === null) {
      errorMessages.push('image_or_video_missing');
    }
    if (callToActionIsEnabled) {
      if (callToActionText === '') {
        errorMessages.push('call_to_action_text_empty');
      }

      if (callToActionIsExternal) {
        if (callToActionURI === '') {
          errorMessages.push('call_to_action_uri_empty');
        } else if (!isValidURL(callToActionURI)) {
          errorMessages.push('call_to_action_uri_invalid');
        }
      }
    }
    if (errorMessages && errorMessages.length > 0) {
      return { errors: true, errorMessages };
    }

    if (!selectedRegions || selectedRegions.length === 0) {
      errorMessages.push('no_region_selected');
      // throw new Error('You can not save an entry without region');
    }
    return {
      title,
      post,
      media: {
        illustration: newIllustration,
        videoUrl,
        videoId,
        usesVideo,
      },
      regions: selectedRegions,
      callToAction: {
        isEnabled: callToActionIsEnabled,
        text: callToActionText,
        URI: callToActionURI,
        tab: callToActionTab,
        isExternal: callToActionIsExternal,
      },
      published,
    };
  }

  saveNews(goback = true) {
    try {
      const { history } = this.props;
      const news = this.getNews();

      if (news.errors) {
        this.setState({
          errorMessages: news.errorMessages,
        });
      } else {
        realtimeStorage.push('news', news).then(() => {
          if (goback) { history.goBack(); }
        });
      }
    } catch (error) {
      this.setState({ errorMessages: error.message });
    }
  }

  updateNews(goback = true) {
    try {
      const { id } = this.state;
      const { history } = this.props;
      const news = this.getNews();

      if (news.errors) {
        this.setState({
          errorMessages: news.errorMessages,
        });
      } else {
        realtimeStorage.update('news', id, news).then(() => {
          if (goback) { history.goBack(); }
        });
      }
    } catch (error) {
      this.setState({ errorMessages: [error.message] });
    }
  }

  saveOrEditButton(action, goBack) {
    const { classes } = this.props;

    return (
      <I18nButton
        raised={1}
        className={classes.saveButton}
        onClick={async () => {
          if (this.illustrationDropzone) {
            await this.illustrationDropzone.uploadImage().then((result) => {
              this.setState({ illustration: result });
            });
          }
          if (action === 'edit') {
            this.updateNews(goBack);
          } else {
            this.saveNews(goBack);
          }
        }
        }
        i18nKey={goBack ? 'save_and_close' : 'save'}
      />
    );
  }

  handleVideoSwitchChange() {
    this.setState(prevState => ({ usesVideo: !prevState.usesVideo }));
  }

  handleCallToActionSwitchChange() {
    this.setState(prevState => ({ callToActionIsExternal: !prevState.callToActionIsExternal }));
  }

  handleCallToActionCheckboxChange(event) {
    this.setState({ callToActionIsEnabled: event.target.checked });
  }

  renderImageOrVideo() {
    const {
      usesVideo, videoUrl, videoId, illustration, readOnly,
    } = this.state;
    if (usesVideo) {
      return (
        <Grid item xs={6}>
          <I18nTextField
            disabled={readOnly}
            i18nLabelKey="video_url"
            value={videoUrl}
            onChange={(value) => {
              // eslint-disable-next-line
              const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
              const match = value.match(regExp);
              const id = (match && match[7].length === 11) ? match[7] : false;
              this.setState({
                videoUrl: value,
                videoId: id,
              });
            }}
            flex
          />
          {videoId && (
            <YouTube
              videoId={videoId}
              opts={{
                height: '276',
                width: '490',
              }}
            />
          )}
        </Grid>
      );
    }
    return (
      <Grid item xs={6}>
        <Dropzone
          disabled={readOnly}
          i18nLabelKey="illustration"
          type="illustrations"
          value={illustration}
          ref={(ref) => { this.illustrationDropzone = ref; }}
        />
      </Grid>
    );
  }

  render() {
    const {
      match,
      classes,
      regions,
    } = this.props;
    const {
      title,
      post,
      usesVideo,
      error,
      selectedRegions,
      callToActionIsEnabled,
      callToActionText,
      callToActionURI,
      callToActionTab,
      callToActionIsExternal,
      errorMessages,
      readOnly,
    } = this.state;

    let i18nTitleKey;
    if (match.params.action === 'add') {
      i18nTitleKey = 'add_news';
    } else if (match.params.action === 'edit') {
      i18nTitleKey = 'edit_news';
    } else if (match.params.action === 'show') {
      i18nTitleKey = 'show_news';
    }

    let callToActionInput;

    if (callToActionIsExternal) {
      callToActionInput = (
        <I18nTextField
          disabled={readOnly}
          i18nLabelKey="action_uri"
          value={callToActionURI}
          onChange={(value) => {
            this.setState({ callToActionURI: value });
          }
          }
          flex
        />
      );
    } else {
      callToActionInput = (
        <FormControl className={classes.formControl}>
          <InputLabel shrink htmlFor="age-label-placeholder">
            {i18n.t('screen_name')}
          </InputLabel>
          <Select
            value={callToActionTab}
            onChange={(event) => {
              this.setState({ callToActionTab: event.target.value });
            }}
            input={<Input name="age" id="age-label-placeholder" />}
            displayEmpty
            name="age"
            className={classes.selectEmpty}
          >
            <MenuItem value="training">{i18n.t('training_screen')}</MenuItem>
            <MenuItem value="videos">{i18n.t('videos_screen')}</MenuItem>
            <MenuItem value="shop">{i18n.t('shop_screen')}</MenuItem>
          </Select>
        </FormControl>
      );
    }

    return (
      <Paper className={classes.paper} elevation={4}>
        <form className={classes.container} autoComplete="off">
          <Grid container spacing={16} justify="space-between">
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={6}>
                  <I18nTypography i18nKey={i18nTitleKey} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              xs={12}
              direction="column"
            >
              <Grid item xs={8}>
                <I18nTextField
                  disabled={readOnly}
                  i18nLabelKey="title"
                  value={title}
                  onChange={value => this.setState({ title: value })}
                  flex
                />
              </Grid>
            </Grid>
            {(regions && regions.length > 1)
              && (
                <RegionSelector
                  disabled={readOnly}
                  value={selectedRegions}
                  regions={regions}
                  onChange={values => this.setState({ selectedRegions: values })}
                  multiple
                />
              )
            }
            {/* </Grid> */}
            <Grid
              container
              spacing={16}
              xs={12}
              // justify="center"
              alignItems="center"
              direction="row"
              style={{
                marginTop: 20,
                marginLeft: 10,
                marginBottom: 10,
              }}
            >
              <I18nTypography variant="subheading" i18nKey="image" />
              <Switch
                disabled={readOnly}
                onChange={() => this.handleVideoSwitchChange()}
                checked={usesVideo}
                onColor="#ddd"
                offColor="#ddd"
                onHandleColor="#76A64B"
                offHandleColor="#76A64B"
                uncheckedIcon={false}
                checkedIcon={false}
                className={classes.switch}
              />
              <I18nTypography variant="subheading" i18nKey="video" />
            </Grid>
            <Grid
              container
              spacing={16}
              xs={12}
              // justify="center"
              alignItems="center"
            >
              {this.renderImageOrVideo()}
            </Grid>
            <Grid
              container
              spacing={16}
              xs={12}
              justify="center"
              alignItems="center"
              direction="row"
              style={{ marginTop: 20 }}
            >
              <CKEditor
                editor={ClassicEditor}
                disabled={readOnly}
                data={post}
                config={{
                  language: 'de',
                  removePlugins: ['EasyImage', 'Image', 'ImageUpload', 'ImageToolbar', 'MediaEmbed'],
                  toolbar: [
                    'Heading',
                    '|',
                    'bold', 'italic',
                    '|',
                    'link', 'bulletedList', 'numberedList', 'blockQuote',
                    '|',
                    'insertTable',
                    '|',
                    'undo', 'redo',
                  ],
                }}
                onChange={(event, editor) => {
                  this.setState({
                    post: editor.getData(),
                  });
                }}
              />
            </Grid>
            <Grid container direction="row" spacing={16} xs={12} style={{ marginTop: 45 }}>
              <Grid item xs={12}>
                <I18nTypography
                  i18nKey="call_to_action_button"
                // style={{ textAlign: 'center' }}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormGroup>
                    <FormControlLabel
                      control={
                        (
                          <Checkbox
                            checked={callToActionIsEnabled}
                            onChange={event => this.handleCallToActionCheckboxChange(event)}
                            value="checkedA"
                            color="primary"
                          />
                        )
                      }
                      label={i18n.t('enable')}
                    />
                  </FormGroup>
                </FormControl>
              </Grid>
              {callToActionIsEnabled && (
                <Fragment>
                  <Grid
                    container
                    spacing={16}
                    xs={12}
                    // justify="center"
                    alignItems="center"
                    direction="row"
                    style={{
                      marginTop: 20,
                      marginLeft: 10,
                    }}
                  >
                    <I18nTypography variant="subheading" i18nKey="link_within_app" />
                    <Switch
                      disabled={readOnly}
                      onChange={() => this.handleCallToActionSwitchChange()}
                      checked={callToActionIsExternal}
                      onColor="#ddd"
                      offColor="#ddd"
                      onHandleColor="#76A64B"
                      offHandleColor="#76A64B"
                      uncheckedIcon={false}
                      checkedIcon={false}
                      className={classes.switch}
                    />
                    <I18nTypography variant="subheading" i18nKey="link_to_external" />
                  </Grid>
                  <Grid item xs={4}>
                    <I18nTextField
                      disabled={readOnly}
                      i18nLabelKey="button_text"
                      value={callToActionText}
                      onChange={value => this.setState({ callToActionText: value })}
                      flex
                    />
                  </Grid>
                  <Grid item xs={4}>
                    {callToActionInput}
                  </Grid>
                </Fragment>
              )}
            </Grid>
            {errorMessages && (
              <Grid container justify="center">
                {errorMessages.map(message => (
                  <Grid xs={12}>
                    <I18nTypography i18nKey={message} style={{ color: '#a00', textAlign: 'center' }} />
                  </Grid>
                ))}
              </Grid>
            )}
            {(!readOnly)
              && (
                <Grid
                  container
                  direction="row"
                // justify="center"
                >
                  {this.saveOrEditButton(match.params.action, true)}
                </Grid>
              )
            }
            <div className={classes.formControl}>
              <I18nTypography>{error}</I18nTypography>
            </div>
          </Grid>
        </form>
      </Paper>
    );
  }
}

AddOrEditNews.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  regions: PropTypes.array,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 1000,
    minWidth: 400,
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  buttonGrid: {
    position: 'relative',
  },
  saveButton: {
    // margin: 'auto',
    margin: 10,
    position: 'relative',
    display: 'block',
  },
  textField: {
    width: '90%',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  exercisePreview: {
    height: 100,
  },
  exerciseList: {
    marginTop: '20px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  exerciseListItem: {
    position: 'relative',
  },
  exerciseListTitle: {
    position: 'absolute',
    margin: '12px 30px',
    fontSize: '130%',
  },
  switch: {
    margin: '0 15px',
  },
  formControl: {
    marginTop: 16,
    marginBottom: 16,
    minWidth: 120,
  },
  // youtubePlayer: {
  //   width: 100,
  // }
});


export default withStyles(styles)(AddOrEditNews);
