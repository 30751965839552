import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import admins from './admins';
import countries from './countries';
import languages from './languages';
import notifications from './notifications';
import user from './user';
import videos from './videos';

const rootReducer = combineReducers({
  router: routerReducer,
  admins,
  countries,
  languages,
  notifications,
  user,
  videos,
});

export default rootReducer;
