import store from 'store';

class StorageService {
  constructor(storageInterface = store) {
    this.storageInterface = storageInterface;
  }

  get(key, defaultValue = null) {
    return this.storageInterface.get(key, defaultValue);
  }

  set(key, value) {
    return this.storageInterface.set(key, value);
  }

  remove(key) {
    this.storageInterface.remove(key);
  }
}

export { StorageService };
export default new StorageService();
