

import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { withStyles } from '@material-ui/core/styles';

import { Icon } from 'react-icons-kit';
import {
  accessibility,
  home,
  newspaper,
  film,
  bullhorn,
  users,
  image,
} from 'react-icons-kit/icomoon';
import { ic_directions_bike } from 'react-icons-kit/md/ic_directions_bike';
import { ic_shopping_cart } from 'react-icons-kit/md/ic_shopping_cart';
import { ic_videocam } from 'react-icons-kit/md/ic_videocam';
import i18n from '../helpers/i18n';

const menuListItem = (props) => {
  const {
    iconName,
    i18nTitleKey,
    path,
    classes,
  } = props;

  let icon = home;
  switch (iconName) {
    case 'exercise':
      icon = accessibility;
      break;
    case 'sports':
      icon = ic_directions_bike;
      break;
    case 'video_categories':
      icon = ic_videocam;
      break;
    case 'news':
      icon = newspaper;
      break;
    case 'videos':
      icon = film;
      break;
    case 'push_notification':
      icon = bullhorn;
      break;
    case 'image':
      icon = image;
      break;
    case 'users':
      icon = users;
      break;
    case 'products':
      icon = ic_shopping_cart;
      break;
    default:
      break;
  }

  return (
    <Link to={path} className={classes.menuLink}>
      <ListItem button>
        <ListItemIcon>
          <Icon icon={icon} size={30} />
        </ListItemIcon>
        <ListItemText primary={i18n.t(i18nTitleKey)} />
      </ListItem>
    </Link>
  );
};

menuListItem.propTypes = {
  iconName: PropTypes.string.isRequired,
  i18nTitleKey: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = theme => ({
  menuLink: {
    'text-decoration': 'none',
    color: theme.palette.primary.main,
  },
});

export default withStyles(styles, { withTheme: true })(menuListItem);
