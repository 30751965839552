import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import _ from 'underscore';

import I18nTypography from '../components/i18nTypography';
import I18nTextField from '../components/i18nTextField';
import I18nButton from '../components/i18nButton';

import Dropzone from '../components/dropZone';

import { realtimeStorage } from '../helpers/firebaseDatabase';

class AddOrEditSport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: {},
      illustration: null,
    };
  }

  componentWillMount() {
    const { match } = this.props;

    realtimeStorage.getOnce('languages').then((data) => {
      this.setState({ languages: data });
    });

    if (match.params.action === 'edit') {
      realtimeStorage.getDoc('sports', match.params.id).then((data) => {
        this.setState({
          id: data.id,
          title: data.title,
          illustration: data.illustration,
        });
      });
    }
  }

  getSport() {
    const {
      title,
      illustration,
    } = this.state;

    return {
      title,
      illustration,
    };
  }

  // eslint-disable-next-line
  getRef(collection, id) {
    if (id) {
      return collection.find(r => r.id === id).ref;
    }
    return null;
  }

  // eslint-disable-next-line
  getIds(collection, refs) {
    if (_.isArray(refs)) {
      return refs.map((ref) => {
        const details = collection.find(r => r.id === ref.id);
        return ({ value: ref.id, label: details.titleDe, ref });
      });
    }
    return null;
  }

  // eslint-disable-next-line
  getId(refs) {
    if (refs) {
      return refs.id;
    }
    return null;
  }

  saveSport(goback = true) {
    const { history } = this.props;

    realtimeStorage.push('sports', this.getSport()).then(() => {
      if (goback) { history.goBack(); }
    });
  }

  updateSport(goback = true) {
    const { id } = this.state;
    const { history } = this.props;
    const sport = this.getSport();

    realtimeStorage.update('sports', id, sport).then(() => {
      if (goback) { history.goBack(); }
    });
  }

  render() {
    const { match, classes } = this.props;
    const {
      title,
      languages,
      illustration,
    } = this.state;

    let i18nTitleKey;
    if (match.params.action === 'add') {
      i18nTitleKey = 'add_new_sport';
    } else if (match.params.action === 'edit') {
      i18nTitleKey = 'edit_sport';
    }

    return (
      <Paper className={classes.paper} elevation={4}>
        <form className={classes.container} autoComplete="off">
          <Grid container spacing={16} justify="space-between">
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={6}>
                  <I18nTypography variant="title" i18nKey={i18nTitleKey} />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid
                container
                spacing={0}
                xs={12}
                direction="column"
              >
                {languages && languages.map(language => (
                  <Grid item xs={12}>
                    <I18nTextField
                      key={`title_${language.i18nKey}`}
                      i18nLabelKey={`title_${language.i18nKey}`}
                      value={title[language.i18nKey] || ''}
                      onChange={value => this.setState(prevState => ({
                        title: {
                          ...prevState.title,
                          [language.i18nKey]: value,
                        },
                      }))}
                      flex
                    />
                  </Grid>
                ))
                }
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container justify="flex-end" alignItems="flex-end">
                <Grid item xs={12} style={{ overflow: 'hidden', height: 277 }}>
                  <Dropzone
                    i18nLabelKey="illustration"
                    type="illustrations"
                    value={illustration}
                    ref={(ref) => { this.illustrationDropzone = ref; }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.buttonGrid}>
              <I18nButton
                raised={1}
                className={classes.saveButton}
                onClick={async () => {
                  await this.illustrationDropzone.uploadImage().then((result) => {
                    this.setState({ illustration: result });
                  });
                  if (match.params.action === 'edit') {
                    this.updateSport();
                  } else {
                    this.saveSport();
                  }
                }}
                i18nKey="save"
              />
            </Grid>
          </Grid>
        </form>
      </Paper>

    );
  }
}


AddOrEditSport.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 800,
    minWidth: 400,
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  buttonGrid: {
    position: 'relative',
  },
  saveButton: {
    margin: 'auto',
    position: 'relative',
    display: 'block',
  },
  textField: {
    width: '90%',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});


export default withStyles(styles)(AddOrEditSport);
