import axios from 'axios';
import mitt from 'mitt';
import appRegistry from './AppRegistry';
import { LocalStorageKeys } from '../constants/Storage';

class RestApiInterceptor {
  constructor(baseURL) {
    this.baseURL = baseURL || `${appRegistry.storage.get(LocalStorageKeys.url)}`;
    const settings = {
      baseURL: this.baseURL,
    };

    this.api = axios.create(settings);
    this.api.interceptors.response.use(
      successRes => successRes,
      (error) => {
        if (error.response.status === 401) {
          RestApiInterceptor.events.emit('authorizationError', { err: error });
        }

        return Promise.reject(error);
      },
    );
  }

  static events = mitt();

  async get(url, config) {
    return this.api.get(url, this.mergeConfig(config));
  }

  async post(url, data, config) {
    return this.api.post(url, data, this.mergeConfig(config));
  }

  // eslint-disable-next-line class-methods-use-this
  async fakePost(responseObject, isSuccess = true) {
    return new Promise((resolve, reject) => {
      if (isSuccess) {
        return resolve(responseObject);
      }
      return reject(responseObject);
    });
  }

  async put(url, data, config) {
    return this.api.put(url, data, this.mergeConfig(config));
  }

  async patch(url, data, config) {
    return this.api.patch(url, data, this.mergeConfig(config));
  }

  async delete(url, config) {
    return this.api.delete(url, this.mergeConfig(config));
  }

  registerResponseInterceptor(success, error) {
    this.api.interceptors.response.use(success, error);
  }

  // eslint-disable-next-line class-methods-use-this
  mergeConfig(config = {}) {
    const token = appRegistry.storage.get(LocalStorageKeys.token);

    return Object.assign({}, config, {
      headers: {
        // Pragma: 'no-cache',
        Authorization: `Bearer ${token}`,
        ...config.headers,
        Origin: 'web',
      },
    });
  }
}

export { RestApiInterceptor };
export default new RestApiInterceptor();
