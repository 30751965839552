import firebase, { timestamp } from './firebase';

let db;
let root;
firebase.init()
  .then(() => {
    db = firebase.getInstance().firestore();
    // eslint-disable-next-line max-len
    /* if you want to use local functions for debugging please uncomment 2 lines below */
    // const settings = { host: 'localhost:8080', ssl: false };
    // db.settings(settings);
    root = db
      .collection('v1')
      .doc('data');
  });

const realtimeStorage = {
  getOn: (type, callback, actions = []) => {
    let ref = root.collection(type);

    actions.forEach((action) => {
      if (action.type === 'orderBy') {
        if (action.direction) {
          ref = ref.orderBy(action.field, action.direction);
        } else {
          ref = ref.orderBy(action.field, 'asc');
        }
      } else if (action.type === 'where') {
        ref = ref.where(action.field, action.opStr, action.value);
      }
    });

    return ref.onSnapshot((querySnapshot) => {
      const data = [];
      querySnapshot.forEach((doc) => {
        data.push(Object.assign(doc.data(), {
          id: doc.ref.id,
          ref: doc.ref,
        }));
      });
      callback(data);
    });
  },
  getOnce: (type, { field, direction = 'asc' } = {}) => {
    let ref = root.collection(type);
    if (field) {
      ref = ref.orderBy(field, direction);
    }
    return ref.get()
      .then((querySnapshot) => {
        const data = [];
        querySnapshot.forEach((doc) => {
          data.push(Object.assign(doc.data(), {
            id: doc.ref.id,
            ref: doc.ref,
          }));
        });
        return data;
      });
  },

  getDoc: (type, docId) => root.collection(type)
    .doc(docId)
    .get()
    .then(async (doc) => {
      if (doc.exists) {
        // const resolveObject = await resolveRefs(doc.data());
        return Object.assign(doc.data(), { id: doc.ref.id });
      }
      return {};
    }),

  push: (type, obj) => root.collection(type)
    .add({
      ...obj,
      createdAt: timestamp(),
    }),

  update: (type, id, obj) => root.collection(type)
    .doc(id)
    .update({
      ...obj,
      updatedAt: timestamp(),
    }),

  delete: (type, items) => {
    const batch = db.batch();
    items.forEach((item) => {
      const ref = root.collection(type)
        .doc(item.id);
      batch.delete(ref);
    });
    return batch.commit();
  },

  publishNews: (items) => {
    items.forEach((item) => {
      const ref = root.collection('news')
        .doc(item.id);
      ref.get()
        .then((news) => {
          ref.update({
            published: !news.data().published,
            publishedAt: timestamp(),
          });
        });
    });
  },
};

export {
  // eslint-disable-next-line
  realtimeStorage,
};
