import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

import HowToReg from '@material-ui/icons/HowToReg';

import { titleCase } from 'change-case';
import Table from '../components/table';
import Dialog from '../components/dialog';
import Select from '../components/i18nSelect';
import i18n from '../helpers/i18n';

const columnData = [
  {
    id: 'uid',
    numeric: false,
    disablePadding: false,
    i18nKey: 'id',
  },
  {
    id: 'email',
    numeric: false,
    disablePadding: false,
    i18nKey: 'email',
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    valueCallback: value => value.creationTime,
    i18nKey: 'created_at',
    timestamp: true,
  },
  {
    id: 'metadata',
    numeric: false,
    disablePadding: false,
    valueCallback: value => value.lastRefreshTime,
    i18nKey: 'last_seen',
    timestamp: true,
  },
  {
    id: 'customClaims',
    key: 'role',
    numeric: false,
    disablePadding: false,
    valueCallback: (value) => {
      if (value) {
        return value.role;
      }
      return null;
    },
    i18nKey: 'role',
  },
  {
    id: 'customClaims',
    key: 'regions',
    numeric: false,
    disablePadding: false,
    valueCallback: (value) => {
      let readableRegions = '';
      if (value && value.regions && value.regions.length > 0) {
        readableRegions = value.regions.map(region => titleCase(region))
          .reduce((prevValue, currentValue) => `${prevValue}, ${currentValue}`);
      }
      return readableRegions;
    },
    i18nKey: 'countries',
  },
];

const userRoles = [
  {
    label: 'Windhund Admin',
    value: 'windhund_admin',
  },
  {
    label: 'Super Admin',
    value: 'super_admin',
  },
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Distributor',
    value: 'distributor',
  },
];

const Users = ({
  addUserRole,
  allCountries,
  allUsers,
  countries,
  fetchAllUsers,
  getCountries,
  getLanguages,
  isLoading,
}) => {
  const [selectedRole, setSelectedRole] = useState();
  const [selectedCountries, setSelectedCountries] = useState();
  const [currentRef, setCurrentRef] = useState();
  const [selectedItems, setSelectedItems] = useState();

  const addOrUpdateUserRole = useCallback(() => {
    selectedItems.forEach((item) => {
      const newRegions = (selectedRole === 'windhund_admin' || selectedRole === 'super_admin') ? allCountries : selectedCountries;
      addUserRole({
        uid: item.uid,
        role: selectedRole,
        regions: newRegions,
      });
    });
  }, [addUserRole, allCountries, selectedItems, selectedRole, selectedCountries]);

  useEffect(() => {
    fetchAllUsers();
    getCountries();
    getLanguages();
  }, [fetchAllUsers, getCountries]);

  return (
    <Fragment>
      <Dialog
        i18nTitleKey="select_role"
        onRef={setCurrentRef}
        onSave={() => addOrUpdateUserRole()}
      >
        <Select
          value={selectedRole}
          onChange={role => setSelectedRole(role)}
          options={userRoles}
          i18nLabelKey="role"
        />
        {(selectedRole === 'admin' || selectedRole === 'distributor')
        && (
          <Select
            value={selectedCountries}
            onChange={newCountries => setSelectedCountries(newCountries)}
            options={countries}
            i18nLabelKey="countries"
            multiple
          />
        )
        }
      </Dialog>
      <div>
        <Table
          isLoading={isLoading}
          keyColumn="uid"
          data={allUsers}
          columns={columnData}
          onDeleteItems={() => { }}
          allowAddItem={false}
          toolbarItems={[{
            tooltipLabel: i18n.t('assignRole'),
            onClick: (selected) => {
              currentRef.open();
              setSelectedItems(selected);
            },
            icon: (<HowToReg />),
          }]}
        />
      </div>
    </Fragment>
  );
};

Users.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  allCountries: PropTypes.array.isRequired,
  countries: PropTypes.array.isRequired,
  getCountries: PropTypes.func.isRequired,
  getLanguages: PropTypes.func.isRequired,
  fetchAllUsers: PropTypes.func.isRequired,
  allUsers: PropTypes.array.isRequired,
  addUserRole: PropTypes.func.isRequired,
};

export default Users;
