import { createMuiTheme } from '@material-ui/core/styles';

// creating our own muiTheme
const muiTheme = createMuiTheme({
  typography: {
    fontFamily: 'Rajdhani, sans-serif',
    fontSize: 14,
  },
  palette: {
    primary: {
      light: '#93ab39',
      main: '#93ab39',
      dark: '#93ab39',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#3C3C3C',
    },
    error: {
      light: 'rgb(244, 67, 54)',
      main: 'rgb(244, 67, 54)',
      dark: 'rgb(244, 67, 54)',
      contrastText: '#ffffff',
    },
    success: {
      light: '#a6e22e',
      main: '#a6e22e',
      dark: '#a6e22e',
      contrastText: '#ffffff',
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)',
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)',
    },
  },
});
export default muiTheme;
