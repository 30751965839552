import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// reducers
import { addUserRole, fetchAllUsers } from '../ducks/admins';
import { getLanguages } from '../ducks/languages';
import { getCountries } from '../ducks/countries';

// selectors
import {
  hasError,
  selectAllCountries,
  selectCountries,
} from '../selectors/countries';

import {
  selectAllUsers,
  selectIsLoading,
} from '../selectors/admins';

import UsersScene from '../pages/users';

const mapStateToProps = state => ({
  countries: selectCountries(state),
  allUsers: selectAllUsers(state),
  allCountries: selectAllCountries(state),
  isLoading: selectIsLoading(state),
  error: hasError(state),
});

const mapDispatchToProps = dispatch => ({
  getCountries: async () => {
    await dispatch(getCountries());
  },
  getLanguages: async () => {
    await dispatch(getLanguages());
  },
  fetchAllUsers: async () => {
    await dispatch(fetchAllUsers());
  },
  addUserRole: async (payload) => {
    await dispatch(addUserRole(payload));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersScene));
