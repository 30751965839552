import { RestApiInterceptor } from './RestApiInterceptor';
import appRegistry from './AppRegistry';
import { LocalStorageKeys } from '../constants/Storage';

class AuthService {
  token = null;

  static options() {
    return {
      headers: { Accept: 'application/json' },
    };
  }

  constructor({
    api = null,
    storageService = null,
  } = {}) {
    this.api = api || new RestApiInterceptor();
    this.storageService = storageService || appRegistry.storage;

    this.token = this.storageService.get(LocalStorageKeys.token);
  }

  saveToken(token) {
    this.token = token;
    this.storageService.set(LocalStorageKeys.token, token);
  }

  saveApiUrl(url) {
    this.url = url;
    this.storageService.set(LocalStorageKeys.url, url);
  }

  removeToken() {
    this.storageService.remove(LocalStorageKeys.token);
  }

  removeApiUrl() {
    this.storageService.remove(LocalStorageKeys.url);
  }


  getToken() {
    this.storageService.getItem(LocalStorageKeys.token);
  }

  deleteUserById(uid) {
    return this.api.delete(`/api/users/${uid}`);
  }

  getAllUsers() {
    return this.api.post('/api/users/all');
  }

  sendVerificationEmailLink(uid) {
    return this.api.post('/api/users/resendVerificationEmailLink', { uid });
  }

  sendUserPasswordResetEmail(email) {
    return this.api.post('/api/users/passwordReset', { email });
  }

  async login(email, password) {
    const response = await this.api.post('/auth', {
      email,
      password,
    });

    return this.handleLoginResponse(response);
  }

  handleLoginResponse(response) {
    const { token } = response.data;
    this.saveToken(token);
    return response.data;
  }
}

export default AuthService;
