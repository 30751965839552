import { propOr } from 'ramda';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';

import _ from 'underscore';

import I18nTypography from '../components/i18nTypography';
import I18nTextField from '../components/i18nTextField';
import I18nSelect from '../components/i18nSelect';
import I18nButton from '../components/i18nButton';

import Dropzone from '../components/dropZone';
import I18nMultiSelect from '../components/i18nMultiSelect';

import { realtimeStorage } from '../helpers/firebaseDatabase';
import i18n from '../helpers/i18n';

class AddOrEditExercise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uid: '',
      title: {},
      repetitionDuration: null,
      beginner: null,
      intermediate: null,
      professional: null,
      selectedCategory: null,
      selectedPainPoints: null,
      selectedBodySections: null,
      selectedRepetitionsCategory: null,
      selectedProducts: null,
      selectedLevel: null,
      animation: null,
      illustration: null,
      description: {},
      painPoints: [],
      bodySections: [],
      categories: [],
      products: [],
      repetitionsCategories: [],
      levels: [],
      languages: [],
    };
  }

  componentWillMount() {
    const { match } = this.props;

    const promises = [];
    promises.push(realtimeStorage.getOnce('categories').then((data) => {
      this.setState({ categories: data });
    }));

    promises.push(realtimeStorage.getOnce('bodySections', { field: 'positionIndex' }).then((data) => {
      this.setState({
        bodySections: data,
      });
    }));

    promises.push(realtimeStorage.getOnce('painPoints', { field: 'positionIndex' }).then((data) => {
      this.setState({
        painPoints: data,
      });
    }));

    promises.push(realtimeStorage.getOnce('repetitionsCategories').then((data) => {
      this.setState({ repetitionsCategories: data });
    }));

    promises.push(realtimeStorage.getOnce('products', { field: 'name' }).then((data) => {
      this.setState({ products: data });
    }));

    promises.push(realtimeStorage.getOnce('exerciseLevels').then((data) => {
      this.setState({ levels: data });
    }));

    promises.push(realtimeStorage.getOnce('languages').then((data) => {
      this.setState({ languages: data });
    }));

    Promise.all(promises).then(() => {
      const {
        painPoints,
        products,
        bodySections,
      } = this.state;

      if (match.params.action === 'edit') {
        realtimeStorage.getDoc('exercises', match.params.id).then((data) => {
          this.setState({
            uid: data.id,
            id: data.id,
            title: data.title,
            description: propOr({}, 'description', data),
            selectedCategory: this.getId(data.category),
            selectedPainPoints: this.getByIds(painPoints, Object.keys(data.painPoints || {})),
            selectedBodySections: this.getByIds(bodySections, Object.keys(data.bodySections || {})),
            selectedProducts: this.getByIds(products, Object.keys(data.products || {})),
            selectedRepetitionsCategory: this.getId(data.repetition.description),
            selectedLevel: data.level,
            repetitionDuration: data.repetition.duration,
            beginner: data.repetition.levels.beginner,
            intermediate: data.repetition.levels.intermediate,
            professional: data.repetition.levels.professional,
            animation: data.media.animation,
            illustration: data.media.illustration,
          });
          this.getExercise();
        });
      }
    });
  }

  getExercise() {
    const {
      uid,
      animation,
      beginner,
      categories,
      description,
      illustration,
      intermediate,
      professional,
      repetitionDuration,
      repetitionsCategories,
      selectedBodySections,
      selectedCategory,
      selectedLevel,
      selectedPainPoints,
      selectedProducts,
      selectedRepetitionsCategory,
      title,
    } = this.state;

    const bodySectionsMap = selectedBodySections && selectedBodySections.reduce((map, obj) => {
      const newMap = map;
      newMap[obj.value] = true;
      return newMap;
    }, {});

    const selectedProductsMap = selectedProducts && selectedProducts.reduce((map, obj) => {
      const newMap = map;
      newMap[obj.value] = true;
      return newMap;
    }, {});

    const selectedPainPointMap = selectedPainPoints && selectedPainPoints.reduce((map, obj) => {
      const newMap = map;
      newMap[obj.value] = true;
      return newMap;
    }, {});

    return {
      uid,
      bodySections: bodySectionsMap,
      category: this.getRef(categories, selectedCategory),
      description,
      painPoints: selectedPainPointMap,
      title,
      media: {
        animation,
        illustration,
      },
      repetition: {
        description: this.getRef(repetitionsCategories, selectedRepetitionsCategory),
        duration: repetitionDuration,
        levels: {
          beginner,
          intermediate,
          professional,
        },
      },
      products: selectedProductsMap,
      level: selectedLevel,
    };
  }

  // eslint-disable-next-line
  getRef(collection, id) {
    if (id) {
      return collection.find(r => r.id === id).ref;
    }
    return null;
  }

  // eslint-disable-next-line
  getByIds(collection, ids) {
    if (ids) {
      const filtered = collection.filter(
        function (e) {
          return this.indexOf(e.id) >= 0;
        },
        ids,
      );

      return filtered.map((item) => {
        const newItem = item;
        newItem.label = item.name ? item.name : item.title.de;
        newItem.value = item.id;
        return newItem;
      });
    }
    return null;
  }

  // eslint-disable-next-line
  getIds(collection, refs) {
    if (_.isArray(refs)) {
      return refs.map((ref) => {
        const details = collection.find(r => r.id === ref.id);
        return ({ value: ref.id, label: details.title.de, ref });
      });
    }
    return null;
  }

  // eslint-disable-next-line
  getId(refs) {
    if (refs) {
      return refs.id;
    }
    return null;
  }

  saveExercise() {
    const { history } = this.props;
    realtimeStorage.push('exercises', this.getExercise()).then(() => {
      history.goBack();
    });
  }

  updateExercise() {
    const { id } = this.state;
    const { history } = this.props;
    const exercise = this.getExercise();
    realtimeStorage.update('exercises', id, exercise).then(() => {
      history.goBack();
    });
  }

  render() {
    const { match, classes } = this.props;
    const {
      description,
      title,
      painPoints,
      bodySections,
      categories,
      selectedCategory,
      selectedPainPoints,
      selectedBodySections,
      selectedProducts,
      repetitionsCategories,
      selectedRepetitionsCategory,
      selectedLevel,
      repetitionDuration,
      beginner,
      intermediate,
      professional,
      animation,
      illustration,
      products,
      levels,
      languages,
    } = this.state;

    let i18nTitleKey;
    if (match.params.action === 'add') {
      i18nTitleKey = 'add_new_exercise';
    } else if (match.params.action === 'edit') {
      i18nTitleKey = 'edit_exercise';
    }

    const allTitles = [];
    const allDescriptions = [];

    if (title) {
      languages.forEach((language) => {
        allTitles.push(
          <Grid item xs={12}>
            <I18nTextField
              key={`title_${language.i18nKey}`}
              i18nLabelKey={`title_${language.i18nKey}`}
              value={title[language.i18nKey] || ''}
              onChange={value => this.setState(prevState => ({
                title: {
                  ...prevState.title,
                  [language.i18nKey]: value,
                },
              }))}
              flex
            />
          </Grid>,
        );
      });
    }

    if (description) {
      languages.forEach((language) => {
        allDescriptions.push(
          <Grid item xs={12}>
            <I18nTextField
              key={`description_${language.i18nKey}`}
              i18nLabelKey={`description_${language.i18nKey}`}
              value={description[language.i18nKey] || ''}
              onChange={value => this.setState(prevState => ({
                description: {
                  ...prevState.description,
                  [language.i18nKey]: value,
                },
              }))}
              flex
            />
          </Grid>,
        );
      });
    }

    return (
      <Paper className={classes.paper} elevation={4}>
        <I18nTypography i18nKey={i18nTitleKey} />
        <form className={classes.container} autoComplete="off">
          <Grid container spacing={5}>
            {allTitles}
            {allDescriptions}
            <Grid item xs={6}>
              <Dropzone
                i18nLabelKey="animation"
                type="animations"
                value={animation}
                ref={(ref) => { this.animationDropzone = ref; }}
                accept="video/*"
              />
            </Grid>
            <Grid item xs={6}>
              <Dropzone
                i18nLabelKey="illustration"
                type="illustrations"
                value={illustration}
                ref={(ref) => { this.illustrationDropzone = ref; }}
                accept="image/*"
              />
            </Grid>
            <Grid item xs={6}>
              <I18nSelect
                i18nLabelKey="category"
                value={selectedCategory}
                onChange={value => this.setState({ selectedCategory: value })}
                options={
                  categories.map(category => (
                    { value: category.id, label: category.title.de }
                  ))
                }
              />
            </Grid>
            <Grid item xs={6}>
              <I18nMultiSelect
                i18nLabelKey="product"
                value={selectedProducts}
                onChange={(value) => {
                  this.setState({ selectedProducts: value });
                }
                }
                options={
                  products.map(product => (
                    { value: product.id, label: product.name }
                  ))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <I18nMultiSelect
                i18nLabelKey="pain_points"
                value={selectedPainPoints}
                onChange={value => this.setState({ selectedPainPoints: value })}
                options={
                  painPoints.map(painPoint => (
                    { value: painPoint.id, label: painPoint.title.de, ref: painPoint.ref }
                  ))
                }
              />
            </Grid>
            <Grid item xs={12}>
              <I18nMultiSelect
                i18nLabelKey="body_sections"
                value={selectedBodySections}
                onChange={value => this.setState({ selectedBodySections: value })}
                options={
                  bodySections.map(bodySection => (
                    { value: bodySection.id, label: bodySection.title.de, ref: bodySection.ref }
                  ))
                }
              />
            </Grid>
            <Grid item xs={4}>
              <I18nSelect
                i18nLabelKey="repetition_description"
                value={selectedRepetitionsCategory}
                onChange={(value) => {
                  const result = repetitionsCategories.find(category => category.id === value);
                  if (result) {
                    const isArbitary = result.i18nKey.match(/^arbitrary_/);
                    if (isArbitary) {
                      this.setState({
                        beginner: 30,
                        intermediate: 30,
                        professional: 30,
                        repetitionDuration: 1,
                      });
                    }
                  }

                  this.setState({ selectedRepetitionsCategory: value });
                }
                }
                options={
                  repetitionsCategories.map(category => (
                    { value: category.id, label: i18n.t(category.i18nKey) }
                  ))
                }
              />
            </Grid>
            <Grid item xs={4}>
              <I18nTextField
                i18nLabelKey="repetition_duration"
                value={repetitionDuration}
                onChange={value => this.setState({ repetitionDuration: value })}
                flex
              />
            </Grid>
            <Grid item xs={4}>
              <I18nSelect
                i18nLabelKey="exercise_level"
                value={selectedLevel}
                onChange={value => this.setState({ selectedLevel: value })}
                options={
                  [
                    { value: null, label: '' },
                    ...levels.map(level => (
                      { value: level.id, label: i18n.t(level.i18nKey) }
                    )),
                  ]
                }
              />
            </Grid>
            <Grid item xs={4}>
              <I18nTextField
                i18nLabelKey="repetitions_beginner"
                value={beginner}
                onChange={value => this.setState({ beginner: value })}
                flex
              />
            </Grid>
            <Grid item xs={4}>
              <I18nTextField
                i18nLabelKey="repetitions_intermediate"
                value={intermediate}
                onChange={value => this.setState({ intermediate: value })}
                flex
              />
            </Grid>
            <Grid item xs={4}>
              <I18nTextField
                i18nLabelKey="repetitions_professional"
                value={professional}
                onChange={value => this.setState({ professional: value })}
                flex
              />
            </Grid>
            <I18nButton
              raised={1}
              className={classes.button}
              onClick={async () => {
                await this.animationDropzone.uploadImage().then((result) => {
                  this.setState({ animation: result });
                });
                await this.illustrationDropzone.uploadImage().then((result) => {
                  this.setState({ illustration: result });
                });
                if (match.params.action === 'edit') {
                  this.updateExercise();
                } else {
                  this.saveExercise();
                }
              }}
              i18nKey="save"
            />
          </Grid>
        </form>
      </Paper>

    );
  }
}


AddOrEditExercise.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 800,
    minWidth: 400,
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  button: {
    margin: theme.spacing.unit,
  },
  textField: {
    width: '100%',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});


export default withStyles(styles)(AddOrEditExercise);
