import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

import i18n from '../helpers/i18n';

const myButton = (props) => {
  const {
    classes,
    loading,
    i18nKey,
    ...rest
  } = props;

  return (
    <div className={classes.container}>
      <Button {...rest}>
        {(loading)
          ? <CircularProgress size={20} color="secondary" variant="indeterminate" />
          : i18n.t(i18nKey)
        }
      </Button>
    </div>
  );
};

myButton.defaultProps = {
  variant: 'contained',
  color: 'primary',
};

myButton.propTypes = {
  classes: PropTypes.object.isRequired,
  loading: PropTypes.bool,
  i18nKey: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
};

const styles = theme => ({
  container: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
});

export default withStyles(styles, { withTheme: true })(myButton);
//     label={i18n.t(props.i18nKey)}
