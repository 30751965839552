import {
  apply,
  equals,
  filter,
  flatten,
  head,
  includes,
  isEmpty,
  keys,
  length,
  map,
  objOf,
  omit,
  pick,
  pipe,
  pluck,
  prop,
  propEq,
  propOr,
  reject,
  sort,
  toLower,
  toPairs,
  uniq,
  values,
  takeLast,
  toUpper,
} from 'ramda';
import { isUndefined } from 'ramda-adjunct';
import React, { Component, Fragment } from 'react';
import snakeCase from 'lodash/snakeCase';
import startCase from 'lodash/startCase';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import AddIcon from '@material-ui/icons/Add';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Fab from '@material-ui/core/Fab';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import RemoveIcon from '@material-ui/icons/Remove';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Switch from '@material-ui/core/Switch';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import Typography from '@material-ui/core/Typography';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ClipLoader } from 'react-spinners';
import YouTube from 'react-youtube';

import _ from 'underscore';

import I18nTypography from '../components/i18nTypography';
import i18n from '../helpers/i18n';
import I18nTextField from '../components/i18nTextField';
import I18nButton from '../components/i18nButton';

import I18nSelect from '../components/i18nSelect';
import DraggableList from '../components/draggableList';
import DB from '../helpers/firebase.service';
import { confirmPrompt } from '../helpers/prompt.service';

class AddOrEditVideos extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addingVideo: false,
      categories: [],
      editingVideo: false,
      filters: {},
      isLoading: false,
      isOpen: false,
      selectedCountry: '' || [],
      selectedLanguages: {},
      title: null,
      videoId: null,
      videoUid: null,
      videoUrl: null,
      videosCollection: {},
      youtubeVideoTitle: null,
    };
  }

  async componentWillMount() {
    const {
      getLanguages, getCountries, getUserDetails, getVideos, getVideoCategories,
    } = this.props;
    await this.loadCategories();
    getLanguages();
    getCountries();
    getUserDetails();
    getVideos();
    getVideoCategories();
  }

  getVideo() {
    const {
      title,
      illustration,
      videoUrl,
      videoId,
      youtubeVideoTitle,
    } = this.state;

    let newIllustration = illustration;
    if (videoId) {
      newIllustration = { url: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg` };
    }

    return {
      title,
      media: {
        illustration: newIllustration,
        videoUrl,
        videoId,
      },
      youtubeVideoTitle,
    };
  }

  async setReorderVideos(videosInNewOrder) {
    const { updateVideos } = this.props;
    const newSortedVideo = videosInNewOrder.map((video, index) => ({
      ...video,
      order: index,
    }));

    newSortedVideo.map(video => updateVideos({
      video,
      uid: video.uid,
    }));
  }

  async loadCategories() {
    const videoCategories = await DB()
      .collection('videoCategories')
      .orderBy('index')
      .data();
    this.setState({
      selectedCategory: videoCategories[0].id,
      selectedCategoryName: videoCategories[0].i18nKey,
      categories: videoCategories.map(category => ({
        ref: category.ref,
        i18nKey: category.i18nKey,
        id: category.id,
      })),
    });
  }

  handleClickDeleteVideo(video) {
    confirmPrompt({
      confirmAction: () => this.deleteVideo(video),
      confirmI18nKey: 'delete_video_question',
    });
  }

  editVideo(video) {
    this.scrollToTop();
    const {
      country, videosCollection, selectedLanguages, uid,
    } = video;
    this.setState({
      editingVideo: true,
      selectedCountry: country,
      selectedLanguages,
      videoUid: uid,
      isOpen: true,
      videosCollection,
    });
  }

  deleteVideo(video) {
    const { deleteVideo } = this.props;
    deleteVideo({
      id: video.uid,
    });
    this.resetForm();
  }

  resetForm() {
    this.setState({
      isLoading: false,
      title: null,
      videoUrl: null,
      videoId: null,
      youtubeVideoTitle: null,
      editingVideo: false,
      selectedCountry: '' || [],
      selectedLanguages: {},
      videosCollection: {},
      videoUid: null,
      isOpen: false,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToTop() {
    window.scrollTo({
      top: 270,
      behavior: 'smooth',
    });
  }

  updateEditedVideo() {
    const {
      selectedCategory,
      selectedCategoryName,
      selectedLanguages,
      videosCollection,
      selectedCountry,
      videoUid,
    } = this.state;

    const { updateVideos, languages } = this.props;

    const languageData = pipe(
      filter(lang => includes(lang.label, keys(selectedLanguages))),
      map(pick(['value', 'uid'])),
    )(languages);

    const transformObjectToArray = data => pipe(
      toPairs,
      map(apply(objOf)),
    )(data);

    const videoPayload = {
      uid: videoUid,
      categoryId: selectedCategory,
      categoryName: selectedCategoryName,
      country: selectedCountry,
      languageData,
      languages: keys(selectedLanguages),
      selectedLanguages,
      videosCollection,
      videos: transformObjectToArray(videosCollection),
    };

    updateVideos({
      video: videoPayload,
      uid: videoPayload.uid,
    });
    this.resetForm();
  }


  async addVideo() {
    const {
      selectedCategory,
      selectedCategoryName,
      selectedLanguages,
      videosCollection,
      selectedCountry,
    } = this.state;

    const {
      addNewVideo,
      languages,
      videos,
      countries,
    } = this.props;

    const languageData = pipe(
      filter(lang => includes(lang.label, keys(selectedLanguages))),
      map(pick(['value', 'uid'])),
    )(languages);

    const transformObjectToArray = data => pipe(
      toPairs,
      map(apply(objOf)),
    )(data);

    const diff = (a, b) => a - b;

    const filteredVideos = pipe(
      filter(propEq('categoryName', selectedCategoryName)),
    )(videos);

    const checkLastNumber = pipe(
      map(prop('order')),
      sort(diff),
      takeLast(1),
      head,
    )(filteredVideos);

    // eslint-disable-next-line array-callback-return
    selectedCountry.map((country) => {
      const searchedCountry = countries
        .find(({ i18nKey }) => i18nKey === country);

      const findCountry = ({
        ...searchedCountry,
        extraLanguages: searchedCountry
          .languages
          .map(languageValue => languages.find(({ value }) => value === languageValue).label),
      });

      const videoPayload = {
        order: isEmpty(filteredVideos) ? 0 : checkLastNumber + 1,
        categoryId: selectedCategory,
        categoryName: selectedCategoryName,
        country: findCountry.i18nKey,
        languageData,
        languages: keys(selectedLanguages),
        selectedLanguages,
        videosCollection,
        videos: transformObjectToArray(videosCollection),
      };

      if (videoPayload.languages.length > 1) {
        const payloadLanguages = videoPayload.languageData.map(({ value }) => value);
        const findProperContentForCountryLanguage = findCountry.languages.map(payloadLanguage => ({
          label: payloadLanguage,
          include: payloadLanguages.includes(payloadLanguage),
        }))
          .filter(({ include }) => include)
          .map(res => res.label);

        if (findProperContentForCountryLanguage) {
          const filterContentByLanguageName = data => Object
            .fromEntries(Object
              .entries(data)
              .filter(([key]) => findCountry.extraLanguages.includes(key)));
          const videoPayloadForMultiSelection = {
            order: isEmpty(filteredVideos) ? 0 : checkLastNumber + 1,
            categoryId: selectedCategory,
            categoryName: selectedCategoryName,
            country: findCountry.i18nKey,
            languageData: languageData.filter(({ value }) => findProperContentForCountryLanguage
              .includes(value)),
            languages: keys(selectedLanguages)
              .filter(selLang => findCountry.extraLanguages.includes(selLang)),
            selectedLanguages: filterContentByLanguageName(selectedLanguages),
            videosCollection: filterContentByLanguageName(videosCollection),
            videos: transformObjectToArray(videosCollection)
              .map(videoCollection => filterContentByLanguageName(videoCollection))
              .filter(videCollection => Object.keys(videCollection).length !== 0),
          };

          addNewVideo(videoPayloadForMultiSelection);
        }
      }

      if (videoPayload.languages.length === 1) {
        addNewVideo(videoPayload);
      }
    });

    this.resetForm();
    this.scrollToTop();
  }

  saveOrEditButton(action, goBack) {
    const { classes } = this.props;
    const { editingVideo, videosCollection, selectedLanguages } = this.state;

    const valueOfSelectedLanguages = pipe(values, length)(selectedLanguages);
    const newVideoContainsURL = videosCollection && pipe(
      map(prop('url')),
      values,
      length,
    )(videosCollection);

    const newVideoContainsTitle = videosCollection && pipe(
      map(prop('title')),
      values,
      reject(isEmpty),
      length,
    )(videosCollection);

    const disabled = isEmpty(videosCollection)
      || !equals(keys(selectedLanguages).sort(), keys(videosCollection).sort())
      || !equals(valueOfSelectedLanguages, newVideoContainsURL)
      || !equals(valueOfSelectedLanguages, newVideoContainsTitle);

    return (
      <I18nButton
        raised
        className={classes.saveButton}
        onClick={() => (editingVideo ? this.updateEditedVideo(goBack) : this.addVideo(goBack))}
        disabled={disabled}
        i18nKey={editingVideo ? 'update_video' : 'save_video'}
      />
    );
  }

  renderVideo(video) {
    const { language, title, videoId } = video;
    const { videosCollection } = this.state;
    return (
      <Grid item xs={12}>
        {videoId && (
          <YouTube
            videoId={videoId}
            opts={{
              height: '350',
              width: '100%',
            }}
            key={videoId}
            onReady={(meta) => {
              const { target } = meta;
              const videoData = target.getVideoData();
              this.setState({
                videosCollection: {
                  ...videosCollection,
                  [language]: {
                    ...video,
                    illustration: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
                    thumbLarge: `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`,
                    thumbMedium: `https://img.youtube.com/vi/${videoId}/mqdefault.jpg`,
                    thumbSmall: `https://img.youtube.com/vi/${videoId}/default.jpg`,
                    isLoading: false,
                    title: title || videoData.title,
                    youtubeVideoTitle: videoData.title,
                  },
                },
              });
            }}
          />
        )}
      </Grid>
    );
  }

  renderVideoListItem(item) {
    const { classes } = this.props;
    const videoData = item.videos && pipe(
      map(values),
      map(head),
    )(item.videos);

    return (
      <div className={classes.exerciseListItem}>
        {videoData.map(video => (
          <Grid container item key={video.uid} direction="row" justify="center" xs={12}>
            <Grid xs={3}>
              {video.illustration && (
                <img
                  alt={video.title}
                  src={video.illustration}
                  className={classes.exercisePreview}
                />
              )}
            </Grid>
            <Grid xs={9}>
              <Typography>
                {`Title: ${video.title}`}
              </Typography>
              <Typography>
                {`Country: ${startCase(toUpper(item.country))}`}
              </Typography>
              <Typography>
                {`Language: ${toUpper(video.language)}`}
              </Typography>
            </Grid>
          </Grid>
        ))}
      </div>
    );
  }


  render() {
    const {
      match,
      classes,
      languages,
      regions,
      countries,
      videos,
      role,
    } = this.props;

    const {
      addingVideo,
      categories,
      editingVideo,
      isLoading,
      filters,
      selectedCategory,
      selectedCategoryName,
      selectedCountry,
      selectedLanguages,
      videosCollection,
      isOpen,
    } = this.state;

    const tabs = [];

    const hasPermissionToCountry = val => regions && includes(val.i18nKey, regions);

    const mappedUniqueCountries = countries && pipe(
      filter(hasPermissionToCountry),
      pluck('languages'),
      flatten,
      uniq,
    )(countries);

    const hasPermissionToLanguage = lang => mappedUniqueCountries
      && includes(lang.value, mappedUniqueCountries);

    const findLanguages = countryName => countryName && pipe(
      filter(propEq('i18nKey', countryName)),
      head,
      prop('languages'),
    )(countries);

    const languageExistInCountry = (lang) => {
      if (editingVideo) {
        return selectedCountry && includes(lang.value, findLanguages(selectedCountry));
      }
      return selectedCountry
          && includes(lang.value, selectedCountry.map(item => findLanguages(item)).flat());
    };

    const mappedCountries = countries && pipe(
      filter(hasPermissionToCountry),
      map(pick(['languages', 'name'])),
      map(language => ({
        label: language.name,
        value: snakeCase(toLower(language.name)),
      })),
    )(countries);

    const mappedUniqueLanguages = languages && pipe(
      filter(hasPermissionToLanguage),
      filter(languageExistInCountry),
    )(languages);

    const handleChange = (event) => {
      const isChecked = event.target.checked;
      const fieldName = event.target.name;
      this.setState({
        selectedLanguages: isChecked ? {
          ...selectedLanguages,
          [fieldName]: isChecked,
        } : { ...omit([[fieldName]], selectedLanguages) },
        videosCollection: isChecked ? {
          ...videosCollection,
        } : { ...omit([[fieldName]], videosCollection) },
      });
    };

    const checkNumberOfSupportedLanguages = languageValue => pipe(
      filter(propEq('i18nKey', languageValue)),
      head,
      pick(['languages']),
      values,
      head,
      length,
    )(countries);

    const pickInitLanguageForOneLanguageOnly = languageCode => pipe(
      filter(propEq('value', languageCode)),
      head,
      pick(['label']),
      values,
      head,
    )(languages);

    const getSupportedLanguage = languageValue => pipe(
      filter(propEq('i18nKey', languageValue)),
      head,
      pick(['languages']),
      values,
      head,
      head,
      pickInitLanguageForOneLanguageOnly,
    )(countries);

    _.each(categories, (category) => {
      tabs.push((<Tab label={i18n.t(category.i18nKey)} value={category.id} key={category.id} />));
    });

    const filterByLanguage = languages && pipe(
      map(language => ({
        label: startCase(language.label),
        value: snakeCase(toLower(language.label)),
      })),
    )(languages);

    const filterByCountry = countries && pipe(
      map(country => ({
        label: country.name,
        value: snakeCase(toLower(country.name)),
      })),
    )(countries);

    const renderVideoList = () => {
      const selectVideosByUserRole = videos && pipe(
        filter(propEq('categoryId', selectedCategory)),
        filter(item => includes(item.country, regions)),
      )(videos);
      const basicFiltering = () => {
        switch (true) {
          case !isUndefined(filters.country) && !isUndefined(filters.language):
            return pipe(
              filter(propEq('country', filters.country)),
              filter(video => includes(filters.language, video.languages)),
            )(selectVideosByUserRole);
          case !isUndefined(filters.country) && isUndefined(filters.language):
            return pipe(
              filter(propEq('country', filters.country)),
            )(selectVideosByUserRole);
          case isUndefined(filters.country) && !isUndefined(filters.language):
            return pipe(
              filter(video => includes(filters.language, video.languages)),
            )(selectVideosByUserRole);
          default:
            return selectVideosByUserRole;
        }
      };
      return basicFiltering();
    };

    return (
      <Fragment>
        <Paper className={classes.paper} elevation={4}>
          <Tabs
            value={selectedCategory}
            onChange={(event, value) => {
              this.setState({
                selectedCategory: value,
                selectedCategoryName: pipe(
                  filter(propEq('id', value)),
                  head,
                  prop('i18nKey'),
                )(categories),
              }, () => { this.resetForm(); });
            }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
          >
            {tabs}
          </Tabs>
          <Grid container item xs={12} justify="center">
            <Box margin={3}>
              <Fab
                disabled={editingVideo}
                color={!isOpen ? 'primary' : 'secondary'}
                aria-label={!isOpen ? 'add' : 'remove'}
                onClick={() => {
                  this.setState({
                    isOpen: !isOpen,
                    addingVideo: !addingVideo,
                  });
                  // eslint-disable-next-line no-unused-expressions
                  isOpen && this.resetForm();
                }}
              >
                {isOpen ? <RemoveIcon /> : <AddIcon />}
              </Fab>
            </Box>
          </Grid>
          {isOpen && (
            <form className={classes.container} autoComplete="off">
              <Grid container spacing={8} justify="space-between">
                <Grid
                  container
                  item
                  xs={12}
                  justify="center"
                >
                  <Typography variant="h4" component="h4">
                    {`${i18n.t(editingVideo ? 'edit_video' : 'add_video')} for ${i18n.t(selectedCategoryName)}`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid xs={4}>
                    {mappedCountries && (
                      <I18nSelect
                        i18nLabelKey="country"
                        value={selectedCountry}
                        multiple={!editingVideo}
                        onChange={(value) => {
                          const formattedValue = snakeCase(value);

                          const markSelectedLanguage = value
                            .map(item => checkNumberOfSupportedLanguages(item)).includes(1)
                            ? {
                              [
                              getSupportedLanguage(value
                                .find(item => checkNumberOfSupportedLanguages(item)))]: true,
                            } : null;

                          this.setState(prevState => ({
                            selectedLanguages: {
                              ...prevState.selectedLanguages,
                              ...markSelectedLanguage,
                            },
                            selectedCountry: editingVideo ? formattedValue : value,
                            videosCollection: {},
                          }));
                        }}
                        options={mappedCountries}
                      />
                    )}
                  </Grid>
                  <Grid
                    alignItems="center"
                    container
                    direction="row"
                    xs={8}
                  >
                    {!isEmpty(selectedCountry) && mappedUniqueLanguages.map(lang => (
                      <Grid alignItems="column">
                        <Grid
                          container
                          xs={12}
                          alignItems="center"
                          direction="row"
                          style={{
                            marginTop: 10,
                            marginRight: 30,
                          }}
                        >
                          {mappedUniqueLanguages.length > 1 && (
                            <Box>
                              <FormControlLabel
                                control={(
                                  <Switch
                                    disabled={length(mappedUniqueLanguages) === 1}
                                    checked={selectedLanguages[lang.label]}
                                    onChange={handleChange}
                                    name={lang.label}
                                    color="primary"
                                  />
                                )}
                                label={lang.label}
                              />
                            </Box>
                          )}
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  spacing={16}
                  xs={12}
                >
                  <Grid item container direction="row" spacing={16} alignItems="center" xs={12}>
                    {mappedUniqueLanguages.map((language) => {
                      const isSelected = prop(language.label, selectedLanguages) === true;
                      return isSelected && (
                        <Card
                          elevation={3}
                          key={language.uid}
                          className={classes.languageCard}
                          id={language.uid}
                        >
                          <Grid
                            item
                            xs={12}
                            container
                            alignContent="center"
                            direction="row"
                            justify="center"
                          >
                            <I18nTypography
                              i18nKey={language.label}
                              variant="title"
                              style={{
                                textTransform: 'uppercase',
                              }}
                            />
                          </Grid>
                          <Grid container xs={12}>
                            <Grid item xs={10}>
                              <I18nTextField
                                i18nLabelKey="video_url"
                                value={propOr('', 'url', videosCollection[language.label])}
                                onChange={(value) => {
                                  // eslint-disable-next-line
                                  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
                                  const regMatch = value.match(regExp);
                                  // eslint-disable-next-line
                                  const id = (regMatch && regMatch[7].length === 11) ? regMatch[7] : false;
                                  this.setState({
                                    isLoading: false,
                                    videosCollection: {
                                      ...videosCollection,
                                      [language.label]: {
                                        url: value,
                                        videoId: id,
                                        title: '',
                                        language: language.label,
                                      },
                                    },
                                  });
                                }}
                                flex
                              />
                            </Grid>
                            {isLoading && (
                              <Grid container item xs={2} alignItems="center" justify="center">
                                <ClipLoader
                                  loading={isLoading}
                                  sizeUnit="px"
                                  size="35"
                                  color="red"
                                />
                              </Grid>
                            )}
                          </Grid>
                          <Grid container xs={12} direction="row" alignItems="bottom">
                            <Grid item xs={8}>
                              <I18nTextField
                                i18nLabelKey={length(mappedUniqueLanguages) === 1 ? 'title' : `title ${language.label}`}
                                value={propOr('', 'title', videosCollection[language.label])}
                                onChange={value => this.setState({
                                  videosCollection: {
                                    ...videosCollection,
                                    [language.label]: {
                                      ...videosCollection[language.label],
                                      title: value,
                                    },
                                  },
                                })}
                                flex
                              />
                            </Grid>
                            <Grid item xs={4}>
                              <I18nButton
                                raised
                                className={classes.saveButton}
                                onClick={() => {
                                  this.setState({
                                    videosCollection: {
                                      ...videosCollection,
                                      [language.label]: {
                                        ...videosCollection[language.label],
                                        title: videosCollection[language.label].youtubeVideoTitle,
                                      },
                                    },
                                  });
                                }}
                                i18nKey="use_youtube_video_title"
                                disabled={
                                  !propOr('', 'youtubeVideoTitle', videosCollection[language.label])
                                  || equals(propOr('', 'youtubeVideoTitle', videosCollection[language.label]), propOr('', 'title', videosCollection[language.label]))
                                  || propOr('', 'isLoading', videosCollection[language.label])
                                }
                              />
                            </Grid>
                          </Grid>
                          <Grid>
                            <Box marginTop={2} marginBottom={2}>
                              {videosCollection[language.label]
                              && this.renderVideo(videosCollection[language.label])}
                            </Box>
                          </Grid>
                        </Card>
                      );
                    })}
                  </Grid>
                  <Grid container direction="row" justify="center" spacing={0} xs={12}>
                    <Grid item xs={4}>
                      <Box margin={4}>
                        {this.saveOrEditButton(match.params.action, true)}
                      </Box>
                    </Grid>
                    {editingVideo && (
                      <Grid item xs={4}>
                        <Box margin={4}>
                          <I18nButton
                            raised
                            className={classes.saveButton}
                            onClick={() => {
                              this.setState({
                                isOpen: !isOpen,
                                editingVideo: false,
                              });
                              this.resetForm();
                            }}
                            i18nKey="cancel"
                          />
                        </Box>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </form>
          )}
          <Grid
            container
            item
            spacing={16}
            xs={12}
            className={classes.dragContainer}
          >
            <Grid
              item
              container
              xs={12}
              alignItems="center"
              justify="center"
            >
              <Box marginBottom={3}>
                <Typography variant="h4" component="h4">
                  {selectedCategoryName && (`Video collection ${i18n.t(selectedCategoryName)}`)}
                </Typography>
              </Box>
            </Grid>
            <Grid container item xs={12} justify="center" alignContent="center">
              {(role === 'admin' || role === 'windhund_admin' || role === 'super_admin') && (
                <Fragment>
                  <Grid
                    item
                    container
                    xs={3}
                    alignItems="center"
                    justify="center"
                  >
                    <Typography variant="h6" component="h6">
                      {i18n.t('selectedFilters')}
                    </Typography>
                  </Grid>
                  <Grid xs={4}>
                    {filterByCountry && (
                      <I18nSelect
                        i18nLabelKey="country"
                        value={filters.country}
                        onChange={(value) => {
                          this.setState({
                            filters: {
                              ...filters,
                              country: value,
                            },
                          });
                        }}
                        options={filterByCountry}
                      />
                    )}
                  </Grid>
                  <Grid xs={4}>
                    {filterByLanguage && (
                      <I18nSelect
                        i18nLabelKey="language"
                        value={filters.language}
                        onChange={(value) => {
                          this.setState({
                            filters: {
                              ...filters,
                              language: value,
                            },
                          });
                        }}
                        options={filterByLanguage}
                      />
                    )}
                  </Grid>
                  <Grid xs={1} container justify="center" alignContent="center">
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        this.setState({ filters: {} });
                      }}
                    >
                      <RotateLeftIcon />
                    </IconButton>
                  </Grid>
                </Fragment>
              )}
              <Grid xs={12}>
                {videos && (
                  <DraggableList
                    items={renderVideoList(filters)}
                    renderItem={item => this.renderVideoListItem(item)}
                    reorderCallback={videosInNewOrder => this.setReorderVideos(videosInNewOrder)}
                    className={classes.draggableList}
                    onDelete={item => this.handleClickDeleteVideo(item)}
                    onEdit={item => this.editVideo(item)}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Fragment>
    );
  }
}

AddOrEditVideos.propTypes = {
  addNewVideo: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  countries: PropTypes.array.isRequired,
  deleteVideo: PropTypes.func.isRequired,
  getCountries: PropTypes.func.isRequired,
  getLanguages: PropTypes.func.isRequired,
  getUserDetails: PropTypes.func.isRequired,
  getVideoCategories: PropTypes.func.isRequired,
  getVideos: PropTypes.func.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  match: PropTypes.object.isRequired,
  regions: PropTypes.array.isRequired,
  role: PropTypes.string.isRequired,
  updateVideos: PropTypes.func.isRequired,
  videos: PropTypes.array.isRequired,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 800,
    minWidth: 400,
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
    marginTop: 20,
  },
  languageCard: {
    width: '100%',
    padding: 15,
    marginTop: 10,
    marginBottom: 10,
  },
  buttonGrid: {
    position: 'relative',
  },
  saveButton: {
    margin: 'auto',
    position: 'relative',
    display: 'block',
  },
  textField: {
    width: '90%',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  exercisePreview: {
    height: 100,
  },
  exerciseList: {
    marginTop: '20px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  exerciseListItem: {
    position: 'relative',
  },
  exerciseListTitle: {
    position: 'absolute',
    margin: '12px 30px',
    fontSize: '130%',
  },
  draggableList: {
    backgroundColor: 'red',
  },
  dragContainer: {
    marginTop: 15,
  },
});


export default withStyles(styles)(AddOrEditVideos);
