import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withRouter } from 'react-router-dom';
import Table from '../../components/table';

import { realtimeStorage } from '../../helpers/firebaseDatabase';
import i18n from '../../helpers/i18n';

const columnData = [
  {
    id: 'uid',
    numeric: false,
    disablePadding: false,
    i18nKey: 'uid',
  },
  {
    id: 'category',
    numeric: false,
    disablePadding: false,
    i18nKey: 'category',
  },
  {
    id: 'screen',
    numeric: false,
    disablePadding: false,
    i18nKey: 'screen_name',
  },
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    i18nKey: 'image',
    valueCallback: (value) => {
      if (value) {
        return <img style={{ height: 120 }} alt="img" src={value} />;
      }
      return null;
    },
  },
];

const Assets = (props) => {
  const {
    history,
  } = props;

  const [loading, setLoading] = useState(false);
  const [assets, setAssets] = useState([]);

  useEffect(() => {
    fetchData()
      .then(r => r)
      .catch(e => e);
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      realtimeStorage.getOnce('assets')
        .then(res => setAssets(res))
        .catch(() => setAssets([]));
    } finally {
      setLoading(false);
    }
  };

  const handleAssetDelete = async () => {
    alert('You can\'t delete products');
  };

  const toolbarItems = [{
    tooltipLabel: i18n.t('show_entry'),
    onClick: (selected) => {
      history.push(`/assets/${selected[0].uid}`);
    },
    icon: (<VisibilityIcon />),
  }];

  return (
    <Grid zeroMinWidth container direction="row">
      <Box width="100%">
        <Table
          isLoading={loading}
          data={assets}
          columns={columnData}
          allowAddItem={false}
          onDeleteItems={handleAssetDelete}
          toolbarItems={toolbarItems}
          {...props}
        />
      </Box>
    </Grid>
  );
};

Assets.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Assets);
