import React, { Component } from 'react';
import PropTypes from 'prop-types';

import EditIcon from '@material-ui/icons/Edit';

import { realtimeStorage } from '../helpers/firebaseDatabase';

import Table from '../components/table';

import i18n from '../helpers/i18n';

const columnData = [
  {
    id: 'illustration',
    numeric: false,
    disablePadding: false,
    i18nKey: 'illustration',
    valueCallback: (value) => {
      if (value && value.url) {
        return (<img style={{ height: 70 }} alt="img" src={(value.url)} />);
      }
      return null;
    },
  },
  {
    id: 'title',
    key: 'title_de',
    numeric: false,
    disablePadding: false,
    textObject: true,
    language: 'de',
    i18nKey: 'title_de',
  },
  {
    id: 'title',
    key: 'title_en',
    numeric: false,
    disablePadding: false,
    textObject: true,
    language: 'en',
    i18nKey: 'title_en',
  },
];

class Sports extends Component {
  static deleteSports(sportIds) {
    realtimeStorage.delete('sports', sportIds);
  }

  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentWillMount() {
    this.unsubscribe = realtimeStorage.getOn('sports', (val) => {
      this.setState({ data: val });
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const { data } = this.state;
    const { history } = this.props;
    return (
      <div>
        <Table
          data={data}
          columns={columnData}
          onDeleteItems={(items) => {
            realtimeStorage.delete('sports', items);
          }}
          toolbarItems={[{
            tooltipLabel: i18n.t('edit'),
            onClick: (selected) => {
              history.push(`/sports/${selected[0].id}/edit`);
            },
            icon: (<EditIcon />),
          }]}
          {...this.props}
        />
      </div>
    );
  }
}

Sports.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Sports;
