import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import moment from 'moment';

import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import EnhancedTableHead from './enhancedTableHead';
import EnhancedTableToolbar from './enhancedTableToolbar';

import i18n from '../../helpers/i18n';
import Spinner from '../Spinner';

const promptStyle = {
  textAlign: 'center',
  width: '500px',
  padding: '40px',
  // backgroundColor: '#28bae6',
  backgroundColor: 'rgb(118,166,75)',
  boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
  color: '#fff',
};

const promptButtonStyle = {
  width: '160px',
  padding: '10px',
  border: '1px solid #fff',
  margin: '10px',
  cursor: 'pointer',
  background: 'none',
  color: '#fff',
  fontSize: '14px',
};

class myTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      order: 'asc',
      orderBy: 'id',
      selected: [],
      data: props.data,
      page: 0,
      rowsPerPage: 25,
    };
  }

  componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    if (data !== nextProps.data) {
      this.setState({
        data: nextProps.data,
        selected: [],
      });
    }
  }

  handleRequestSort(event, property) {
    /* eslint-disable*/
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    const data = order === 'desc'
      ? this.state.data.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
      : this.state.data.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({
      data,
      order,
      orderBy
    });
    /* eslint-enable */
  }

  // eslint-disable-next-line class-methods-use-this
  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  handleClick(event, item) {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(item);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, item);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    this.setState({ selected: newSelected });
    this.scrollToTop();
  }


  handleSelectAllClick(event, checked) {
    const { data } = this.state;
    if (checked) {
      this.setState({ selected: data });
      this.scrollToTop();
      return;
    }
    this.setState({ selected: [] });
  }

  isSelected(item) {
    const { selected } = this.state;
    return selected.indexOf(item) !== -1;
  }

  handleChangePage(event, page) {
    this.setState({ page });
  }

  handleChangeRowsPerPage(event) {
    this.setState({ rowsPerPage: event.target.value });
  }

  handleAddNewItem() {
    const { location, history } = this.props;
    const currentPath = location.pathname;
    const newPath = currentPath.slice(-1) === '/' ? `${currentPath}add` : `${currentPath}/add`;
    history.push(newPath);
  }

  handleDeleteItems() {
    const { selected } = this.state;
    const { onDeleteItems } = this.props;

    confirmAlert({
      customUI: ({ onClose }) => (
        <div
          className="custom-ui"
          style={promptStyle}
        >
          <h1>
            {i18n.t('sure_question')}
          </h1>
          <p>
            {i18n.t('delete_item_question')}
          </p>
          <div
            style={{
              justifyContent: 'center',
              display: 'flex',
            }}
          >
            <button
              style={promptButtonStyle}
              onClick={() => {
                onDeleteItems(selected);
                onClose();
              }}
              type="submit"
            >
              {i18n.t('yes')}
            </button>
            <button
              style={promptButtonStyle}
              onClick={onClose}
              type="submit"
            >
              {i18n.t('no')}
            </button>
          </div>
        </div>
      ),
    });
    this.setState({ selected: [] });
  }

  renderTableCell(n, column) {
    const { classes } = this.props;
    if (column.id === 'image') {
      return (
        <TableCell key={column.key || column.id} numeric={column.numberic}>
          <img
            className={classes.image}
            alt="img"
            src={n[column.id]}
          />
        </TableCell>
      );
    }
    let content = n[column.id];

    if (column.valueCallback) {
      content = column.valueCallback(content);
    }
    if (column.timestamp && content) {
      content = moment(content)
        .format('LLL');
    }

    if (column.textObject) {
      return (
        <TableCell key={column.key || column.id} numeric={column.numberic}>
          {content[column.language]}
        </TableCell>
      );
    }
    if (column.published) {
      const i18nKey = content ? 'published' : 'not_published';
      return (
        <TableCell key={column.key || column.id} numeric={column.numberic}>
          {i18n.t(i18nKey)}
        </TableCell>
      );
    }
    return (
      <TableCell key={column.key || column.id} numeric={column.numberic}>
        {content}
      </TableCell>
    );
  }

  render() {
    const {
      classes,
      columns,
      title,
      allowAddItem,
      toolbarItems,
      i18nKey,
      isLoading,
      keyColumn,
    } = this.props;
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
    } = this.state;

    return (
      <div className={classes.wrapper}>
        <Paper className={classes.root}>
          <EnhancedTableToolbar
            title={
              (i18nKey)
                ? i18n.t(i18nKey)
                : title
            }
            numSelected={selected.length}
            handleAddItem={() => this.handleAddNewItem()}
            allowAddItem={allowAddItem}
            toolbarItems={toolbarItems}
            selected={selected}
            onDeleteItems={() => this.handleDeleteItems()}
          />
          <Table className={classes.table}>
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={(event, checked) => this.handleSelectAllClick(event, checked)}
              onRequestSort={(event, property) => this.handleRequestSort(event, property)}
              rowCount={data.length}
              columnData={columns}
            />
            {isLoading && (
            <div style={{
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: 'calc(100% - 56px)',
              backgroundColor: 'rgba(0, 0, 0, 0.2)',
            }}
            >
              <div>
                <Spinner />
                <div>
                  <p>
                    {i18n.t('loading_wait')}
                  </p>
                </div>
              </div>
            </div>
            )}
            <TableBody className={classes.tableBody}>
              {data.slice(page * rowsPerPage, (page * rowsPerPage) + rowsPerPage)
                .map((n) => {
                  const isSelected = this.isSelected(n);
                  return (
                    <TableRow
                      hover
                      onClick={event => this.handleClick(event, n)}
                      // onKeyDown={event => this.handleKeyDown(event, n)}
                      role="checkbox"
                      aria-checked={isSelected}
                      tabIndex={-1}
                      key={n[keyColumn]}
                      selected={isSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox color="primary" checked={isSelected} />
                      </TableCell>
                      {columns.map(column => this.renderTableCell(n, column))}
                    </TableRow>
                  );
                })}
              {data.length === 0 && !isLoading && (
                <div style={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: 'calc(100% - 56px)',
                  backgroundColor: 'rgba(0, 0, 0, 0.2)',
                }}
                >
                  <p>
                    {i18n.t('no_elements')}
                  </p>
                </div>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  count={data.length}
                  rowsPerPage={rowsPerPage}
                  labelRowsPerPage={i18n.t('rows_per_page')}
                  labelDisplayedRows={({ from, to, count }) => `${from}-${to} ${i18n.t('of')} ${count}`}
                  page={page}
                  rowsPerPageOptions={[25, 50, 100, 200, 500]}
                  onChangePage={(event, currentPage) => this.handleChangePage(event, currentPage)}
                  onChangeRowsPerPage={event => this.handleChangeRowsPerPage(event)}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Paper>
      </div>
    );
  }
}

myTable.defaultProps = {
  allowAddItem: true,
  keyColumn: 'id',
};

myTable.propTypes = {
  allowAddItem: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array,
  data: PropTypes.array,
  history: PropTypes.object,
  i18nKey: PropTypes.string,
  isLoading: PropTypes.bool,
  keyColumn: PropTypes.string,
  location: PropTypes.object,
  onDeleteItems: PropTypes.func,
  title: PropTypes.string,
  toolbarItems: PropTypes.array,
};


const styles = theme => ({
  root: {
    width: '100%',
    marginTop: theme.spacing.unit * 3,
  },
  table: {
    minHeight: 250,
    minWidth: 400,
    position: 'relative',
  },
  tableBody: { wordBreak: 'break-word' },
  tableWrapper: { overflowX: 'auto' },
  wrapper: { marginTop: -24 },
  image: { height: 45 },
});

export default withStyles(styles)(myTable);
