import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';

import {
  equals,
  includes,
  isEmpty,
  length,
  map,
  pipe,
  values,
} from 'ramda';

import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import Paper from '@material-ui/core/Paper';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';

import { makeStyles } from '@material-ui/core/styles';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import i18n from '../../../helpers/i18n';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  editBox: {
    padding: 30,
  },
  formBox: {
    padding: 30,
    margin: 15,
  },
  saveButton: {
    marginLeft: 10,
    marginRight: 10,
  },
  deleteButton: {
    backgroundColor: '#df0024',
    color: 'white',
    marginLeft: 10,
    marginRight: 10,
    '&:hover': {
      color: 'grey',
    },
  },
});

const LanguagesTable = ({
  row,
  updateLanguages,
  deleteLanguage,
}) => {
  const [open, setOpen] = useState(false);
  const [modalManager, setModalManager] = useState(false);

  const classes = useRowStyles();

  const [initValues] = useState({
    name: row.label,
    i18nKey: row.value,
    uid: row.uid,
  });

  const [formValues, setNewFormValues] = useState(initValues);

  const disabled = useMemo(() => (equals(values(initValues), values(formValues)))
    || length(formValues.i18nKey) !== 2
    || pipe(values, map(isEmpty), includes(true))(formValues),
  [initValues, formValues]);

  const handleChange = useCallback(prop => event => setNewFormValues({
    ...formValues,
    [prop]: event.target.value,
  }), [setNewFormValues]);

  const handleSubmit = useCallback(() => updateLanguages({
    uid: formValues.uid,
    language: formValues,
  }), [updateLanguages, formValues]);

  const handleDelete = useCallback(() => deleteLanguage({
    id: formValues.uid,
    language: formValues,
  }), [deleteLanguage, formValues]);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">{row.uid}</TableCell>
        <TableCell align="right">{row.label}</TableCell>
        <TableCell align="right">{row.value}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={5}>
              <Paper elevation={3}>
                <form className={classes.root} noValidate autoComplete="off">
                  <Grid
                    container
                    direction="row"
                    justify="space-around"
                    alignItems="baseline"
                    className={classes.formBox}
                  >
                    <div>
                      <TextField
                        error={isEmpty(formValues.name)}
                        required
                        id={row.label}
                        label={i18n.t('required')}
                        defaultValue={row.label}
                        helperText={i18n.t('language_name')}
                        onChange={handleChange('name')}
                      />
                    </div>
                    <div>
                      <TextField
                        error={isEmpty(formValues.i18nKey)}
                        required
                        id={row.value}
                        label={i18n.t('required')}
                        defaultValue={row.value}
                        onChange={handleChange('i18nKey')}
                        helperText={i18n.t('language_shortcut')}
                      />
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    className={classes.editBox}
                  >
                    <Box>
                      <Button
                        disabled={disabled}
                        variant="contained"
                        color="primary"
                        onClick={() => handleSubmit()}
                        className={classes.saveButton}
                        startIcon={<SaveIcon />}
                      >
                        {i18n.t('update_in_db')}
                      </Button>
                    </Box>

                    <Box>
                      <Button
                        variant="contained"
                        color="secondary"
                        className={classes.deleteButton}
                        onClick={() => setModalManager(!modalManager)}
                        startIcon={<DeleteIcon />}
                      >
                        {i18n.t('delete_country_from_db', { country: row.label })}
                      </Button>
                    </Box>
                  </Grid>
                </form>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Dialog
        open={modalManager}
        onClose={() => setModalManager(!modalManager)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{i18n.t('delete_language_question')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {i18n.t('delete_language_prompt')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModalManager(!modalManager)} color="primary" variant="contained">
            {i18n.t('no')}
          </Button>
          <Button onClick={() => handleDelete()} color="primary" autoFocus className={classes.deleteButton}>
            {i18n.t('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

LanguagesTable.propTypes = {
  row: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }).isRequired,
  updateLanguages: PropTypes.func.isRequired,
  deleteLanguage: PropTypes.func.isRequired,
};

export default LanguagesTable;
