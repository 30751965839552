import { assoc, prop, sortBy } from 'ramda';
import firebase from '../helpers/firebase';
import { realtimeStorage } from '../helpers/firebaseDatabase';

export const FETCH_COUNTRIES_REQUEST = Symbol('FETCH_COUNTRIES_REQUEST');
export const FETCH_COUNTRIES_SUCCESS = Symbol('FETCH_COUNTRIES_SUCCESS');
export const FETCH_COUNTRIES_FAILURE = Symbol('FETCH_COUNTRIES_FAILURE');

export const UPDATE_COUNTRIES_REQUEST = Symbol('UPDATE_COUNTRIES_REQUEST');
export const UPDATE_COUNTRIES_SUCCESS = Symbol('UPDATE_COUNTRIES_SUCCESS');
export const UPDATE_COUNTRIES_FAILURE = Symbol('UPDATE_COUNTRIES_FAILURE');

export const ADD_NEW_COUNTRY_REQUEST = Symbol('ADD_NEW_COUNTRY_REQUEST');
export const ADD_NEW_COUNTRY_SUCCESS = Symbol('ADD_NEW_COUNTRY_SUCCESS');
export const ADD_NEW_COUNTRY_FAILURE = Symbol('ADD_NEW_COUNTRY_FAILURE');

export const DELETE_COUNTRY_REQUEST = Symbol('DELETE_COUNTRY_REQUEST');
export const DELETE_COUNTRY_SUCCESS = Symbol('DELETE_COUNTRY_SUCCESS');
export const DELETE_COUNTRY_FAILURE = Symbol('DELETE_COUNTRY_FAILURE');

export const fetchCountriesRequest = payload => ({
  type: FETCH_COUNTRIES_REQUEST,
  payload,
});
export const fetchCountriesFailure = payload => ({
  type: FETCH_COUNTRIES_FAILURE,
  payload,
});
export const fetchCountriesSuccess = payload => ({
  type: FETCH_COUNTRIES_SUCCESS,
  payload,
});

export const updateCountriesRequest = payload => ({
  type: UPDATE_COUNTRIES_REQUEST,
  payload,
});
export const updateCountriesFailure = payload => ({
  type: UPDATE_COUNTRIES_FAILURE,
  payload,
});
export const updateCountriesSuccess = payload => ({
  type: UPDATE_COUNTRIES_SUCCESS,
  payload,
});

export const addNewCountryRequest = payload => ({
  type: ADD_NEW_COUNTRY_REQUEST,
  payload,
});
export const addNewCountryFailure = payload => ({
  type: ADD_NEW_COUNTRY_FAILURE,
  payload,
});
export const addNewCountrySuccess = payload => ({
  type: ADD_NEW_COUNTRY_SUCCESS,
  payload,
});

export const deleteCountryRequest = payload => ({
  type: DELETE_COUNTRY_REQUEST,
  payload,
});
export const deleteCountryFailure = payload => ({
  type: DELETE_COUNTRY_FAILURE,
  payload,
});
export const deleteCountrySuccess = payload => ({
  type: DELETE_COUNTRY_SUCCESS,
  payload,
});

export const getCountries = () => async (dispatch) => {
  dispatch(fetchCountriesRequest());
  firebase
    .getInstance()
    .firestore()
    .collection('v1')
    .doc('data')
    .collection('countries')
    .get()
    .then((snapshot) => {
      const result = [];
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.data());
      });
      const countries = result.map(country => ({
        label: country.name,
        value: country.i18nKey,
      }));
      const allCountries = result.map(country => country.i18nKey);
      dispatch(fetchCountriesSuccess({
        countries,
        allCountries,
        adminCountries: [...result],
      }));
    })
    .catch((error) => {
      dispatch(fetchCountriesFailure(error));
    });
};

export const updateCountries = payload => async (dispatch) => {
  dispatch(updateCountriesRequest());
  realtimeStorage
    .update('countries', payload.uid, payload.country)
    .then(() => {
      dispatch(updateCountriesSuccess());
      dispatch(getCountries());
    })
    .catch((error) => {
      dispatch(updateCountriesFailure(error));
    });
};

export const addNewCountry = payload => async (dispatch) => {
  dispatch(addNewCountryRequest());
  realtimeStorage
    .push('countries', payload.country)
    .then((response) => {
      dispatch(updateCountries({
        country: assoc('uid', response.id, payload.country),
        uid: response.id,
      }));
      dispatch(addNewCountrySuccess());
      setTimeout(() => dispatch(getCountries()), 2500);
    })
    .catch((error) => {
      dispatch(addNewCountryFailure(error));
    });
};

export const deleteCountry = payload => async (dispatch) => {
  dispatch(deleteCountryRequest());
  realtimeStorage
    .delete('countries', [payload])
    .then(() => {
      dispatch(deleteCountrySuccess());
      dispatch(getCountries());
    })
    .catch((error) => {
      dispatch(deleteCountryFailure(error));
    });
};

const initState = {
  isLoading: false,
  error: false,
  success: false,
  countries: [],
  allCountries: [],
};

const countriesReducer = (state = initState, action) => {
  switch (action.type) {
    case ADD_NEW_COUNTRY_REQUEST:
    case UPDATE_COUNTRIES_REQUEST:
    case DELETE_COUNTRY_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case FETCH_COUNTRIES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case FETCH_COUNTRIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        countries: action.payload.countries,
        allCountries: action.payload.allCountries,
        adminCountries: sortBy(prop('countryCode'), action.payload.adminCountries),
        error: false,
      };
    case ADD_NEW_COUNTRY_SUCCESS:
    case UPDATE_COUNTRIES_SUCCESS:
    case DELETE_COUNTRY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: false,
        success: true,
      };
    case FETCH_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case UPDATE_COUNTRIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };

    case ADD_NEW_COUNTRY_FAILURE:
    case DELETE_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default (state, action) => [countriesReducer].reduce(
  (newState, currentReducer) => currentReducer(newState, action),
  state,
);
