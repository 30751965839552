import React, { Component } from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';

import { withStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import I18nTypography from '../components/i18nTypography';
import I18nTextField from '../components/i18nTextField';
import I18nButton from '../components/i18nButton';

import Dropzone from '../components/dropZone';
import { timestamp } from '../helpers/firebase';

import { realtimeStorage } from '../helpers/firebaseDatabase';

class AddOrEditVideoCategories extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: null,
      id: null,
      media: null,
      updatedAt: timestamp(),
      index: 0,
      // eslint-disable-next-line react/no-unused-state
      errorMessages: false,
      readOnly: (props.match.params.action === 'show'),
    };
  }

  componentWillMount() {
    const { match } = this.props;
    const promises = [];

    Promise.all(promises).then(() => {
      if (match.params.action === 'edit' || match.params.action === 'show') {
        realtimeStorage.getDoc('videoCategories', match.params.id).then((data) => {
          this.setState({
            i18nKey: data.i18nKey,
            id: data.id,
            index: data.index || 0,
            media: data.media,
            key: data.key || data.i18nKey,
            title: data.title || data.i18nKey,
            updatedAt: data.updatedAt || timestamp,
          });
        });
      }
    });
  }

  getVideoCategories() {
    const {
      title,
      media,
      i18nKey,
      updatedAt,
      index,
      key,
    } = this.state;

    const errorMessages = [];

    if (title === null || title.length === 0 || !title.trim()) {
      errorMessages.push('title_missing');
    }
    return {
      title,
      media,
      updatedAt,
      i18nKey,
      index,
      key,
    };
  }

  saveVideoCategories(goback = true) {
    try {
      const { history } = this.props;
      const videoCategories = this.getVideoCategories();

      if (videoCategories.errors) {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          errorMessages: videoCategories.errorMessages,
        });
      } else {
        realtimeStorage.push('videoCategories', videoCategories).then(() => {
          if (goback) { history.goBack(); }
        });
      }
    } catch (error) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ errorMessages: error.message });
    }
  }

  updateVideoCategories(goback = true) {
    try {
      const { id } = this.state;
      const { history } = this.props;
      const videoCategories = this.getVideoCategories();

      if (videoCategories.errors) {
        this.setState({
          // eslint-disable-next-line react/no-unused-state
          errorMessages: videoCategories.errorMessages,
        });
      } else {
        realtimeStorage.update('videoCategories', id, videoCategories).then(() => {
          if (goback) { history.goBack(); }
        });
      }
    } catch (error) {
      // eslint-disable-next-line react/no-unused-state
      this.setState({ errorMessages: [error.message] });
    }
  }

  saveOrEditButton(action, goBack) {
    const { classes } = this.props;

    return (
      <I18nButton
        raised={1}
        className={classes.saveButton}
        onClick={async () => {
          if (this.illustrationDropzone) {
            await this.illustrationDropzone.uploadImage().then((result) => {
              this.setState({ media: result });
            });
          }
          if (action === 'edit') {
            this.updateVideoCategories(goBack);
          } else {
            this.saveVideoCategories(goBack);
          }
        }
        }
        i18nKey={goBack ? 'save_and_close' : 'save'}
      />
    );
  }

  renderImageOrVideo() {
    const { media, readOnly } = this.state;
    return (
      <Grid item xs={6}>
        <Dropzone
          disabled={readOnly}
          i18nLabelKey="illustration"
          type="illustrations"
          value={media}
          sizes={['600x300', '300x150', '150x75']}
          ref={(ref) => { this.illustrationDropzone = ref; }}
        />
      </Grid>
    );
  }

  render() {
    const {
      match,
      classes,
    } = this.props;
    const {
      title,
      i18nKey,
      key,
      error,
      readOnly,
      index,
    } = this.state;

    let i18nTitleKey;
    if (match.params.action === 'add') {
      i18nTitleKey = 'add_videoCategories';
    } else if (match.params.action === 'edit') {
      i18nTitleKey = 'edit_videoCategories';
    } else if (match.params.action === 'show') {
      i18nTitleKey = 'show_videoCategories';
    }

    return (
      <Paper className={classes.paper} elevation={4}>
        <form className={classes.container} autoComplete="off">
          <Grid container spacing={16} justify="space-between">
            <Grid item xs={12}>
              <Grid container direction="row" justify="flex-start" alignItems="flex-start">
                <Grid item xs={6}>
                  <I18nTypography variant="title" i18nKey={i18nTitleKey} />
                </Grid>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              xs={12}
              direction="column"
            >
              <Grid item xs={8}>
                <I18nTextField
                  disabled={readOnly}
                  i18nLabelKey="translation_key"
                  value={i18nKey}
                  onChange={value => this.setState({ i18nKey: value })}
                  flex
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              xs={12}
              direction="column"
            >
              <Grid item xs={8}>
                <I18nTextField
                  disabled={readOnly}
                  i18nLabelKey="index"
                  value={index}
                  onChange={value => this.setState({ index: parseInt(value, 0) })}
                  flex
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              xs={12}
              direction="column"
            >
              <Grid item xs={8}>
                <I18nTextField
                  disabled={readOnly}
                  i18nLabelKey="title"
                  value={title}
                  onChange={value => this.setState({ title: value })}
                  flex
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              xs={12}
              direction="column"
            >
              <Grid item xs={8}>
                <I18nTextField
                  disabled={readOnly}
                  i18nLabelKey="key"
                  value={key}
                  onChange={value => this.setState({ key: camelCase(value) })}
                  flex
                />
              </Grid>
            </Grid>
            <Grid
              container
              spacing={16}
              xs={12}
              // justify="center"
              alignItems="center"
            >
              {this.renderImageOrVideo()}
            </Grid>
            {(!readOnly)
              && (
                <Grid
                  container
                  direction="row"
                >
                  {this.saveOrEditButton(match.params.action, true)}
                </Grid>
              )
            }
            <div className={classes.formControl}>
              <I18nTypography>{error}</I18nTypography>
            </div>
          </Grid>
        </form>
      </Paper>
    );
  }
}

AddOrEditVideoCategories.propTypes = {
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 1000,
    minWidth: 400,
  }),
  container: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  buttonGrid: {
    position: 'relative',
  },
  saveButton: {
    // margin: 'auto',
    margin: 10,
    position: 'relative',
    display: 'block',
  },
  textField: {
    width: '90%',
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  exercisePreview: {
    height: 100,
  },
  exerciseList: {
    marginTop: '20px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  },
  exerciseListItem: {
    position: 'relative',
  },
  exerciseListTitle: {
    position: 'absolute',
    margin: '12px 30px',
    fontSize: '130%',
  },
  switch: {
    margin: '0 15px',
  },
  formControl: {
    marginTop: 16,
    marginBottom: 16,
    minWidth: 120,
  },
  // youtubePlayer: {
  //   width: 100,
  // }
});


export default withStyles(styles)(AddOrEditVideoCategories);
