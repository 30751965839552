// eslint-disable-next-line import/prefer-default-export
export const selectAllUsers = state => state.admins.allUsers;
export const selectAllUsersWithAccount = state => state.admins.allUsersWithAccount;
export const selectIncognitoTypeCount = state => state.admins.incognitoTypeCount;
export const selectAllUsersCount = state => state.admins.allUsersCount;
export const selectError = state => state.admins.error;
export const selectHasData = state => state.admins.hasData;
export const selectIsLoading = state => state.admins.isLoading;
export const selectSuccess = state => state.admins.success;
export const selectUnverifiedUsers = state => state.admins.unverifiedUsers;
export const selectUsersAccountType = state => state.admins.accountType;
export const selectUsersIncognitoType = state => state.admins.incognitoType;
export const selectVerifiedUsers = state => state.admins.verifiedUsers;
