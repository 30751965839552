import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

// reducers
import {
  getLanguages, updateLanguages, addNewLanguage, deleteLanguage,
} from '../ducks/languages';
import {
  getCountries, updateCountries, addNewCountry, deleteCountry,
} from '../ducks/countries';

// selectors
import {
  hasError,
  selectIsLoading,
  selectAdminCountries,
} from '../selectors/countries';

import { selectLanguages, selectSuccess } from '../selectors/languages';

import { Management } from '../pages';

const mapStateToProps = state => ({
  countries: selectAdminCountries(state),
  languages: selectLanguages(state),
  isLoading: selectIsLoading(state),
  error: hasError(state),
  success: selectSuccess(state),
});

const mapDispatchToProps = dispatch => ({
  getCountries: async () => {
    await dispatch(getCountries());
  },
  getLanguages: async () => {
    await dispatch(getLanguages());
  },
  updateLanguages: async (payload) => {
    await dispatch(updateLanguages(payload));
  },
  updateCountries: async (payload) => {
    await dispatch(updateCountries(payload));
  },
  addNewLanguage: async (payload) => {
    await dispatch(addNewLanguage(payload));
  },
  addNewCountry: async (payload) => {
    await dispatch(addNewCountry(payload));
  },
  deleteLanguage: async (payload) => {
    await dispatch(deleteLanguage(payload));
  },
  deleteCountry: async (payload) => {
    await dispatch(deleteCountry(payload));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Management));
