import { createStore, applyMiddleware } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from '../ducks';

class ReduxStoreConfigurator {
  constructor(history) {
    this.middlewares = [thunk, routerMiddleware(history)];

    if (process.env.NODE_ENV === 'development') {
      this.middlewares.push(createLogger());
    }
  }

  configureStore(initialState) {
    this.store = createStore(
      rootReducer,
      initialState,
      applyMiddleware(...this.middlewares),
    );

    return this.store;
  }
}

export default ReduxStoreConfigurator;
