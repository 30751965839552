import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as moment from 'moment';

import VisibilityIcon from '@material-ui/icons/Visibility';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

// icons
import SendIcon from '@material-ui/icons/Send';
import CancelScheduleSendIcon from '@material-ui/icons/Clear';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import ScheduleIcon from '@material-ui/icons/Schedule';
import CheckIcon from '@material-ui/icons/Check';

import { withRouter } from 'react-router-dom';
import Table from '../../components/table';
import I18nSelect from '../../components/i18nSelect';

import { realtimeStorage } from '../../helpers/firebaseDatabase';
import firebase from '../../services/FirebaseService';
import i18n from '../../helpers/i18n';

import { globalValue } from './NotificationForm';

import { notificationService } from '../../services/NotificationService';

const PushNotifications = (props) => {
  const {
    history,
    role,
    regions,
  } = props;

  const userTypes = [
    {
      value: 'all',
      label: i18n.t('all_users'),
    },
    {
      value: 'incognito',
      label: i18n.t('user_incognito_only'),
    },
    {
      value: 'email',
      label: i18n.t('user_with_emails_only'),
    },
    {
      value: 'mailingSubscription',
      label: i18n.t('user_mailing_subscription_only'),
    },
  ];
  const columnData = [
    {
      id: 'title',
      numeric: false,
      disablePadding: false,
      i18nKey: 'title',
    },
    {
      id: 'body',
      numeric: false,
      disablePadding: false,
      i18nKey: 'body',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: false,
      i18nKey: 'status',
      valueCallback: (value) => {
        switch (true) {
          case value === 'sending':
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box pr={0.5}>
                  <SendIcon color="primary" />
                </Box>
                <p>
                  {i18n.t('notification_sending')}
                </p>
              </Grid>
            );
          case value === 'pending':
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box pr={0.5}>
                  <SendIcon color="primary" />
                </Box>
                <p>
                  {i18n.t('notification_pending')}
                </p>
              </Grid>
            );
          case value === 'sent':
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box pr={0.5}>
                  <CheckIcon color="primary" />
                </Box>
                <p>
                  {i18n.t('notification_sent')}
                </p>
              </Grid>
            );
          case value === 'scheduled':
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box pr={0.5}>
                  <ScheduleIcon color="primary" />
                </Box>
                <p>
                  {i18n.t('notification_scheduled')}
                </p>
              </Grid>
            );
          case value === 'failed':
            return (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box pr={0.5}>
                  <CancelScheduleSendIcon color="primary" />
                </Box>
                <p>
                  {i18n.t('notification_failed')}
                </p>
              </Grid>
            );
          default:
            return i18n.t(value);
        }
      },
    },
    {
      id: 'sendAt',
      numeric: false,
      disablePadding: true,
      i18nKey: 'send_at',
      timestamp: true,
    },
    {
      id: 'language',
      numeric: false,
      disablePadding: false,
      i18nKey: 'language',
      valueCallback: value => i18n.t(value || ''),
    },
    {
      id: 'userType',
      numeric: false,
      disablePadding: false,
      i18nKey: 'user_type',
      valueCallback: (value) => {
        const foundFilter = userTypes.find(type => type.value === value);
        if (foundFilter) {
          return foundFilter.label;
        }
        return i18n.t('user_all');
      },
    },
    {
      id: 'country',
      numeric: false,
      disablePadding: false,
      i18nKey: 'country',
      valueCallback: value => (value === 'global' ? i18n.t('all_countries') : value),
    },
    {
      id: 'audience',
      numeric: false,
      disablePadding: false,
      i18nKey: 'custom_audience',
    },
  ];

  const notificationStatuses = [
    {
      value: 'all',
      label: i18n.t('notification_all'),
    },
    {
      value: 'pending',
      label: i18n.t('notification_pending'),
    },
    {
      value: 'failed',
      label: i18n.t('notification_failed'),
    },
    {
      value: 'sending',
      label: i18n.t('notification_sending'),
    },
    {
      value: 'scheduled',
      label: i18n.t('notification_scheduled'),
    },
    {
      value: 'sent',
      label: i18n.t('notification_sent'),
    },
  ];

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showAllNotifications, setShowAllNotifications] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(notificationStatuses[0].value);
  const [selectedUserType, setSelectedUserType] = useState(userTypes[0].value);
  const [search, setSearch] = useState('');
  const [sendDateTo, setSendDateTo] = useState({});
  const [sendDateFrom, setSendDateFrom] = useState({});

  const [languages, setLanguages] = useState([]);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    realtimeStorage.getOnce('languages')
      .then((newLanguages) => {
        const languagesSelect = newLanguages.map(language => ({
          value: language.i18nKey,
          label: i18n.t(`notification_${language.i18nKey}`),
        }));
        setLanguages([
          { value: 'all_languages', label: i18n.t('all_languages') },
          ...languagesSelect,
        ]);

        setSelectedLanguage('all_languages');
      });

    fetchData();
  }, []);

  useEffect(() => {
    if (data.length === 0) return;
    const filters = [];
    if (selectedCountry && (!showAllNotifications || selectedCountry !== globalValue)) {
      filters.unshift({
        option: 'country',
        value: selectedCountry,
      });
    }
    if (selectedLanguage && selectedLanguage !== 'all_languages') {
      filters.unshift({
        option: 'language',
        value: selectedLanguage,
      });
    }
    if (selectedUserType && selectedUserType !== 'all') {
      filters.unshift({
        option: 'userType',
        value: selectedUserType,
      });
    }
    if (selectedStatus && selectedStatus !== 'all') {
      filters.unshift({
        option: 'status',
        value: selectedStatus,
      });
    }
    if (search.length > 0) {
      filters.unshift({
        option: 'title',
        value: search,
      });
    }
    if (typeof sendDateTo === 'string') {
      filters.unshift({
        option: 'sendDateTo',
        value: sendDateTo,
      });
    }
    if (typeof sendDateFrom === 'string') {
      filters.unshift({
        option: 'sendDateFrom',
        value: sendDateFrom,
      });
    }
    if (filters.length > 0) {
      setShowAllNotifications(false);
    }
    const newData = data.filter((notification) => {
      const shouldShow = filters.every((filter) => {
        const notificationField = notification[filter.option];
        if (filter.option === 'sendDateTo') {
          return moment(notification.sendAt).startOf('day') <= moment(filter.value);
        }
        if (filter.option === 'sendDateFrom') {
          return moment(notification.sendAt).endOf('day') >= moment(filter.value);
        }
        if (filter.option === 'country') {
          return notification.country === filter.value || notification.country === globalValue;
        }
        if (typeof notificationField === 'string') {
          return notificationField.toLowerCase().includes(filter.value.toString().toLowerCase());
        }
        return notificationField === filter.value;
      });
      return shouldShow;
    });
    setFilteredData(newData);
  }, [
    selectedLanguage,
    selectedCountry,
    selectedUserType,
    search,
    data.length,
    sendDateTo,
    sendDateFrom,
    selectedStatus,
  ]);

  const mapNotificationToNewObject = notification => ({
    audience: notification.recipients,
    body: notification.contents.en,
    country: notification.countryFilter,
    id: notification.id,
    image: notification.big_picture || null,
    language: notification.languageFilter,
    languageFilter: notification.languageFilter,
    sendAt: notification.send_after || new Date(notification.createdAt.seconds * 1000),
    status: notification.status,
    subtitle: notification.subtitle ? notification.subtitle.en : null,
    title: notification.headings.en,
    // eslint-disable-next-line no-nested-ternary
    url: notification.data ? notification.data.web_url : notification.app_url ? notification.app_url.split('//')[1] : null,
    open: notification.data ? !notification.data.openInWeb : true,
    userType: notification.userTypeFilter,
  });

  const fetchData = async () => {
    try {
      setLoading(true);

      // Get and set countries
      const newCountries = await realtimeStorage.getOnce('countries') || [];
      const userCountries = newCountries
        .filter(country => country.hasOwnContent && regions.includes(country.i18nKey));
      const countriesCodes = userCountries.map(country => country.countryCode);
      const countriesSelect = userCountries
        .map(country => ({
          value: country.countryCode,
          label: i18n.t(`notification_${country.i18nKey}`),
        }));

      setCountries([{ value: globalValue, label: 'Global' }, ...countriesSelect]);

      setSelectedCountry(globalValue);

      // Get and set notifications
      const notifications = await realtimeStorage.getOnce(
        'notifications',
        { field: 'createdAt', direction: 'desc' },
      );
      const newData = notifications.map((notification = {}) => mapNotificationToNewObject(
        notification,
      ));
      if (role === 'distributor') {
        setData(newData.filter(notification => (
          countriesCodes.includes(notification.country) || notification.country === globalValue
        )));
      } else {
        setData(newData);
      }
      setFilteredData(newData);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteNotifications = async (items) => {
    try {
      setLoading(true);
      await Promise.all(items.map(item => (
        notificationService.cancelNotification(item.id)
      )));
    } finally {
      setLoading(false);
      fetchData();
    }
  };

  const toolbarItems = [{
    tooltipLabel: i18n.t('show_entry'),
    onClick: (selected) => {
      history.push(`/push_notifications/${selected[0].id}/show`);
    },
    icon: (<VisibilityIcon />),
  }, {
    tooltipLabel: i18n.t('duplicate_notification'),
    onClick: (selected) => {
      props.history.push('/push_notifications/add', selected);
    },
    icon: (<FileCopyIcon />),
  }];

  const toggleShowAllNotifications = () => {
    if (toggleShowAllNotifications) {
      setSelectedCountry(globalValue);
      setSelectedLanguage('all_languages');
      setSelectedUserType(userTypes[0].value);
      setSearch('');
      setSendDateFrom({});
      setSendDateTo({});
      setSelectedStatus(notificationStatuses[0].value);
    }
    setShowAllNotifications(!showAllNotifications);
  };

  const handleSearchPhrase = (event) => {
    setSearch(event.target.value);
  };

  const renderFilters = () => (
    <Grid container direction="row" alignItems="center">
      <Box px="10px" display="flex" flexDirection="column">
        <Typography variant="caption" color="primary" align="left">
          {i18n.t('show_all_notifications')}
        </Typography>
        <Switch
          onChange={toggleShowAllNotifications}
          checked={showAllNotifications}
          color="primary"
        />
      </Box>
      {countries && countries.length > 1 && (
        <I18nSelect
          i18nLabelKey="country"
          value={selectedCountry}
          options={countries}
          onChange={setSelectedCountry}
        />
      )}
      <Grid item>
        <I18nSelect
          i18nLabelKey="language"
          value={selectedLanguage}
          options={languages}
          onChange={setSelectedLanguage}
        />
      </Grid>
      <Grid item>
        <I18nSelect
          i18nLabelKey="status"
          value={selectedStatus}
          options={notificationStatuses}
          onChange={setSelectedStatus}
        />
      </Grid>
      <Grid item>
        <I18nSelect
          i18nLabelKey="user_type"
          value={selectedUserType}
          options={userTypes}
          onChange={setSelectedUserType}
        />
      </Grid>
      <Grid item>
        <Box flexDirection="column" display="flex">
          <Typography variant="caption" color="primary" align="left">
            {`${i18n.t('send_at')} (from): `}
          </Typography>
          <TextField
            id="date-from"
            type="date"
            value={sendDateFrom}
            onChange={(date) => {
              const newDate = moment(date.target.value).format('YYYY-MM-DD');
              setSendDateFrom(newDate);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Grid>
      <Grid item>
        <Box flexDirection="column" display="flex" mx="10px">
          <Typography variant="caption" color="primary" align="left">
            {`${i18n.t('send_at')} (to): `}
          </Typography>
          <TextField
            id="date-to"
            type="date"
            value={sendDateTo}
            onChange={(date) => {
              const newDate = moment(date.target.value).format('YYYY-MM-DD');
              setSendDateTo(newDate);
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Box>
      </Grid>
      <Grid item>
        <TextField
          label={i18n.t('search_text')}
          value={search}
          defaultValue={search}
          id="search"
          onChange={handleSearchPhrase}
        />
      </Grid>
    </Grid>
  );

  useEffect(() => {
    // Moved inside "useEffect" to avoid re-creating on render
    const handleNotificationsChanges = async () => {
      // Get and set notifications
      const notifications = await realtimeStorage.getOnce(
        'notifications',
        { field: 'createdAt', direction: 'desc' },
      );
      const newData = notifications.map(
        (notification = {}) => mapNotificationToNewObject(notification),
      );
      setData(newData);
      setFilteredData(newData);
    };

    const query = firebase()
      .collection('notifications')
      .orderBy('createdAt');

    // Create the DB listener for notifications
    const unsuscribe = query.onSnapshot(handleNotificationsChanges,
      err => console.log(err));
    return () => {
      unsuscribe();
    };
  }, []);

  return (
    <div>
      {renderFilters()}
      <Table
        isLoading={loading}
        data={showAllNotifications ? data : filteredData}
        columns={columnData}
        allowAddItem
        onDeleteItems={handleDeleteNotifications}
        toolbarItems={toolbarItems}
        {...props}
      />
    </div>
  );
};

PushNotifications.propTypes = {
  history: PropTypes.object.isRequired,
  regions: PropTypes.array,
  role: PropTypes.string.isRequired,
};

PushNotifications.defaultProps = {
  regions: [],
};

export default withRouter(PushNotifications);
