import React, {
  Fragment, useCallback, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';

import UserSceneConnector from '../../connectors/userSceneConnector';
import CountriesTable from './components/CountriesTable';
import LanguagesTable from './components/LanguagesTable';
import AddNewLanguage from './components/AddNewLanguage';
import AddNewCountry from './components/AddNewCountry';
import i18n from '../../helpers/i18n';

const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box paddingTop={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Management = ({
  addNewCountry,
  addNewLanguage,
  deleteLanguage,
  deleteCountry,
  countries,
  languages,
  getCountries,
  getLanguages,
  updateLanguages,
  updateCountries,
  success,
}) => {
  useEffect(() => {
    getCountries();
    getLanguages();
  }, [getCountries, getLanguages]);

  const [value, setValue] = useState(0);

  const handleChange = useCallback((event, newValue) => {
    setValue(newValue);
  }, [setValue]);

  return (
    <Fragment>
      <AppBar position="static">
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
          style={{
            backgroundColor: '#e0e0e0',
          }}
        >
          <Tab label={i18n.t('country_management')} {...a11yProps(0)} />
          <Tab label={i18n.t('language_management')} {...a11yProps(1)} />
          <Tab label={i18n.t('users_management')} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell variant="head">UID</TableCell>
                <TableCell align="right" variant="head">{i18n.t('country')}</TableCell>
                <TableCell align="right" variant="head">{i18n.t('own_content')}</TableCell>
                <TableCell align="right" variant="head">{i18n.t('country_code')}</TableCell>
                <TableCell align="right" variant="head">{i18n.t('language_code')}</TableCell>
                <TableCell align="right" variant="head">{i18n.t('translation_key')}</TableCell>
                <TableCell align="right" variant="head">{i18n.t('is_party')}</TableCell>
                <TableCell align="right" variant="head">{i18n.t('url_shop')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countries && countries.map(country => (
                <CountriesTable
                  key={country.name}
                  row={country}
                  updateCountries={updateCountries}
                  deleteCountry={deleteCountry}
                  languages={languages}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AddNewCountry addNewCountry={addNewCountry} languages={languages} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell variant="head">UID</TableCell>
                <TableCell align="right" variant="head">{i18n.t('label')}</TableCell>
                <TableCell align="right" variant="head">{i18n.t('value')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {languages && languages.map(language => (
                <LanguagesTable
                  key={language.value}
                  row={language}
                  updateLanguages={updateLanguages}
                  deleteLanguage={deleteLanguage}
                  success={success}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AddNewLanguage addNewLanguage={addNewLanguage} />
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UserSceneConnector />
      </TabPanel>
    </Fragment>
  );
};

Management.propTypes = {
  countries: PropTypes.arrayOf(
    PropTypes.shape({
      countryCode: PropTypes.string.isRequired,
      hasOwnContent: PropTypes.bool.isRequired,
      i18nKey: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      thirdParty: PropTypes.bool.isRequired,
      webshopURI: PropTypes.string.isRequired,
    }),
  ).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    }),
  ).isRequired,
  getCountries: PropTypes.func.isRequired,
  getLanguages: PropTypes.func.isRequired,
  updateLanguages: PropTypes.func.isRequired,
  updateCountries: PropTypes.func.isRequired,
  addNewLanguage: PropTypes.func.isRequired,
  addNewCountry: PropTypes.func.isRequired,
  deleteLanguage: PropTypes.func.isRequired,
  deleteCountry: PropTypes.func.isRequired,
  success: PropTypes.bool.isRequired,
};

export default Management;
