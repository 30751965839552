import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Route, Switch, Redirect } from 'react-router-dom';

import { MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import MomentUtils from '@date-io/moment';
import AuthService from './services/AuthService';

import AddOrEditExercise from './pages/addOrEditExercise';
import AddOrEditNews from './pages/addOrEditNews';
import AddOrEditSport from './pages/addOrEditSport';
import AddOrEditVideoCategories from './pages/addOrEditVideoCategories';
import AssetDetails from './pages/Assets/AssetDetails';
import Assets from './pages/Assets/AssetsList';
import Exercises from './pages/exercises';
import Loading from './pages/loading';
import News from './pages/news';
import NoAccess from './pages/noAccess';
import NotificationForm from './pages/Notifications/NotificationForm';
import NotificationsList from './pages/Notifications/NotificationsList';
import Product from './pages/Products/Product';
import Products from './pages/Products/ProductsList';
import Sports from './pages/sports';
import VideoCategories from './pages/videoCategories';

import theme from './helpers/muiTheme';
import firebase from './helpers/firebase';

import i18n from './helpers/i18n';


// connectors
import ManagementSceneConnector from './connectors/managementSceneConnector';
import LoginSceneConnector from './connectors/loginSceneConnector';
import VideosSceneConnector from './connectors/videosSceneConnector';
import HeaderConnector from './connectors/headerConnector';
import userAccountsSceneConnector from './connectors/userAccountsSceneConnector';

const authService = new AuthService();

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentUser: null,
      role: null,
      error: '',
    };
  }

  componentWillMount() {
    firebase.init()
      .then(() => {
        firebase.getInstance()
          .auth()
          .onAuthStateChanged(async (user) => {
            if (user) {
              const setEnv = () => {
                const firebaseEnvironmentURL = typeof user.u === 'string' ? user.u : user.o;
                switch (true) {
                  case firebaseEnvironmentURL === 'blackroll-dev.firebaseapp.com':
                    return authService.saveApiUrl('https://us-central1-blackroll-dev.cloudfunctions.net');
                  case firebaseEnvironmentURL === 'blackroll-staging-6355e.firebaseapp.com':
                    return authService.saveApiUrl('https://us-central1-blackroll-staging-6355e.cloudfunctions.net');
                  case firebaseEnvironmentURL === 'blackroll-31904.firebaseapp.com':
                    return authService.saveApiUrl('https://us-central1-blackroll-31904.cloudfunctions.net');
                  default:
                    return authService.saveApiUrl('https://us-central1-blackroll-dev.cloudfunctions.net');
                }
              };
              await setEnv();
              await user.getIdTokenResult(true)
                .then((result) => {
                  if (result.claims.role) {
                    authService.saveToken(result.token);
                    this.setState({
                      role: result.claims.role,
                      regions: result.claims.regions,
                      currentUser: user,
                    });
                  } else {
                    firebase.getInstance()
                      .auth()
                      .signOut();
                    this.setState({ error: i18n.t('error_no_role') });
                  }
                });
            } else {
              this.setState({ currentUser: null });
            }
            this.setState({ isLoading: false });
          });
      });
  }

  render() {
    const {
      isLoading,
      role,
      error,
      regions,
      currentUser,
    } = this.state;
    const { classes } = this.props;

    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MuiThemeProvider theme={theme}>
          <div className={classes.root}>
            <Switch>
              {(isLoading) && (<Route render={() => <Loading />} />)}

              { /*  Route for windhund admin only  */}
              <RoleBasedRoute
                exact
                path="/management"
                allowedRoles={['admin', 'windhund_admin', 'super_admin']}
                component={ManagementSceneConnector}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/products"
                allowedRoles={['super_admin']}
                component={Products}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/products/:sku"
                allowedRoles={['super_admin']}
                component={Product}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/assets"
                allowedRoles={['super_admin']}
                component={Assets}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/assets/:id"
                allowedRoles={['super_admin']}
                component={AssetDetails}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/userAccountsManagement"
                allowedRoles={['super_admin']}
                component={userAccountsSceneConnector}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              { /*  Routes for all admins only  */}
              <RoleBasedRoute
                exact
                path="/exercises"
                allowedRoles={['admin', 'windhund_admin', 'super_admin']}
                component={Exercises}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />

              <RoleBasedRoute
                exact
                path={['/exercises/:action', '/exercises/:id/:action']}
                allowedRoles={['admin', 'windhund_admin', 'super_admin']}
                component={AddOrEditExercise}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />

              <RoleBasedRoute
                exact
                path="/sports"
                allowedRoles={['admin', 'windhund_admin', 'super_admin']}
                component={Sports}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />

              <RoleBasedRoute
                exact
                path={['/sports/:action', '/sports/:id/:action']}
                allowedRoles={['admin', 'windhund_admin', 'super_admin']}
                component={AddOrEditSport}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              { /*  Routes for all roles  */}
              <RoleBasedRoute
                exact
                path="/push_notifications"
                allowedRoles={['distributor', 'admin', 'windhund_admin', 'super_admin']}
                component={NotificationsList}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path={['/push_notifications/:action', '/push_notifications/:id/:action']}
                allowedRoles={['distributor', 'admin', 'windhund_admin', 'super_admin']}
                component={NotificationForm}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/news"
                allowedRoles={['distributor', 'admin', 'windhund_admin', 'super_admin']}
                component={News}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path={['/news/:action', '/news/:id/:action']}
                allowedRoles={['distributor', 'admin', 'windhund_admin', 'super_admin']}
                component={AddOrEditNews}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/videos"
                allowedRoles={['distributor', 'admin', 'windhund_admin', 'super_admin']}
                component={VideosSceneConnector}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path="/videoCategories"
                allowedRoles={['windhund_admin', 'super_admin']}
                component={VideoCategories}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <RoleBasedRoute
                exact
                path={['/videoCategories/:action', '/videoCategories/:id/:action']}
                allowedRoles={['windhund_admin', 'super_admin']}
                component={AddOrEditVideoCategories}
                currentUser={currentUser}
                regions={regions}
                role={role}
              />
              <Route
                exact
                path="/login"
                component={LoginSceneConnector}
                render={(props) => {
                  if (currentUser) {
                    return (<Redirect exact from="/login" to="/news" />);
                  }
                  return (<loginSceneConnector error={error} {...props} />);
                }}
              />
              <Route
                exact
                path="/no_access"
                component={NoAccess}
                currentUser={currentUser}
              />
              <Redirect exact from="/" to="/news" />
            </Switch>
          </div>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    );
  }
}

const RoleBasedRoute = ({
  component: RouteComponent,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) => {
      if (rest.currentUser) {
        if (rest.allowedRoles.includes(rest.role)) {
          return (
            <HeaderConnector {...rest}>
              <RouteComponent {...props} {...rest} />
            </HeaderConnector>
          );
        }
        return (
          <Redirect to={{
            pathname: '/no_access',
            state: { from: props.location },
          }}
          />
        );
      }
      return (
        <Redirect to={{
          pathname: '/login',
          state: { from: props.location },
        }}
        />
      );
    }}
  />
);

RoleBasedRoute.propTypes = {
  component: PropTypes.any,
  role: PropTypes.string,
  history: PropTypes.object,
  location: PropTypes.object,
};

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
});

export default withStyles(styles)(App);
