import { withRouter } from 'react-router-dom';

import { connect } from 'react-redux';
import { authUserWithEmailAndPassword } from '../ducks/user';
import LoginScene from '../pages/login';
import { selectUserDetails, isLoading, hasError } from '../selectors/user';

const mapStateToProps = state => ({
  user: selectUserDetails(state),
  isLoading: isLoading(state),
  error: hasError(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  loginWithEmailAndPassword: async (email, password) => {
    await dispatch(authUserWithEmailAndPassword(email, password, ownProps));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginScene));
