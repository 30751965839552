import { sortBy, prop, assoc } from 'ramda';
import firebase from '../helpers/firebase';
import { realtimeStorage } from '../helpers/firebaseDatabase';

export const FETCH_LANGUAGES_REQUEST = Symbol('FETCH_LANGUAGES_REQUEST');
export const FETCH_LANGUAGES_SUCCESS = Symbol('FETCH_LANGUAGES_SUCCESS');
export const FETCH_LANGUAGES_FAILURE = Symbol('FETCH_LANGUAGES_FAILURE');
export const UPDATE_LANGUAGES_REQUEST = Symbol('UPDATE_LANGUAGES_REQUEST');
export const UPDATE_LANGUAGES_SUCCESS = Symbol('UPDATE_LANGUAGES_SUCCESS');
export const UPDATE_LANGUAGES_FAILURE = Symbol('UPDATE_LANGUAGES_FAILURE');
export const ADD_NEW_LANGUAGE_REQUEST = Symbol('ADD_NEW_LANGUAGE_REQUEST');
export const ADD_NEW_LANGUAGE_SUCCESS = Symbol('ADD_NEW_LANGUAGE_SUCCESS');
export const ADD_NEW_LANGUAGE_FAILURE = Symbol('ADD_NEW_LANGUAGE_FAILURE');
export const DELETE_LANGUAGE_REQUEST = Symbol('DELETE_LANGUAGE_REQUEST');
export const DELETE_LANGUAGE_SUCCESS = Symbol('DELETE_LANGUAGE_SUCCESS');
export const DELETE_LANGUAGE_FAILURE = Symbol('DELETE_LANGUAGE_FAILURE');

export const fetchLanguagesRequest = payload => ({
  type: FETCH_LANGUAGES_REQUEST,
  payload,
});
export const fetchLanguagesFailure = payload => ({
  type: FETCH_LANGUAGES_FAILURE,
  payload,
});
export const fetchLanguagesSuccess = payload => ({
  type: FETCH_LANGUAGES_SUCCESS,
  payload,
});

export const updateLanguagesRequest = payload => ({
  type: UPDATE_LANGUAGES_REQUEST,
  payload,
});
export const updateLanguagesFailure = payload => ({
  type: UPDATE_LANGUAGES_FAILURE,
  payload,
});
export const updateLanguagesSuccess = payload => ({
  type: UPDATE_LANGUAGES_SUCCESS,
  payload,
});

export const addNewLanguageRequest = payload => ({
  type: ADD_NEW_LANGUAGE_REQUEST,
  payload,
});
export const addNewLanguageFailure = payload => ({
  type: ADD_NEW_LANGUAGE_FAILURE,
  payload,
});
export const addNewLanguageSuccess = payload => ({
  type: ADD_NEW_LANGUAGE_SUCCESS,
  payload,
});

export const deleteLanguageRequest = payload => ({
  type: DELETE_LANGUAGE_REQUEST,
  payload,
});
export const deleteLanguageFailure = payload => ({
  type: DELETE_LANGUAGE_FAILURE,
  payload,
});
export const deleteLanguageSuccess = payload => ({
  type: DELETE_LANGUAGE_SUCCESS,
  payload,
});


export const getLanguages = () => async (dispatch) => {
  dispatch(fetchLanguagesRequest());
  firebase
    .getInstance()
    .firestore()
    .collection('v1')
    .doc('data')
    .collection('languages')
    .get()
    .then((snapshot) => {
      const result = [];
      snapshot.forEach((childSnapshot) => {
        result.push(childSnapshot.data());
      });
      const languages = result.map(language => ({
        label: language.name,
        value: language.i18nKey,
        uid: language.uid,
      }));
      dispatch(fetchLanguagesSuccess({
        languages,
      }));
    })
    .catch((error) => {
      dispatch(fetchLanguagesFailure(error));
    });
};

export const updateLanguages = payload => async (dispatch) => {
  dispatch(updateLanguagesRequest());
  realtimeStorage
    .update('languages', payload.uid, payload.language)
    .then(() => {
      dispatch(updateLanguagesSuccess());
      dispatch(getLanguages());
    })
    .catch((error) => {
      dispatch(updateLanguagesFailure(error));
    });
};

export const deleteLanguage = payload => async (dispatch) => {
  dispatch(deleteLanguageRequest());
  realtimeStorage
    .delete('languages', [payload])
    .then(() => {
      dispatch(deleteLanguageSuccess());
      dispatch(getLanguages());
    })
    .catch((error) => {
      dispatch(deleteLanguageFailure(error));
    });
};

export const addNewLanguage = payload => async (dispatch) => {
  dispatch(addNewLanguageRequest());
  realtimeStorage
    .push('languages', payload.language)
    .then((response) => {
      dispatch(updateLanguages({
        language: assoc('uid', response.id, payload.language),
        uid: response.id,
      }));
      dispatch(addNewLanguageSuccess());
      setTimeout(() => dispatch(getLanguages()), 2500);
    })
    .catch((error) => {
      dispatch(addNewLanguageFailure(error));
    });
};


const initState = {
  isLoading: false,
  error: false,
  entries: [],
  success: false,
};

const languagesReducer = (state = initState, action) => {
  switch (action.type) {
    case UPDATE_LANGUAGES_REQUEST:
    case ADD_NEW_LANGUAGE_REQUEST:
    case DELETE_LANGUAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
        success: false,
      };
    case FETCH_LANGUAGES_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: false,
      };
    case UPDATE_LANGUAGES_SUCCESS:
    case ADD_NEW_LANGUAGE_SUCCESS:
    case DELETE_LANGUAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        success: true,
      };
    case FETCH_LANGUAGES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        entries: sortBy(prop('label'), action.payload.languages),
        error: false,
      };
    case FETCH_LANGUAGES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    case UPDATE_LANGUAGES_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        success: false,
      };
    case ADD_NEW_LANGUAGE_FAILURE:
    case DELETE_LANGUAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
        success: false,
      };
    default:
      return state;
  }
};

export default (state, action) => [languagesReducer].reduce(
  (newState, currentReducer) => currentReducer(newState, action),
  state,
);
