import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  getUserDetails,
  logoutCurrentUser,
} from '../ducks/user';

import { selectUserDetails } from '../selectors/user';

import HeaderAndDrawer from '../components/headerAndDrawer';

const mapStateToProps = state => ({
  userDetails: selectUserDetails(state),
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  logoutCurrentUser: async () => {
    await dispatch(logoutCurrentUser(ownProps));
  },
  getUserDetails: async () => {
    await dispatch(getUserDetails());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderAndDrawer));
