import React, { Component } from 'react';
import {
  isEmpty, includes, pipe, filter, pathOr, toLower,
} from 'ramda';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import EditIcon from '@material-ui/icons/Edit';

import { realtimeStorage } from '../helpers/firebaseDatabase';

import Table from '../components/table';

import i18n from '../helpers/i18n';


const columnData = [
  {
    id: 'media',
    numeric: false,
    disablePadding: false,
    i18nKey: 'illustration',
    valueCallback: (value) => {
      if (value && value.illustration) {
        return (<img style={{ height: 70 }} alt="img" src={(value.illustration.thumb || value.illustration.url)} />);
      }
      return null;
    },
  },
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    i18nKey: 'title',
  },
  {
    id: 'description',
    numeric: false,
    disablePadding: false,
    i18nKey: 'description',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    i18nKey: 'id',
  },
];

class Exercises extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      search: '',
    };
  }

  componentWillMount() {
    this.unsubscribe = realtimeStorage.getOn('exercises', (val) => {
      const newItems = [];
      val.forEach((item) => {
        const newItem = item;
        newItem.title = item.title.de;
        newItem.uid = item.title.uid;
        newItem.description = pathOr('', ['description', 'de'], item);
        newItems.push(newItem);
      });
      this.setState({ data: newItems });
    }, [{
      field: 'createdAt',
      type: 'orderBy',
    }]);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  render() {
    const {
      data,
      search,
      searchData,
    } = this.state;
    const { history } = this.props;

    const handleSearchPhrase = () => (event) => {
      this.setState({ search: event.target.value });
      // eslint-disable-next-line max-len
      this.setState({ searchData: pipe(filter(val => includes(toLower(event.target.value), toLower(val.title))))(data) });
    };

    return (
      <div>
        <Grid container item xs={12} spacing={4} justify="center" alignContent="center">
          <Grid
            item
            container
            xs={2}
            alignItems="center"
            justify="center"
          >
            <Typography variant="h6" component="h6">
              {i18n.t('selectedFilters')}
            </Typography>
          </Grid>
          <Grid
            item
            container
            xs={9}
            alignItems="center"
            justify="center"
          >
            <FormControl fullWidth variant="filled">
              <TextField
                label={i18n.t('search_text')}
                value={search}
                defaultValue={search}
                id="search"
                onChange={handleSearchPhrase()}
              />
            </FormControl>
          </Grid>
          <Grid xs={1} container justify="center" alignContent="center">
            <IconButton
              aria-label="delete"
              onClick={() => {
                this.setState({ search: '' });
              }}
            >
              <RotateLeftIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Table
          data={isEmpty(search) ? data : searchData}
          columns={columnData}
          rowHeight={120}
          onDeleteItems={(items) => {
            realtimeStorage.delete('exercises', items);
          }}
          toolbarItems={[{
            tooltipLabel: i18n.t('edit_item'),
            onClick: (selected) => {
              history.push(`/exercises/${selected[0].id}/edit`);
            },
            icon: (<EditIcon />),
          }]}
          {...this.props}
        />
      </div>
    );
  }
}

Exercises.propTypes = {
  history: PropTypes.object.isRequired,
};

export default Exercises;
