import firebase from '../helpers/firebase';
import AuthService from '../services/AuthService';

const authService = new AuthService();

export const LOGIN_REQUEST = Symbol('LOGIN_REQUEST');
export const LOGIN_SUCCESS = Symbol('LOGIN_SUCCESS');
export const LOGIN_FAILURE = Symbol('LOGIN_FAILURE');

export const LOGOUT_REQUEST = Symbol('LOGOUT_REQUEST');
export const LOGOUT_SUCCESS = Symbol('LOGOUT_SUCCESS');
export const LOGOUT_FAILURE = Symbol('LOGOUT_FAILURE');

export const FETCH_USER_DETAILS_REQUEST = Symbol('FETCH_USER_DETAILS_REQUEST');
export const FETCH_USER_DETAILS_SUCCESS = Symbol('FETCH_USER_DETAILS_SUCCESS');
export const FETCH_USER_DETAILS_FAILURE = Symbol('FETCH_USER_DETAILS_FAILURE');

export const loginRequest = payload => ({
  type: LOGIN_REQUEST,
  payload,
});
export const loginFailure = payload => ({
  type: LOGIN_FAILURE,
  payload,
});
export const loginSuccess = payload => ({
  type: LOGIN_SUCCESS,
  payload,
});

export const logoutRequest = payload => ({
  type: LOGOUT_REQUEST,
  payload,
});
export const logoutSuccess = payload => ({
  type: LOGOUT_SUCCESS,
  payload,
});
export const logoutFailure = payload => ({
  type: LOGOUT_SUCCESS,
  payload,
});

export const fetchUserDetailsRequest = payload => ({
  type: FETCH_USER_DETAILS_REQUEST,
  payload,
});
export const fetchUserDetailsSuccess = payload => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload,
});
export const fetchUserDetailsFailure = payload => ({
  type: FETCH_USER_DETAILS_SUCCESS,
  payload,
});

export const authUserWithEmailAndPassword = (email, password, ownProps) => async (dispatch) => {
  dispatch(loginRequest());
  firebase
    .getInstance()
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(async (userDetails) => {
      const { user } = userDetails;
      const data = await user.getIdTokenResult();
      dispatch(loginSuccess(({
        user,
        data,
      })));
      setTimeout(() => ownProps.history.push('/news'), 600);
    })
    .catch((error) => {
      dispatch(loginFailure(error));
    });
};

export const logoutCurrentUser = () => async (dispatch, ownProps) => {
  dispatch(logoutRequest());
  firebase
    .getInstance()
    .auth()
    .signOut()
    .then((user) => {
      dispatch(logoutSuccess({ user }));
      authService.removeToken();
      authService.removeApiUrl();
      ownProps.history.push('/login');
    })
    .catch((error) => {
      dispatch(logoutFailure({ error }));
    });
};

export const getUserDetails = () => async (dispatch) => {
  dispatch(fetchUserDetailsRequest());
  firebase
    .getInstance()
    .auth()
    .currentUser
    .getIdTokenResult(true)
    .then((idTokenResult) => {
      dispatch(fetchUserDetailsSuccess(idTokenResult));
    })
    .catch((error) => {
      dispatch(fetchUserDetailsFailure(error));
    });
};


const initState = {
  isAuthenticated: false,
  isLoading: false,
  displayName: '',
  email: '',
  emailVerified: '',
  isAnonymous: '',
  iud: '',
  phoneNumber: '',
  photoURL: '',
};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAILS_REQUEST:
      return {
        ...state,
        isLoading: true,
        isAuthenticated: false,
        error: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        displayName: action.payload.user.displayName,
        email: action.payload.user.email,
        emailVerified: action.payload.user.emailVerified,
        error: false,
        isAnonymous: action.payload.user.isAnonymous,
        isAuthenticated: true,
        isLoading: true,
        iud: action.payload.user.uid,
        phoneNumber: action.payload.user.phoneNumber,
        photoURL: action.payload.user.photoURL,
        regions: action.payload.data.claims.regions,
        role: action.payload.data.claims.role,
      };
    case FETCH_USER_DETAILS_SUCCESS:
      return {
        ...state,
        ...action.payload.claims,
        isLoading: false,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.payload.message,
      };
    case FETCH_USER_DETAILS_FAILURE:
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
        error: action.payload.message,
      };
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case LOGOUT_SUCCESS:
      return {
        ...initState,
      };
    case LOGOUT_FAILURE:
      return {
        ...initState,
        error: true,
      };
    default:
      return state;
  }
};

export default (state, action) => [userReducer].reduce(
  (newState, currentReducer) => currentReducer(newState, action),
  state,
);
