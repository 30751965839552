import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import * as moment from 'moment';
import 'moment/locale/de';

import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import ArrowBackIosRounded from '@material-ui/icons/ArrowBackIosRounded';
import IconButton from '@material-ui/core/IconButton';

import { ClipLoader } from 'react-spinners';
import Button from '@material-ui/core/Button';
import { realtimeStorage } from '../../helpers/firebaseDatabase';

import i18n from '../../helpers/i18n';
import I18nButton from '../../components/i18nButton';
import Dropzone from '../../components/dropZone';

// services
import firebase from '../../helpers/firebase';

moment.locale('de');

const AssetDetails = ({
  classes,
  history,
  match,
}) => {
  const illustrationDropzone = useRef();
  const assetUID = match.params.id || '';
  const [loading, setLoading] = useState(false);
  const [asset, setAsset] = useState(false);
  const [localValue, setLocalValue] = useState();
  const [changeImage, setChangeImage] = useState(false);
  const [illustration, setIllustration] = useState(null);
  const readAsset = async () => {
    try {
      setLoading(true);
      const newAssetDetails = await realtimeStorage.getOnce('assets');
      setAsset(newAssetDetails.find(a => a.uid === assetUID));
    } finally {
      setLoading(false);
    }
  };

  const renderHeader = () => (
    <Grid item xs={12}>
      <Box alignItems="center" display="flex" flexDirection="row">
        <IconButton onClick={history.goBack} style={{ paddingRight: 16 }}>
          <ArrowBackIosRounded />
        </IconButton>
        <Typography variant="h4" component="h4">
          {`${i18n.t('app_asset_details')}: ${assetUID}`}
        </Typography>
      </Box>
    </Grid>
  );

  const renderLoader = () => loading && (
    <div className={classes.loadingContainer}>
      <ClipLoader
        sizeUnit="px"
        size={35}
      />
    </div>
  );

  const getUrlFromBucket = (localURL) => {
    const fileExtension = localURL.name.match(/(\.\w+$)/g)[0];
    const fullFilename = `${asset.screen}-${moment().toISOString()}${fileExtension}`;
    const storageRef = firebase
      .getInstance()
      .storage()
      .ref()
      .child(`/images/${asset.category}/`)
      .child(fullFilename);

    return storageRef
      .put(localURL)
      .then(() => storageRef
        .getDownloadURL()
        .then(url => url)
        .catch(e => e));
  };

  const uploadImageFromDropZone = async () => {
    setLoading(true);
    const imagePath = await getUrlFromBucket(localValue);
    const newAssetDetails = ({
      ...asset,
      image: imagePath,
    });
    const {
      uid,
      image,
      screen,
      category,
    } = newAssetDetails;
    const updatePayload = {
      uid,
      image,
      screen,
      category,
    };
    realtimeStorage
      .update('assets', uid, updatePayload)
      .then(() => {
        setLoading(false);
        history.goBack();
      })
      .catch(e => e);
  };

  useEffect(() => {
    readAsset().then().catch();
  }, []);

  return (
    <Paper className={classes.paper} elevation={4}>
      <Grid container item spacing={2} direction="row">
        <Grid zeroMinWidth container direction="row">
          <Box width="100%">
            {renderHeader()}
          </Box>
          <Box width="50%" alignContent style={{ margin: 'auto' }}>
            <Box mt="10px">
              <Typography>{`${i18n.t('id')}: ${asset.uid}`}</Typography>
            </Box>
            <Box mt="10px">
              <Typography>{`${i18n.t('screen_name')}: ${asset.screen}`}</Typography>
            </Box>
            <Box mt="10px">
              <Typography>{`${i18n.t('category')}: ${asset.category}`}</Typography>
            </Box>
            <Box mt={2}>
              <Button
                variant="contained"
                color="primary"
                className={classes.deleteButton}
                onClick={() => {
                  setChangeImage(true);
                  setLocalValue(null);
                  setIllustration(null);
                }}
              >
                {i18n.t('replace_current_image')}
              </Button>
            </Box>
          </Box>
          <Box width="50%">
            <Box mt="10px">
              <Typography>{i18n.t('image')}</Typography>
              <img
                alt={asset.image}
                src={asset.image}
                style={{
                  height: 'auto',
                  maxWidth: '100%',
                  borderRadius: 10,
                  boxShadow: '0 20px 75px rgba(0, 0, 0, 0.23)',
                }}
              />
            </Box>
          </Box>
          {changeImage && (
            <Box width="100%" p="10px">
              <Dropzone
                disabled={loading}
                i18nLabelKey="illustration"
                type="illustrations"
                value={illustration}
                passUrl={file => file && setLocalValue(file)}
                ref={(ref) => { illustrationDropzone.current = ref; }}
                maxSize={1000000}
              />
              <Typography>{i18n.t('file_max_size_1')}</Typography>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container>
        {localValue && (
          <Box mt={4}>
            <Grid item className={classes.saveButton}>
              <I18nButton
                i18nKey="replace_and_save"
                fullWidth
                onClick={() => uploadImageFromDropZone()}
                color="primary"
                disabled={loading || !localValue}
                loading={loading}
              />
            </Grid>
          </Box>
        )}
      </Grid>
      {renderLoader()}
    </Paper>
  );
};

AssetDetails.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

const styles = theme => ({
  paper: theme.mixins.gutters({
    paddingTop: 16,
    paddingBottom: 16,
    marginTop: theme.spacing.unit * 3,
    margin: '0 auto',
    maxWidth: 1000,
    minWidth: 400,
  }),
  loadingContainer: {
    display: 'flex',
    position: 'absolute',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#ffffff85',
    top: 0,
    right: 0,
  },
  formControl: {
    marginTop: 20,
    marginBottom: 20,
  },
  textField: {
    width: 400,
  },
  summary: {
    marginTop: 10,
    '& b': {
      fontWeight: 600,
      fontFamily: 'sans-serif',
    },
  },
  audienceContainer: {
    marginTop: 28,
  },
  heading: {
    fontFamily: 'Rajdhani',
    fontSize: 20,
  },
  contentHeading: {
    marginTop: 10,
  },
  deleteProductsButton: {
    minWidth: 40,
    padding: 0,
  },
  saveButton: {
    marginRight: 10,
  },
  regionSection: {
    marginBottom: 15,
  },
});

export default withStyles(styles)(AssetDetails);
