import { isEmpty, not } from 'ramda';
import React, { useCallback, useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Grid from '@material-ui/core/Grid';

import BackgroundImage from '../img/background_login.jpg';

import I18nTextField from '../components/i18nTextField';
import I18nButton from '../components/i18nButton';

import BlackrollLogo from '../img/blackroll-logo.png';

const Login = ({
  classes,
  loginWithEmailAndPassword,
  isLoading,
  error,
}) => {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const notify = useCallback(() => toast.error(errorMessage, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  }), [errorMessage]);
  // eslint-disable-next-line max-len
  const handleLoginPress = useCallback(() => loginWithEmailAndPassword(email, password), [email, password, loginWithEmailAndPassword]);

  const isValid = not(isEmpty(email)) && not(isEmpty(password));

  useEffect(() => {
    setErrorMessage(error);
    // eslint-disable-next-line no-unused-expressions
    errorMessage && notify();
  }, [error, errorMessage]);

  return (
    <div className={classes.root}>
      <ToastContainer />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={8} md={5} elevation={6} square className={classes.loginForm}>
        <div className={classes.paper}>
          <img className={classes.logo} src={BlackrollLogo} alt="Blackroll" />
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <div className={classes.form}>
            <I18nTextField
              flex
              i18nLabelKey="email"
              onKeyDown={e => e.key === 'Enter' && isValid && handleLoginPress()}
              onChange={setEmail}
            />
            <I18nTextField
              flex
              i18nLabelKey="password"
              onChange={setPassword}
              onKeyDown={e => e.key === 'Enter' && isValid && handleLoginPress()}
              type="password"
            />
            <div className={classes.buttonFooter}>
              <I18nButton
                disabled={isEmpty(email) || isEmpty(password)}
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                i18nKey="login"
                loading={isLoading}
                onClick={() => handleLoginPress()}
              />
            </div>
          </div>
        </div>
      </Grid>
    </div>
  );
};


Login.propTypes = {
  classes: PropTypes.object.isRequired,
  isLoading: PropTypes.bool.isRequired,
  loginWithEmailAndPassword: PropTypes.func,
  error: PropTypes.string,
};

const styles = theme => ({
  loginForm: {
    display: 'flex',
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 'auto',
  },
  avatar: {
    marginTop: 20,
  },
  logo: {
    width: 300,
  },
  buttonFooter: {
    marginTop: 20,
  },
  root: {
    height: '100vh',
    display: 'flex',
    backgroundColor: theme.palette.grey[100],
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  image: {
    backgroundImage: `url(${BackgroundImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100%',
  },
});

export default withStyles(styles)(Login);
