import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { uniqBy } from 'ramda';

import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';

import { withRouter } from 'react-router-dom';
import Table from '../../components/table';

import { realtimeStorage } from '../../helpers/firebaseDatabase';

const columnData = [
  {
    id: 'title',
    numeric: false,
    disablePadding: false,
    i18nKey: 'name',
  },
  {
    id: 'id',
    numeric: false,
    disablePadding: false,
    i18nKey: 'Sku',
  },
  {
    id: 'pimProductId',
    numeric: false,
    disablePadding: false,
    i18nKey: 'id',
  },
  {
    id: 'base64Image',
    numeric: false,
    disablePadding: false,
    i18nKey: 'illustration',
    valueCallback: (value) => {
      if (value) {
        return <img style={{ height: 120 }} alt="img" src={value} />;
      }
      return null;
    },
  },
  {
    id: 'handle',
    numeric: false,
    disablePadding: false,
    i18nKey: 'Handle',
  },
  {
    id: 'exercisesCount',
    numeric: false,
    disablePadding: false,
    i18nKey: 'number_of_exercises',
  },
];

const Products = (props) => {
  const [loading, setLoading] = useState(false);
  const [pimProducts, setPimProducts] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const newPimProducts = await realtimeStorage.getOnce('pimProducts');
      const products = newPimProducts.map(product => ({
        ...product,
        illustration: product.image,
        title: product.name.en,
        exercisesCount: product.productExercises.length,
      }));
      setPimProducts(uniqBy(product => product.handle, products));
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteProduct = async () => {
    alert("You can't delete products");
  };

  return (
    <div>
      <Grid zeroMinWidth container direction="row">
        <Box width="100%">
          <Table
            isLoading={loading}
            data={pimProducts}
            columns={columnData}
            allowAddItem={false}
            onDeleteItems={handleDeleteProduct}
            {...props}
          />
        </Box>
      </Grid>
    </div>
  );
};

Products.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Products);
