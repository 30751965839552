import React from 'react';
import PropTypes from 'prop-types';
// eslint-disable-next-line
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddCircle';

import i18n from '../../helpers/i18n';

const toolbarStyles = theme => ({
  root: { paddingRight: 2 },
  highlight:
    theme.palette.type === 'light'
      ? {
        color: theme.palette.secondary.A700,
        backgroundColor: theme.palette.secondary.A100,
      }
      : {
        color: theme.palette.secondary.A100,
        backgroundColor: theme.palette.secondary.A700,
      },
  spacer: { flex: '1 1 100%' },
  actions: { color: theme.palette.text.secondary },
  icons: { display: 'flex' },
  title: { flex: '0 0 auto' },
  row: { float: 'left' },
  button: { margin: theme.spacing.unit },
  leftIcon: { marginRight: theme.spacing.unit },
});

const EnhancedTableToolbar = (props) => {
  const {
    numSelected,
    classes,
    title,
    allowAddItem,
    handleAddItem,
    toolbarItems,
    selected,
    onDeleteItems,
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root, { [classes.highlight]: numSelected > 0 })}
    >
      <div className={classes.actions}>
        {numSelected > 0 ? (
          <div className={classes.icons}>
            {toolbarItems && toolbarItems.map((item) => {
              if (!item.forSingleSelectionOnly || numSelected === 1) {
                let disabled = false;
                if (item.isDisabled) {
                  disabled = item.isDisabled(selected);
                }
                return (
                  <Tooltip key={item.tooltipLabel} title={item.tooltipLabel}>
                    <IconButton
                      disabled={disabled}
                      aria-label={item.tooltipLabel}
                      onClick={() => item.onClick(selected)}
                    >
                      {item.icon}
                    </IconButton>
                  </Tooltip>
                );
              }
              return null;
            })}
            <Tooltip title={i18n.t('delete')}>
              <IconButton aria-label={i18n.t('delete')} onClick={() => onDeleteItems(selected)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        )
          : (
            <div className={classes.icons}>
              <Button className={classes.button} disabled={!allowAddItem} raised={1} color="primary" onClick={handleAddItem}>
                <AddIcon className={classes.leftIcon} />
                {i18n.t('add')}
              </Button>
            </div>
          )}
      </div>
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography>
            {`${numSelected} ${i18n.t('selected')}`}
            {' '}
          </Typography>
        )
          : (
            <Typography>
              {title}
            </Typography>
          )}
      </div>
      <div className={classes.spacer} />
    </Toolbar>
  );
};


EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number,
  classes: PropTypes.object,
  title: PropTypes.string,
  allowAddItem: PropTypes.bool,
  toolbarItems: PropTypes.array,
  selected: PropTypes.array,
  onDeleteItems: PropTypes.func,
  handleAddItem: PropTypes.func,
};


export default withStyles(toolbarStyles)(EnhancedTableToolbar);
